MultiInterventionJudgementOption = ({ option, grouppedTagsByOption, tags }) ->
  <label className="ep-radiobutton">
    <b>{option.get('text')}{":"}</b>{" "}
    {grouppedTagsByOption.get(option.get('value'), Immutable.Map()).map (__, key) ->
      tags.getIn([key, 'name'])
    .toList().interpose(', ')}
  </label>

MultiInterventionJudgementOption.propTypes =
  option: PropTypes.instanceOf(Immutable.Map).isRequired
  grouppedTagsByOption: PropTypes.instanceOf(Immutable.Map).isRequired
  tags: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = MultiInterventionJudgementOption
