templateLanguages = require 'lib/doc_sections_templates/templates_languages'
DocSectionsTemplatesActions = require 'actions/doc_sections_templates_actions'
DocSectionsTemplateSectionsSelection =
  require 'components/administration/templates/doc_sections_template_sections_selection'
SelectedTemplate = require 'components/administration/templates/selected_template'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'

DocSectionsTemplates = createReactClass
  displayName: 'DocSectionsTemplates'

  propTypes:
    template: PropTypes.instanceOf(Immutable.Map)
    editingTemplate: PropTypes.string
    templateName: PropTypes.string
    templateChanged: PropTypes.bool

  mixins: [CustomRenderMixin, Translation('settings:etd_templates')]

  editTemplate: ->
    DocSectionsTemplatesActions.prepareTemplate @props.template
    DocSectionsTemplatesActions.editTemplate()

  render: ->
    <div>
      <SelectedTemplate
        templateType='doc_sections'
        template={@props.template}
        editTemplate={@editTemplate}
        editingTemplate={@props.editingTemplate}
        templateName={@props.templateName}
        baseTemplateName={@props.templateName}
        templateLanguages={templateLanguages}
        templateChanged={@props.templateChanged}
        templateDescription={@i18n "template_for_doc_sections"}
        SectionsSelectionComponent={DocSectionsTemplateSectionsSelection}
        saveTemplateChanges={DocSectionsTemplatesActions.saveTemplate}
      />
    </div>

module.exports = DocSectionsTemplates
