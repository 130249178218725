var EDMSActions, INITIAL_DIALOG_STATE, INITIAL_STATE, convertListToOrderedMap, generateStore, getOpenedFolders, _getParentIds,
  __slice = [].slice;

generateStore = require('stores/utils/generate_store');

EDMSActions = require('actions/edms_actions');

convertListToOrderedMap = require('lib/immutable_utils').convertListToOrderedMap;

INITIAL_DIALOG_STATE = Immutable.fromJS({
  handler: null,
  applying: false,
  isOpen: false
});

INITIAL_STATE = Immutable.fromJS({
  isFetching: false,
  localSearchQuery: '',
  globalSearchQuery: '',
  dialog: INITIAL_DIALOG_STATE,
  folders: {},
  files: [],
  rootFolderIds: [],
  meta: {
    viewType: 'grid',
    currentCabinet: null,
    openedFolders: [],
    selectedFile: null,
    paginationData: {
      currentPage: 0,
      documentsCount: 0,
      documentsPerPage: 20
    }
  }
});

_getParentIds = function(parentIds, cabinet, cabinets) {
  if (!cabinet || !cabinet.parentId) {
    return;
  }
  parentIds.push(cabinet.parentId);
  return _getParentIds(parentIds, _.find(cabinets, function(c) {
    return c.id === cabinet.parentId;
  }), cabinets);
};

getOpenedFolders = function(currentCabinet, cabinets) {
  var parentIds;
  parentIds = [];
  _getParentIds(parentIds, currentCabinet, cabinets);
  return Immutable.fromJS(_.compact([currentCabinet.id].concat(__slice.call(parentIds))));
};

module.exports = generateStore({
  name: 'EDMSStore',
  initialState: INITIAL_STATE,
  boundActions: [EDMSActions],
  methods: {
    isFetching: function() {
      return this.state.get('isFetching');
    },
    isSubmitting: function() {
      return this.state.getIn(['importDialog', 'applying']);
    },
    isInsertModalOpen: function() {
      return this.state.getIn(['importDialog', 'isOpen'], false);
    },
    onInsertFile: function() {
      return this.setState(this.state.setIn(['importDialog', 'applying'], true));
    },
    onFetchCabinet: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchSuccess: function(_arg) {
      var cabinets, currentCabinet, documents, documentsCount, page, rootCabinetIds, searchResults;
      searchResults = _arg.searchResults, page = _arg.page;
      if (!searchResults) {
        return;
      }
      cabinets = searchResults.cabinets, documents = searchResults.documents, rootCabinetIds = searchResults.rootCabinetIds, currentCabinet = searchResults.currentCabinet, documentsCount = searchResults.documentsCount;
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', false);
        state.setIn(['meta', 'currentCabinet'], Immutable.fromJS(currentCabinet));
        state.setIn(['meta', 'openedFolders'], getOpenedFolders(currentCabinet, cabinets));
        state.setIn(['meta', 'paginationData'], Immutable.fromJS({
          documentsCount: documentsCount,
          currentPage: page,
          documentsPerPage: 20
        }));
        state.set('rootFolderIds', Immutable.fromJS(rootCabinetIds));
        state.set('folders', convertListToOrderedMap(Immutable.fromJS(cabinets), 'id'));
        return state.set('files', Immutable.fromJS(documents));
      }));
    },
    onFetchError: function() {
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', false);
        return state.set('searchResults', Immutable.Map());
      }));
    },
    onToggleSelectedFile: function(fileId) {
      var selected;
      selected = this.getSelectedFile();
      return this.setState(this.state.setIn(['meta', 'selectedFile'], fileId === selected ? null : fileId));
    },
    getFolders: function() {
      return this.state.get('folders', Immutable.Map());
    },
    getFiles: function() {
      return this.state.get('files', Immutable.List());
    },
    onSetSubmitting: function(submitting) {
      return this.setState(this.state.setIn(['importDialog', 'applying'], submitting));
    },
    getCurrentCabinetId: function() {
      return this.state.getIn(['meta', 'currentCabinet', 'id'], null);
    },
    getRootFolderIds: function() {
      return this.state.get('rootFolderIds', Immutable.List());
    },
    getSelectedFile: function() {
      return this.state.getIn(['meta', 'selectedFile']);
    },
    getOpenedFolders: function() {
      return this.state.getIn(['meta', 'openedFolders'], Immutable.List());
    },
    getLocalSearchQuery: function() {
      return this.state.get('localSearchQuery');
    },
    getGlobalSearchQuery: function() {
      return this.state.get('globalSearchQuery');
    },
    getPaginationData: function() {
      return this.state.getIn(['meta', 'paginationData'], Immutable.Map());
    },
    getInsertHandler: function() {
      return this.state.getIn(['importDialog', 'handler'], null);
    },
    getViewType: function() {
      return this.state.getIn(["meta", "viewType"]);
    },
    onChangeViewType: function(viewType) {
      return this.setState(this.state.setIn(['meta', 'viewType'], viewType));
    },
    onCloseImportDialog: function() {
      return this.setState(this.state.set('importDialog', INITIAL_DIALOG_STATE));
    },
    onSetViewType: function(viewType) {
      return this.setState(this.state.setIn(['meta', 'viewType'], viewType));
    },
    onSetGlobalSearchQuery: function(_arg) {
      var page, searchQuery;
      searchQuery = _arg.searchQuery, page = _arg.page;
      if (_.isEmpty(searchQuery)) {
        return;
      }
      return this.setState(this.state.withMutations(function(state) {
        state.set('globalSearchQuery', searchQuery);
        state.set('localSearchQuery', '');
        state.set('isFetching', true);
        state.setIn(['meta', 'currentCabinet'], null);
        return state.setIn(['meta', 'paginationData'], Immutable.fromJS({
          documentsCount: 0,
          currentPage: 0,
          documentsPerPage: 20
        }));
      }));
    },
    onSearchSuccess: function(_arg) {
      var count, documents, page, searchResults;
      searchResults = _arg.searchResults, page = _arg.page;
      if (!searchResults) {
        return;
      }
      documents = searchResults.documents, count = searchResults.count;
      return this.setState(this.state.withMutations(function(state) {
        state.set('isFetching', false);
        state.set('files', Immutable.fromJS(documents));
        return state.setIn(['meta', 'paginationData'], Immutable.fromJS({
          documentsCount: count,
          currentPage: page,
          documentsPerPage: 20
        }));
      }));
    },
    onOpenImportDialog: function(_arg) {
      var handler;
      handler = _arg.handler;
      return this.setState(this.state.withMutations(function(state) {
        state.setIn(['importDialog', 'isOpen'], true);
        return state.setIn(['importDialog', 'handler'], handler);
      }));
    }
  }
});
