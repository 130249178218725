RangeInput = ({ value, onChange }) ->
  _onChange = (index) -> (evt)->
    v = evt.target.value
    value.splice(index, 1, v)
    onChange(value)  

  <div className="flex flex-row items-center">
    <input type="number" className="inline-form-control mr-5" value={value[0]} onChange={_onChange(0)}/>
    <input type="number" className="inline-form-control mr-5" value={value[1]} onChange={_onChange(1)} />  
  </div>

RangeInput.propTypes =
  value: PropTypes.array.isRequired
  onChange: PropTypes.func.isRequired

module.exports = RangeInput
  