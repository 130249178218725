AdolopmentTypeOfRecommendation = require 'components/etd/adolopment_type_of_recommendation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdSectionTitle = require 'components/etd/etd_section_title'
EtdVotingMixin = require 'components/mixins/etd_voting_mixin'
TypeOfRecommendation = require 'components/etd/type_of_recommendation'
Translation = require 'components/mixins/translation'

{ string, instanceOf, func, bool } = PropTypes

Recommendation = createReactClass

  displayName: "Recommendation"

  mixins: [
    CustomRenderMixin
    EtdVotingMixin
    Translation('es:recommendations.table')
  ]

  propTypes:
    adolopmentData: instanceOf(Immutable.Map).isRequired
    adolopments: instanceOf(Immutable.OrderedMap).isRequired
    editable: bool
    etdId: string.isRequired
    etdViewSettings: instanceOf(Immutable.Map).isRequired
    renderMode: string.isRequired
    section: instanceOf(Immutable.Map).isRequired
    sectionId: string.isRequired,
    highlightAsDiff: bool
    showResultingDataOnly: bool
    withTitle: bool

  getDefaultProps: ->
    showResultingDataOnly: false
    withTitle: true
    highlightAsDiff: false

  _getSectionProps: (section, sectionId) ->
    editable: if @props.editable? then @props.editable else @_inEditMode()
    etdId: @props.etdId
    etdViewSettings: @props.etdViewSettings
    isConsensus: false
    overarchingQuestionData: @props.overarchingQuestionData
    renderMode: @props.renderMode
    highlightAsDiff: @props.highlightAsDiff
    sectionId: sectionId

  render: ->

    {
      adolopmentData
      adolopments
      etdId
      etdViewSettings
      renderMode
      section
      sectionId
      showResultingDataOnly
      withTitle
    } = @props

    # withAdolopment means that one wants to update this section
    # if it is set to false than content of the section is copied from orginal etd and marked as
    # readonly
    withAdolopment = adolopmentData.getIn([sectionId, 'withAdolopment'], false)
    sectionToBeDisplayedAsCurrent = if adolopments.isEmpty()
      section
    else if withAdolopment
      section
    else
      adolopments.last().getIn(['templateData', 'conclusions', 'sections', sectionId])

    sectionProps = @_getSectionProps(section, sectionId)
    adolopmentSectionCollapsed = etdViewSettings.getIn(
      [etdId, 'collapsedAdolopmentSections', sectionId], false)

    showAdolopmentPart = not adolopments.isEmpty() and not showResultingDataOnly and withAdolopment

    <div className='recommendation-section'>
      {withTitle && <EtdSectionTitle
        renderMode={@props.renderMode}
        title={@i18n 'type_of_recommendation'}
      />}

      {showAdolopmentPart and <AdolopmentTypeOfRecommendation
        adolopments={adolopments}
        className='recommendation-table-static'
        key={"#{sectionId}-adoloped"}
        {...sectionProps}
      />
      }
      {not adolopmentSectionCollapsed and <TypeOfRecommendation
        key={sectionId}
        className='recommendation-table-static'
        section={sectionToBeDisplayedAsCurrent}

        {...sectionProps}
      />}

    </div>

module.exports = Recommendation
