appUtils = require 'lib/app_utils'
mediator = require 'mediator'
{ getAccessRightsList } = require 'lib/members_helper'
{ MDG_WORKSPACE } = require 'lib/mdg_helper'
{ useCoffeeMemo, useI18n } = require 'lib/react_utils'

VisibilityMarks = React.memo ({ visibleFor, toggleCommentVisibility }) ->
  i18n = useI18n('team:member_access_rights')
  accessRightsList = useCoffeeMemo [], -> getAccessRightsList().map ({ text, value }) ->
    {
      value,
      text: switch value
        when 'mdg_editor_in_chief' then i18n 'mdg_editor_in_chief.short_label'
        when 'mdg_panel_reviewer' then i18n 'mdg_panel_reviewer.short_label'
        when 'evolent_panel_reviewer' then i18n 'evolent_panel_reviewer.short_label'
        else text
    }

  canUserToggleVisibility = if appUtils.isActiveWorkspaceOrFeatureSwitchOn(
    MDG_WORKSPACE, 'mdgDocSecFeatures'
  )
    mediator.user.hasRole ['admin', 'mdg_editor_in_chief', 'researcher']
  else
    true

  <div className="mark-for-role-buttons">
    {accessRightsList.map (role) ->
      buttonClassNames = classNames
        marked: role.value in visibleFor,
        enabled: canUserToggleVisibility
      return if role.value is 'no_access'
      <button
        key={role.value}
        className={buttonClassNames}
        data-role={role.value}
        onClick={toggleCommentVisibility if canUserToggleVisibility}
      >
        {role.text}
      </button>
    }
  </div>

VisibilityMarks.propTypes =
  visibleFor: PropTypes.arrayOf(PropTypes.string)
  toggleCommentVisibility: PropTypes.func.isRequired
  accessRights: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }))

VisibilityMarks.defaultProps =
  visibleFor: []

module.exports = VisibilityMarks
