var ACPVisualGuidelinesActions, EMPTY_VISUALIZATION, EXPORT_DIALOG_INITIAL_STATE, INITIAL_DIALOG_STATE, INITIAL_STATE, INITIAL_TABLE_DATA, SETTINGS_DIALOG_INITIAL_STATE, convertRowsToMap, docTypes, generateStore;

generateStore = require('stores/utils/generate_store');

ACPVisualGuidelinesActions = require('actions/acp_visual_guidelines_actions');

docTypes = require('lib/db_docs/doc_types');

convertRowsToMap = require('lib/app_utils').convertRowsToMap;

EMPTY_VISUALIZATION = Immutable.fromJS({
  name: ''
});

INITIAL_DIALOG_STATE = Immutable.fromJS({
  applying: false,
  isOpen: false,
  visualization: EMPTY_VISUALIZATION
});

EXPORT_DIALOG_INITIAL_STATE = Immutable.fromJS({
  isOpen: false
});

SETTINGS_DIALOG_INITIAL_STATE = Immutable.fromJS({
  isOpen: false,
  settings: {}
});

INITIAL_STATE = Immutable.fromJS({
  isFetching: false,
  visualization: {},
  visualizationPreview: {
    preparing: false,
    data: {}
  },
  interactiveTableData: {},
  visualizations: {},
  dialog: INITIAL_DIALOG_STATE,
  exportDialog: EXPORT_DIALOG_INITIAL_STATE,
  settingsDialog: SETTINGS_DIALOG_INITIAL_STATE,
  meta: {
    activeTab: 'document'
  }
});

INITIAL_TABLE_DATA = Immutable.fromJS({
  populations: {},
  populationsOrder: [],
  interventions: {},
  recommendations: {},
  outcomes: {}
});

module.exports = generateStore({
  name: 'ACPVisualGuidelinesStore',
  initialState: INITIAL_STATE,
  boundActions: [ACPVisualGuidelinesActions],
  methods: {
    onDbChange: function(change) {
      if (change.id === this.getCurrentVisualizationId()) {
        return this.setState(this.state.set('visualization', Immutable.fromJS(change.doc)));
      } else if (change.id === this.getCurrentVisualizationRootId()) {
        return this.setState(this.state.set('interactiveTableData', Immutable.fromJS(change.doc.interactiveTableData)));
      } else if (change.deleted) {
        return this.setState(this.state.deleteIn(['visualizations', change.id]));
      } else if (change.doc.docType === docTypes.ACP_VISUAL_GUIDELINES) {
        return this.setState(this.state.setIn(['visualizations', change.id], Immutable.fromJS(change.doc)));
      }
    },
    onChangeTab: function(tab) {
      return this.setState(this.state.setIn(['meta', 'activeTab'], tab));
    },
    onSetApplying: function() {
      return this.state.setIn(['dialog', 'applying'], true);
    },
    onOpenCreateOrEditDialog: function(visualization) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['dialog', 'isOpen'], true);
        return s.setIn(['dialog', 'visualization'], visualization || EMPTY_VISUALIZATION);
      }));
    },
    onCloseCreateOrEditDialog: function() {
      return this.setState(this.state.set('dialog', INITIAL_DIALOG_STATE));
    },
    onCreateVisualization: function() {
      return this.setState(this.state.setIn(['dialog', 'applying'], true));
    },
    onUpdateVisualization: function() {
      return this.setState(this.state.setIn(['dialog', 'applying'], true));
    },
    onUpdateEditingVisualization: function(_arg) {
      var key, value;
      key = _arg.key, value = _arg.value;
      return this.setState(this.state.setIn(['dialog', 'visualization', key], value));
    },
    onFetch: function() {
      return this.setState(this.state.set('fetching', true));
    },
    onFetchError: function(err) {
      return this.setState(this.state.set('fetching', false));
    },
    onFetchSuccess: function(_arg) {
      var rows;
      rows = _arg.rows;
      return this.setState(this.state.withMutations(function(state) {
        state.set('fetching', false);
        return state.set('visualizations', convertRowsToMap(rows));
      }));
    },
    onFetchOneSuccess: function(doc) {
      return this.setState(this.state.withMutations(function(state) {
        state.set('fetching', false);
        return state.set('visualization', Immutable.fromJS(doc));
      }));
    },
    onFetchRootSuccess: function(doc) {
      return this.setState(this.state.set('interactiveTableData', Immutable.fromJS(doc.interactiveTableData) || INITIAL_TABLE_DATA));
    },
    onSaveSuccess: function() {
      return this.setState(this.state.withMutations(function(s) {
        return s.set('dialog', INITIAL_DIALOG_STATE);
      }));
    },
    onCreateOrUpdateError: function() {
      return this.setState(this.state.withMutations(function(s) {
        return s.set('dialog', INITIAL_DIALOG_STATE);
      }));
    },
    onPrepareDataForVisualizationPreview: function() {
      return this.setState(this.state.setIn(['visualizationPreview', 'preparing'], true));
    },
    onDataPreparedSuccess: function(data) {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['visualizationPreview', 'data'], Immutable.fromJS(data));
        return s.setIn(['visualizationPreview', 'preparing'], false);
      }));
    },
    onDataPreparedError: function() {
      return this.setState(this.state.withMutations(function(s) {
        s.setIn(['visualizationPreview', 'data'], Immutable.fromJS({}));
        return s.setIn(['visualizationPreview', 'preparing'], false);
      }));
    },
    isCreateOrEditDialogOpen: function() {
      return this.state.getIn(['dialog', 'isOpen']);
    },
    isApplying: function() {
      return this.state.getIn(['dialog', 'applying']);
    },
    getSettings: function() {
      return this.state.getIn(['visualization', 'settings'], Immutable.Map());
    },
    getEditingVisualization: function() {
      return this.state.getIn(['dialog', 'visualization'], Immutable.Map());
    },
    isFetching: function() {
      return this.state.get('fetching');
    },
    isPreparingData: function() {
      return this.state.getIn(['visualizationPreview', 'preparing'], false);
    },
    getVisualizationDataForPreview: function() {
      return this.state.getIn(['visualizationPreview', 'data'], Immutable.Map());
    },
    getVisualizations: function() {
      return this.state.get('visualizations').sortBy(function(vis) {
        return vis.get('name');
      });
    },
    getCurrentVisualizationId: function() {
      return this.state.getIn(['visualization', '_id'], null);
    },
    getCurrentVisualization: function() {
      return this.state.get('visualization', Immutable.Map());
    },
    getCurrentVisualizationRootId: function() {
      return this.state.getIn(['visualization', 'visualizationRootId'], null);
    },
    getInteractiveTableData: function() {
      return this.state.get('interactiveTableData', Immutable.Map());
    },
    getActiveTab: function() {
      return this.state.getIn(['meta', 'activeTab'], 'document');
    },
    isExportDialogOpen: function() {
      return this.state.getIn(['exportDialog', 'isOpen'], false);
    },
    onCloseExportDialog: function() {
      return this.setState(this.state.set('exportDialog', EXPORT_DIALOG_INITIAL_STATE));
    },
    onOpenExportDialog: function() {
      return this.setState(this.state.setIn(['exportDialog', 'isOpen'], true));
    },
    onOpenSettingsDialog: function() {
      return this.setState(this.state.withMutations((function(_this) {
        return function(s) {
          s.setIn(['settingsDialog', 'isOpen'], true);
          return s.setIn(['settingsDialog', 'settings'], _this.getSettings());
        };
      })(this)));
    },
    isSettingsDialogOpen: function() {
      return this.state.getIn(['settingsDialog', 'isOpen'], false);
    },
    onCloseSettingsDialog: function() {
      this.setState(this.state.setIn(['settingsDialog', 'isOpen'], false));
      return _.delay((function(_this) {
        return function() {
          return _this.setState(_this.state.set('settingsDialog', SETTINGS_DIALOG_INITIAL_STATE));
        };
      })(this), 300);
    },
    onUpdateSettings: function(_arg) {
      var field, value;
      field = _arg.field, value = _arg.value;
      return this.setState(this.state.setIn(['settingsDialog', 'settings', field], value));
    },
    getSettingsDialogState: function() {
      return this.state.get('settingsDialog', Immutable.Map());
    }
  }
});
