var appUtils, keyBy, mediator, utils;

keyBy = require('lodash').keyBy;

mediator = require('mediator');

utils = require('base/lib/utils');

appUtils = {
  checkConnection: function() {
    return mediator.services.replication.isConnected();
  },
  _showErrorAndReportToRaven: function(err, message) {
    var msg;
    msg = (function() {
      if (message) {
        return message;
      } else {
        try {
          return JSON.parse(err.responseText).message;
        } catch (_error) {
          if (err.responseText) {
            return err.responseText;
          } else {
            return err;
          }
        }
      }
    })();
    mediator.dialogs.error(msg);
    return utils.reportRavenError(err);
  },
  errorHandler: function(err) {
    return appUtils._showErrorAndReportToRaven(err);
  },
  errorHandlerWithMsg: function(err, msg) {
    return appUtils._showErrorAndReportToRaven(err, msg);
  },
  errorHandlerWithMsgCurried: function(msg) {
    return function(err) {
      return appUtils.errorHandlerWithMsg(err, msg);
    };
  },
  panelVoiceErrorHandler: function(error) {
    var message;
    message = error.status === 403 ? $.t('voting:panel_voice.errors.forbidden') : void 0;
    return appUtils._showErrorAndReportToRaven(error, message);
  },
  convertRowsToMap: function(rows, keyField) {
    var docs;
    if (keyField == null) {
      keyField = '_id';
    }
    docs = _.chain(rows).pluck('doc').compact().value();
    return Immutable.fromJS(keyBy(docs, keyField));
  },
  convertRowsToArray: function(rows) {
    return _.chain(rows).pluck('doc').compact().value();
  },
  flatPluckCompact: function(obj, field) {
    return _.chain(obj).pluck(field).compact().flatten().value();
  },
  isActiveWorkspaceOrFeatureSwitchOn: function(workspaceId, featureSwitch) {
    return mediator.activeWorkspace === workspaceId || mediator.services.switches.isOn(featureSwitch);
  },
  getOrganizationName: function() {
    if (mediator.services.switches.isServerSwitchOn('acpFeatures')) {
      return 'acp';
    } else {
      return 'gradepro';
    }
  }
};

module.exports = appUtils;
