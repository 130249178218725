var AbstractQuestion, Collection, Footnotes, FootnotesBinding, Outcome, Outcomes, PouchMemento, QS, QUESTION_DEFAULTS, Question, ReferencesBinding, calculateTitles, etdHelper, mediator, prepareDiagnosticOutcomes, utils, _ref,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

AbstractQuestion = require('models/base/abstract_question');

Collection = require('models/base/collection');

Outcome = require('models/outcome');

Outcomes = require('models/outcomes');

Footnotes = require('models/evidence_syntheses/footnotes');

mediator = require('mediator');

FootnotesBinding = require('lib/traits/footnotes_binding');

ReferencesBinding = require('lib/traits/references_binding');

PouchMemento = require('lib/traits/pouch_memento');

QS = require('models/outcome/quality_scores');

utils = require('base/lib/utils');

etdHelper = require('lib/etd_helper');

_ref = require('lib/questions_helper'), QUESTION_DEFAULTS = _ref.QUESTION_DEFAULTS, calculateTitles = _ref.calculateTitles, prepareDiagnosticOutcomes = _ref.prepareDiagnosticOutcomes;

module.exports = Question = (function(_super) {
  __extends(Question, _super);

  function Question() {
    return Question.__super__.constructor.apply(this, arguments);
  }

  Question.TITLE_ATTRIBUTES = ['intervention', 'comparison', 'questionFormat', 'healthProblemOrPopulation', 'targetCondition', 'indexTest', 'comparatorTest', 'comparatorTestPresent', 'diagnosticVerb'];

  Question.QUESTION_FORMATS = [
    {
      value: 'FOR_HP',
      label: $.t('es:question.for_hp_label')
    }, {
      value: 'IN_POP',
      label: $.t('es:question.in_pop_label')
    }
  ];

  Question.prototype.CURRENT_ETR_TREATMENT_VERSION = '1.0';

  Question.prototype.CURRENT_ETR_DIAGNOSTIC_VERSION = '1.0';

  Question.prototype.etrTreatmentVersionHistory = ['0', '1.0'];

  Question.prototype.etrDiagnosticVersionHistory = ['0', '1.0'];

  Question.prototype.defaults = _.extend({}, QUESTION_DEFAULTS, {
    type: '',
    manualTableTitle: false,
    questionNo: 0,
    date: '',
    question: '',
    authors: '',
    sourceOfDxData: null,
    pooledSensitivity: null,
    pooledSensitivityFrom: null,
    pooledSensitivityTo: null,
    pooledSpecificity: null,
    pooledSpecificityFrom: null,
    pooledSpecificityTo: null,
    pooledSensitivityComparison: null,
    pooledSensitivityComparisonFrom: null,
    pooledSensitivityComparisonTo: null,
    pooledSpecificityComparison: null,
    pooledSpecificityComparisonFrom: null,
    pooledSpecificityComparisonTo: null,
    pooledSensitivityRangeFrom: null,
    pooledSensitivityRangeTo: null,
    pooledSensitivityComparisonRangeFrom: null,
    pooledSensitivityComparisonRangeTo: null,
    pooledSpecificityRangeFrom: null,
    pooledSpecificityRangeTo: null,
    pooledSpecificityComparisonRangeFrom: null,
    pooledSpecificityComparisonRangeTo: null,
    prevalence1: '0',
    prevalence1Characteristics: $.t('es:question.prevalence_characteristics_default'),
    prevalence2Characteristics: $.t('es:question.prevalence_characteristics_default'),
    prevalence3Characteristics: $.t('es:question.prevalence_characteristics_default'),
    recommendationIds: [],
    $tableMode: 'regular',
    diagnosticVerb: 'diagnose'
  });

  Question.prototype.nestedCollections = {
    'outcomes': Outcomes
  };

  Question.prototype.numberDecimalFraction = function() {
    return {
      pattern: 'number',
      gt: 0,
      max: 1,
      required: false,
      msg: $.t('errors:model.decimal_fraction')
    };
  };

  Question.prototype.validation = {
    referenceTestShortName: {
      maxLength: 100,
      required: false
    },
    pooledSensitivity: Question.prototype.numberDecimalFraction(),
    pooledSensitivityFrom: Question.prototype.numberDecimalFraction(),
    pooledSensitivityTo: Question.prototype.numberDecimalFraction(),
    pooledSpecificity: Question.prototype.numberDecimalFraction(),
    pooledSpecificityFrom: Question.prototype.numberDecimalFraction(),
    pooledSpecificityTo: Question.prototype.numberDecimalFraction(),
    pooledSensitivityComparison: Question.prototype.numberDecimalFraction(),
    pooledSensitivityComparisonFrom: Question.prototype.numberDecimalFraction(),
    pooledSensitivityComparisonTo: Question.prototype.numberDecimalFraction(),
    pooledSpecificityComparison: Question.prototype.numberDecimalFraction(),
    pooledSpecificityComparisonFrom: Question.prototype.numberDecimalFraction(),
    pooledSpecificityComparisonTo: Question.prototype.numberDecimalFraction(),
    pooledSensitivityRangeFrom: [
      Question.prototype.numberDecimalFraction(), {
        lt: 'pooledSensitivityRangeTo'
      }
    ],
    pooledSensitivityRangeTo: Question.prototype.numberDecimalFraction(),
    pooledSensitivityComparisonRangeFrom: Question.prototype.numberDecimalFraction(),
    pooledSensitivityComparisonRangeTo: Question.prototype.numberDecimalFraction(),
    pooledSpecificityRangeFrom: [
      Question.prototype.numberDecimalFraction(), {
        lt: 'pooledSpecificityRangeTo'
      }
    ],
    pooledSpecificityRangeTo: Question.prototype.numberDecimalFraction(),
    pooledSpecificityComparisonRangeFrom: Question.prototype.numberDecimalFraction(),
    pooledSpecificityComparisonRangeTo: Question.prototype.numberDecimalFraction(),
    prevalence1: {
      pattern: 'number',
      min: 0,
      max: 100,
      atLeastOneRequired: 'prevalence1, prevalence2, prevalence3',
      msg: $.t('errors:model.range_number', {
        from: 0,
        to: 100
      })
    },
    prevalence2: {
      pattern: 'number',
      min: 0,
      max: 100,
      required: false
    },
    prevalence3: {
      pattern: 'number',
      min: 0,
      max: 100,
      required: false
    },
    prevalence1Characteristics: {
      maxLength: 200,
      required: false
    },
    prevalence2Characteristics: {
      maxLength: 200,
      required: false
    },
    prevalence3Characteristics: {
      maxLength: 200,
      required: false
    }
  };

  Question.prototype.setDiagnosticDefaults = function() {
    this.set({
      questionFormat: Question.QUESTION_FORMATS[1].value
    });
    return this.set('outcomes', this.getDiagnosticOutcomes());
  };

  Question.prototype.getDiagnosticOutcomes = function() {
    var diagnosticOutcomes;
    diagnosticOutcomes = new Outcomes();
    return diagnosticOutcomes.add(prepareDiagnosticOutcomes());
  };

  Question.prototype.setTreatmentDefaults = function() {
    if (!this.get('outcomes')) {
      return this.set('outcomes', new Outcomes());
    }
  };

  Question.prototype.setAdditionalDefaults = function() {
    if (!this.get('coi')) {
      this.set('coi', {});
    }
    if (!this.isNew()) {
      return;
    }
    if (this.isDiagnostic()) {
      return this.setDiagnosticDefaults();
    } else {
      return this.setTreatmentDefaults();
    }
  };

  Question.prototype._setEtrVersions = function() {
    if (this.isNew()) {
      this.set('version_etr_treatment', this.CURRENT_ETR_TREATMENT_VERSION);
      return this.set('version_etr_diagnostic', this.CURRENT_ETR_DIAGNOSTIC_VERSION);
    }
  };

  Question.prototype._updateDiagnosticQuestion = function() {
    if (!this.isDiagnostic()) {
      return;
    }
    if (this.get('indexTest') == null) {
      this.set('indexTest', this.get('intervention'));
    }
    if (this.get('comparatorTest') == null) {
      return this.set('comparatorTest', this.get('comparison'));
    }
  };

  Question.prototype.initialize = function(attributes, options) {
    this.setAdditionalDefaults();
    this._updateDiagnosticQuestion();
    this._setEtrVersions();
    Question.__super__.initialize.apply(this, arguments);
    this.enable(FootnotesBinding);
    this.enable(ReferencesBinding);
    this.enable(PouchMemento);
    this.on((Question.TITLE_ATTRIBUTES.map(function(prop) {
      return "change:" + prop;
    })).join(' '), function() {
      return this.set(this.calculateTitles());
    });
    if (!this.get('footnotesList')) {
      this.set('footnotesList', new Footnotes());
    }
    return this.set(this.calculateTitles());
  };

  Question.prototype.duplicate = function() {
    var clonnedAttrs, duplicated, recommendationIds;
    clonnedAttrs = this.parse(_(this.toJSON()).omit('_id', '_rev', 'orderNumber', 'recommendationIds'));
    recommendationIds = this.get('recommendationIds');
    duplicated = new Question(clonnedAttrs, {
      wait: true
    });
    if (!_(recommendationIds).isEmpty()) {
      this.duplicateRecommendationTable(recommendationIds, duplicated);
    }
    return duplicated;
  };

  Question.prototype.destroy = function() {
    var recommendationIds;
    recommendationIds = this.get('recommendationIds').slice();
    if (!_(recommendationIds).isEmpty()) {
      etdHelper.removeRecommendationTable(recommendationIds).then((function(_this) {
        return function(deletedEtds) {
          _this.deletedEtds = deletedEtds;
        };
      })(this));
    }
    return Question.__super__.destroy.apply(this, arguments);
  };

  Question.prototype.undoDelete = function() {
    return this.save().then((function(_this) {
      return function() {
        if (_this.deletedEtds) {
          return etdHelper.restoreRecommendationTables(_this.deletedEtds);
        }
      };
    })(this));
  };

  Question.prototype.dispose = function() {
    this.deletedEtds = null;
    return Question.__super__.dispose.apply(this, arguments);
  };

  Question.prototype.duplicateRecommendationTable = function(recommendationIds, question) {
    var duplicator, idChecker, intervalId;
    duplicator = function() {
      return etdHelper.duplicateRecommendationTable(recommendationIds, question.id).then(function(recommendationsTable) {
        return question.save('recommendationIds', [recommendationsTable._id]);
      });
    };
    idChecker = function() {
      if (question.id) {
        clearInterval(intervalId);
        return duplicator();
      }
    };
    if (question.id) {
      return duplicator();
    } else {
      return intervalId = setInterval(idChecker, 100);
    }
  };

  Question.prototype.calculateTitles = function(attributes) {
    var msgKey, params, postfix, questionFormat, titles;
    if (attributes == null) {
      attributes = this.attributes;
    }
    params = _.extend(_(attributes).pick(Question.TITLE_ATTRIBUTES), {
      type: attributes.type
    });
    questionFormat = attributes.questionFormat;
    titles = calculateTitles(params);
    if (!attributes.manualTableTitle) {
      titles.tableTitle = _.str.capitalize(attributes.type === 'diagnostic' ? (postfix = attributes.comparatorTestPresent ? '' : '_short', postfix += (function() {
        switch (attributes.diagnosticVerb) {
          case 'screen':
            return '_screen';
          case 'stage':
            return '_stage';
          default:
            return '';
        }
      })(), msgKey = "es:question.templates.diagnostic_sof" + postfix, $.t(msgKey, params)) : $.t("es:question.templates." + questionFormat + "_sof", params));
    }
    return titles;
  };

  Question.prototype.getCriticalOutcomes = function() {
    return this.get('outcomes').filter(function(outcome) {
      return outcome.isCritical();
    });
  };

  Question.prototype.getCriticalAndImportantOutcomes = function() {
    return this.get('outcomes').filter(function(outcome) {
      return outcome.isCritical() || outcome.isImportant();
    });
  };

  Question.prototype.setConflictStatusForMember = function(memberId, status) {
    var currentStatus;
    currentStatus = this.get('coi')[memberId];
    if (status === currentStatus) {
      return;
    }
    this.get('coi')[memberId] = status;
    this.trigger('change:coi', this, this.get('coi'));
    return this.trigger('change', this);
  };

  Question.prototype.checkForNewEtrVersion = function() {
    var currentVersion, modelVersion, _ref1;
    _ref1 = this.isDiagnostic() ? [this.CURRENT_ETR_DIAGNOSTIC_VERSION, this.get('version_etr_diagnostic')] : [this.CURRENT_ETR_TREATMENT_VERSION, this.get('version_etr_treatment')], currentVersion = _ref1[0], modelVersion = _ref1[1];
    if (parseFloat(currentVersion) > parseFloat(modelVersion != null ? modelVersion : 0)) {
      return currentVersion;
    }
  };

  Question.prototype._treatmentMappings = {
    '0 -> 1.0': {
      qualityJudgement: 'overallCertaintyJudgement',
      resourcesJudgement: {
        to: 'incrementalCostToNetBenefitsJudgement',
        valuesMapping: {
          very_small: 'yes',
          small: 'probably_yes',
          borderline: 'uncertain',
          high: 'probably_no',
          very_high: 'no'
        }
      },
      equityJudgement: {
        to: 'equityJudgement',
        valuesMapping: {
          high: 'increased',
          moderate: 'probably_increased',
          uncertain: 'uncertain',
          low: 'probably_reduced',
          very_low: 'reduced'
        }
      },
      consequencesBalance: {
        to: 'consequencesBalance',
        valuesMapping: {
          clearly_negative: 'clearly_negative',
          probably_negative: 'probably_negative',
          balance_too_certain: 'closely_balanced_or_uncertain',
          balance_very_similar: 'closely_balanced_or_uncertain',
          probably_positive: 'probably_positive',
          clearly_positive: 'clearly_positive'
        }
      },
      qualityExplanation: {
        to: 'benefitsHarmsAdditionalConsiderations',
        method: 'append'
      },
      benefitsHarmsExplanation: {
        to: 'benefitsHarmsAdditionalConsiderations',
        method: 'append'
      },
      resourcesExplanation: 'incrementalCostToNetBenefitsAdditionalConsiderations',
      equityExplanation: 'equityAdditionalConsiderations',
      remarksJustifications: 'recommendationJustification',
      implementationFeasibility: 'implementationConsiderations'
    }
  };

  Question.prototype.updateEtrIfNecessary = function() {
    var fromVersion, fromVersionIndex, newVersion;
    newVersion = this.checkForNewEtrVersion();
    if (!newVersion) {
      return false;
    }
    if (this.isDiagnostic()) {

    } else {
      fromVersion = this.get('version_etr_treatment');
      fromVersionIndex = this.etrTreatmentVersionHistory.indexOf(fromVersion != null ? fromVersion : '0');
      while (this.etrTreatmentVersionHistory[fromVersionIndex] !== newVersion) {
        this._mapEtrTreatment(fromVersionIndex);
        fromVersionIndex += 1;
      }
    }
    this.save();
    return true;
  };

  Question.prototype._mapEtrTreatment = function(fromVersionIndex) {
    var appended, fromField, fromVersion, mappingKey, mappings, toField, toVersion, value;
    fromVersion = this.etrTreatmentVersionHistory[fromVersionIndex];
    toVersion = this.etrTreatmentVersionHistory[fromVersionIndex + 1];
    mappingKey = "" + fromVersion + " -> " + toVersion;
    mappings = this._treatmentMappings[mappingKey];
    for (fromField in mappings) {
      toField = mappings[fromField];
      switch (typeof toField) {
        case 'string':
          this.set(toField, this.get(fromField));
          break;
        case 'object':
          value = this.get(fromField);
          if (!value) {
            continue;
          }
          if (toField.valuesMapping) {
            this.set(toField.to, toField.valuesMapping[value]);
          } else if (toField.method) {
            switch (toField.method) {
              case 'append':
                appended = _.compact([this.get(toField.to), value]).join('<br>');
                this.set(toField.to, appended);
            }
          }
      }
    }
    return this.set('version_etr_treatment', toVersion);
  };

  return Question;

})(AbstractQuestion);
