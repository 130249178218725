var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

_.namespace(module, function(require) {
  var APPENDIX_EVIDENCE_TABLES_ID, DraftContentRenderer, EditorDecorators, HtmlExport, MDGQuestionDoc, MdgRecommendationSectionContent, Printout, Question, QuestionDoc, ReferencesStore, W, get, getRecommendationMetadata, getRecommendationMetadataOrUndefined, i18nForMixin, mediator, removeHTMLNode, sequence, _exportMdgSection, _exportMdgTable, _exportRegularSection, _exportRelatedTables, _getRecommendationDataOrUndefinedWithTranslation, _getRecommendationDataWithTranslation, _getRecommendationWithTranslation, _ref, _renderDraftData;
  APPENDIX_EVIDENCE_TABLES_ID = require('lib/services/document_sections_exporter/utils').APPENDIX_EVIDENCE_TABLES_ID;
  EditorDecorators = require('lib/editor_decorators');
  get = require('lodash').get;
  _ref = require('lib/services/covid_exporter/utils'), getRecommendationMetadata = _ref.getRecommendationMetadata, getRecommendationMetadataOrUndefined = _ref.getRecommendationMetadataOrUndefined;
  HtmlExport = require('lib/html_export');
  i18nForMixin = require('lib/react_utils').i18nForMixin;
  MDGQuestionDoc = require('lib/db_docs/mdg_question_doc');
  mediator = require('mediator');
  Printout = MDGTables.Printout;
  MdgRecommendationSectionContent = require('components/document_sections/v2/mdg_recommendation_section_content');
  DraftContentRenderer = require('lib/draft_utils').DraftContentRenderer;
  Question = require('models/question');
  QuestionDoc = require('lib/db_docs/question_doc');
  ReferencesStore = require('stores/references_store');
  removeHTMLNode = require('base/lib/utils').removeHTMLNode;
  sequence = require('when/sequence');
  W = require('when/when');
  _getRecommendationDataWithTranslation = function(recommendation, key) {
    var maybeValue;
    maybeValue = getRecommendationMetadata(recommendation, key);
    return _.map(maybeValue, function(value) {
      return $.t("es:recommendations.metadataExtractionForm.values." + key + "." + value);
    });
  };
  _getRecommendationDataOrUndefinedWithTranslation = function(recommendation, key) {
    var maybeValue;
    maybeValue = getRecommendationMetadataOrUndefined(recommendation, key);
    if (!maybeValue) {
      return void 0;
    }
    return $.t("es:recommendations.metadataExtractionForm.values." + key + "." + maybeValue);
  };
  _getRecommendationWithTranslation = function(recommendation) {
    var maybeValue, option, options;
    maybeValue = get(recommendation, 'templateData.conclusions.sections.mdgRecommendation.selectedOption');
    if (!maybeValue) {
      return void 0;
    }
    options = get(recommendation, 'templateData.conclusions.sections.mdgRecommendation.options');
    option = _.find(options, function(_arg) {
      var value;
      value = _arg.value;
      return value === maybeValue;
    });
    return option != null ? option.text : void 0;
  };
  _renderDraftData = function(recommendation, renderer, key) {
    var content, contentHTML;
    content = get(recommendation, "templateData.conclusions.sections." + key + ".content");
    contentHTML = renderer.rawContentToHTML(content);
    return contentHTML || void 0;
  };
  _exportMdgTable = function(references, tableTitle, exportParams, reactContainer) {
    return function(questionDoc) {
      var div, exportDate, guidelineId, hasStudies, htmlTable, i18n, linkPrefix, referencesToPass, section, tableData, tableDataToPass, tableElement, tablePath, tableSectionId, usedReferencesInTable;
      hasStudies = !_.isEmpty(questionDoc.studies);
      if (!hasStudies) {
        return;
      }
      exportDate = exportParams.exportDate, guidelineId = exportParams.guidelineId, linkPrefix = exportParams.linkPrefix;
      i18n = i18nForMixin('mdg_tables:tables');
      tableElement = React.createElement(Printout, {
        i18n: i18n,
        references: references,
        studies: questionDoc.studies,
        type: questionDoc.type
      });
      ReactDOM.render(tableElement, reactContainer);
      usedReferencesInTable = _.chain(questionDoc.studies).pluck('referenceId').compact().value();
      div = document.createElement('div');
      div.innerHTML = reactContainer.innerHTML;
      ReactDOM.unmountComponentAtNode(reactContainer);
      tableSectionId = "" + guidelineId + "/" + APPENDIX_EVIDENCE_TABLES_ID + "/" + questionDoc._id;
      tablePath = "" + linkPrefix + guidelineId + "/" + APPENDIX_EVIDENCE_TABLES_ID + "/" + questionDoc._id;
      referencesToPass = _.chain(references).filter(function(ref) {
        var _ref1;
        return _ref1 = ref._id, __indexOf.call(usedReferencesInTable, _ref1) >= 0;
      }).map(function(ref) {
        return _.omit(ref, '_rev');
      }).value();
      tableDataToPass = _.omit(questionDoc, '_rev');
      tableData = {
        references: referencesToPass,
        table: tableDataToPass
      };
      htmlTable = $(div).find('#mdg-printout').removeAttr('id').addClass('mdg-table-printout').attr('id', questionDoc._id).attr('data-table-id', JSON.stringify(questionDoc._id)).attr('data-table', JSON.stringify(tableDataToPass)).attr('data-references', JSON.stringify(referencesToPass)).prop('outerHTML');
      removeHTMLNode(div);
      section = {
        id: tableSectionId,
        guid: questionDoc._id,
        chapterId: 'appendix-evidence-tables',
        guidelineId: guidelineId,
        htmlContent: htmlTable,
        lastModified: exportDate,
        tableData: tableData,
        title: tableTitle,
        type: 'table'
      };
      return {
        references: usedReferencesInTable,
        sections: [section],
        tablePath: tablePath
      };
    };
  };
  _exportRelatedTables = function(projectId, references, tableTitle, exportParams, reactContainer, tableIds) {
    if (_.isEmpty(tableIds)) {
      return W.resolve([]);
    }
    return sequence(_.map(tableIds, function(tableId) {
      return function() {
        return MDGQuestionDoc.at(projectId, tableId).fetch();
      };
    })).then(function(tables) {
      var exportResults;
      exportResults = _.chain(tables).compact().map(_exportMdgTable(references, tableTitle, exportParams, reactContainer)).compact().value();
      return _.reduce(['references', 'sections', 'tablePath'], function(acc, key) {
        acc[key] = _.chain(exportResults).pluck(key).flatten().value();
        return acc;
      }, {});
    });
  };
  _exportMdgSection = function(section, chapterId, exportParams) {
    var exportDate, guidelineId, guidelineTitle, projectId, questionId, reactContainer, recommendationId, references, sectionId, topics;
    sectionId = section._id, questionId = section.questionId, recommendationId = section.recommendationId;
    exportDate = exportParams.exportDate, guidelineId = exportParams.guidelineId, guidelineTitle = exportParams.guidelineTitle, projectId = exportParams.projectId, topics = exportParams.topics;
    reactContainer = document.createElement('div');
    references = ReferencesStore.getDeduplicatedReferences().toJS();
    return W.all([
      MDGQuestionDoc.at(projectId, questionId).fetch(), mediator.services.storePersistenceAdapter.fetch(projectId, recommendationId, {
        attachments: true
      })
    ]).then(function(_arg) {
      var questionDoc, recommendationDoc, relatedTableIds, tableTitle;
      questionDoc = _arg[0], recommendationDoc = _arg[1];
      _.forEach(questionDoc.studies, function(study) {
        return delete study['internalComments'];
      });
      relatedTableIds = getRecommendationMetadata(recommendationDoc, 'relatedTable');
      tableTitle = getRecommendationMetadataOrUndefined(recommendationDoc, 'recommendationTitle');
      if (_.isEmpty(tableTitle)) {
        tableTitle = questionDoc.question;
      }
      return _exportRelatedTables(projectId, references, tableTitle, exportParams, reactContainer, relatedTableIds).then(function(relatedTablesData) {
        var dartData, div, draftRenderer, draftSections, fullReferencesData, htmlRecommendation, recommendationData, recommendationElement, recommendationSection, referencesFromRecommendation, referencesIdsFromRecommendation, tablePath, tableReferences, tableSection, tableSectionData, tablesPaths, tablesReferences, tablesSections, topicsInRec, topicsUsed, _ref1;
        tableSectionData = _exportMdgTable(references, tableTitle, exportParams, reactContainer)(questionDoc);
        tableSection = tableSectionData != null ? tableSectionData.sections[0] : void 0;
        tablePath = tableSectionData != null ? tableSectionData.tablePath : void 0;
        tableReferences = (_ref1 = tableSectionData != null ? tableSectionData.references : void 0) != null ? _ref1 : [];
        tablesSections = _.compact(_.union([tableSection], relatedTablesData.sections));
        tablesPaths = _.compact(_.union([tablePath], relatedTablesData.tablePath));
        tablesReferences = _.chain(tableReferences).union(relatedTablesData.references).uniq().value();
        topicsInRec = getRecommendationMetadata(recommendationDoc, 'topic');
        topicsUsed = _.chain(topics).filter(function(_arg1) {
          var _id;
          _id = _arg1._id;
          return __indexOf.call(topicsInRec, _id) >= 0;
        }).pluck('name').sort().uniq().value();
        draftRenderer = new DraftContentRenderer({
          customDecorators: EditorDecorators.referenceDecorator
        });
        draftSections = _.mapObject({
          benefits: 'benefits',
          evidence: 'evidence',
          frequencyDoseDuration: 'frequencyDoseDuration',
          harms: 'harms',
          indications: 'indications',
          indicationsForDiscontinuation: 'indicationsForDiscontinuation',
          rationale: 'rationale',
          recommendationText: 'mdgRecommendation'
        }, function(etdKey) {
          return _renderDraftData(recommendationDoc, draftRenderer, etdKey);
        });
        draftRenderer.dispose();
        dartData = _.extend(draftSections, {
          category: _getRecommendationDataWithTranslation(recommendationDoc, 'category'),
          conditionSeverity: _getRecommendationDataWithTranslation(recommendationDoc, 'conditionSeverity'),
          evidenceTable: tablePath,
          intervention: get(questionDoc, 'intervention.name'),
          guidelineTitle: guidelineTitle,
          levelOfConfidence: _getRecommendationDataOrUndefinedWithTranslation(recommendationDoc, 'levelOfConfidence'),
          links: getRecommendationMetadata(recommendationDoc, 'links'),
          operativePhase: _getRecommendationDataWithTranslation(recommendationDoc, 'operativePhase'),
          phase: _getRecommendationDataWithTranslation(recommendationDoc, 'phaseOfCare'),
          recommendation: _getRecommendationWithTranslation(recommendationDoc),
          recommendationGuid: recommendationId,
          relatedTables: relatedTablesData.tablePath,
          strengthOfEvidence: _getRecommendationDataOrUndefinedWithTranslation(recommendationDoc, 'strengthOfEvidence'),
          topics: topicsUsed
        });
        recommendationData = {
          dart: dartData,
          question: _.extend({
            id: questionDoc._id
          }, _.pick(questionDoc, 'intervention', 'population', 'question')),
          recommendation: _.omit(recommendationDoc, '_id', '_rev')
        };
        recommendationElement = React.createElement(MdgRecommendationSectionContent, {
          mode: 'export',
          question: Immutable.fromJS(questionDoc),
          questionLinks: tablesPaths,
          recommendation: Immutable.fromJS(recommendationDoc)
        });
        ReactDOM.render(recommendationElement, reactContainer);
        div = document.createElement('div');
        div.innerHTML = reactContainer.innerHTML;
        ReactDOM.unmountComponentAtNode(reactContainer);
        referencesIdsFromRecommendation = [];
        fullReferencesData = [];
        $(div).find('span[data-refid]').each(function(_idx, element) {
          var refId, _ref2;
          refId = element.dataset['refid'];
          if (!_.isEmpty(refId)) {
            referencesIdsFromRecommendation.push(refId);
          }
          return fullReferencesData.push(JSON.parse((_ref2 = element.dataset['refs']) != null ? _ref2 : '{}'));
        });
        referencesFromRecommendation = _.chain(references).filter(function(ref) {
          var _ref2;
          return _ref2 = ref._id, __indexOf.call(referencesIdsFromRecommendation, _ref2) >= 0;
        }).map(function(ref) {
          return [ref._id, _.omit(ref, '_rev')];
        }).object().value();
        htmlRecommendation = $(div).find('.mdg-recommendation').attr('id', sectionId).attr('data-recommendation-id', JSON.stringify(recommendationDoc._id)).attr('data-recommendation', JSON.stringify(recommendationData.recommendation)).attr('data-question', JSON.stringify(recommendationData.question)).attr('data-references', JSON.stringify(referencesFromRecommendation)).prop('outerHTML');
        removeHTMLNode(div);
        recommendationSection = {
          id: "" + guidelineId + "/" + chapterId + "/" + sectionId,
          guid: sectionId,
          chapterId: chapterId,
          guidelineId: guidelineId,
          htmlContent: htmlRecommendation,
          lastModified: exportDate,
          recommendationData: recommendationData,
          type: 'recommendation'
        };
        return {
          references: referencesIdsFromRecommendation,
          fullReferencesData: fullReferencesData,
          referencesFromTables: tablesReferences,
          sections: _.compact(_.union([recommendationSection], tablesSections))
        };
      });
    })["finally"](function() {
      return removeHTMLNode(reactContainer);
    });
  };
  _exportRegularSection = function(section, chapterId, exportParams) {
    var exportDate, guidelineId, questionId, recommendationId, sectionId;
    sectionId = section._id, questionId = section.questionId, recommendationId = section.recommendationId;
    exportDate = exportParams.exportDate, guidelineId = exportParams.guidelineId;
    return QuestionDoc.at(mediator.project.id, questionId).fetch().then(function(question) {
      var exporter, questionModel;
      questionModel = new Question(question);
      exporter = new HtmlExport(questionModel, 'sof', exportParams);
      return exporter.getSource('portrait', {}, 'PrintoutSOF');
    }).then(function(sofSource) {
      return {
        references: [],
        referencesFromTables: [],
        sections: [
          {
            id: "" + guidelineId + "/" + chapterId + "/" + sectionId,
            guid: sectionId,
            chapterId: chapterId,
            guidelineId: guidelineId,
            lastModified: exportDate,
            recommendationData: {
              questionId: questionId,
              recommendationId: recommendationId,
              sofSource: sofSource
            },
            type: 'recommendation'
          }
        ]
      };
    });
  };
  return {
    exportSection: function(section, chapterId, exportParams) {
      if (mediator.services.switches.isOn('mdgTables')) {
        return _exportMdgSection(section, chapterId, exportParams);
      } else {
        return _exportRegularSection(section, chapterId, exportParams);
      }
    }
  };
});
