BatchEditorOptionsModal = require 'components/document_sections/v2/batch_editor_options_modal'
ChaptersList = require 'components/document_sections/v2/chapters_list'
ConnectStore = require 'components/enhancers/connect_store'
DocumentSectionsV2Store = require 'stores/document_sections_v2_store'
EDMSActions = require 'actions/edms_actions'
EDMSInsertModal = require 'components/common/edms/edms_insert_modal'
EDMSStore = require 'stores/edms_store'
MdaTopicsStore = require 'stores/mda_topics_store'
mediator = require 'mediator'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
ReferencesActions = require 'actions/references_actions'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferencesStore = require 'stores/references_store'
Sidebar = require 'components/document_sections/v2/sidebar'
Spinner = require 'components/common/spinner'

MdaTopic = createReactClass
  displayName: 'MdaTopic'

  propTypes:
    batchEditorOptions: PropTypes.instanceOf(Immutable.Map).isRequired
    rootChaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired
    isEDMSInsertModalOpen: PropTypes.bool.isRequired
    insertEDMSHandler: PropTypes.func.isRequired
    isFetchingChapters: PropTypes.bool.isRequired
    isFetchingQuestionGroups: PropTypes.bool.isRequired
    isFetchingQuestions: PropTypes.bool.isRequired
    suggesting: PropTypes.bool.isRequired
    currentDiscussionsFilters: PropTypes.instanceOf(Immutable.Map).isRequired
    editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
    editingDiscussionReplies: PropTypes.instanceOf(Immutable.Map).isRequired
    activeDiscussionItem: PropTypes.oneOfType [
      PropTypes.string
      PropTypes.arrayOf PropTypes.string
    ]
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number.isRequired
    })
    topicId: PropTypes.string.isRequired
    isInsertModalOpen: PropTypes.bool.isRequired
    initialReferences: PropTypes.instanceOf(Immutable.Map).isRequired
    insertReferencesHandler: PropTypes.func.isRequired

  render: ->
    {
      activeDiscussionItem,
      activeSidebarTab,
      batchEditorOptions,
      chapters,
      currentDiscussionsFilters,
      discussions,
      editingDiscussionItems,
      editingDiscussionReplies,
      initialReferences,
      insertReferencesHandler,
      insertEDMSHandler,
      isEDMSInsertModalOpen,
      isFetchingChapters,
      isFetchingQuestionGroups,
      isFetchingQuestions,
      isInsertModalOpen,
      questionGroups,
      questions,
      rootChaptersOrder,
      scrollToSectionContent,
      suggesting,
      topicId
    } = @props

    if isFetchingChapters || isFetchingQuestionGroups || isFetchingQuestions
      <Spinner />
    else
      <div className="document-sections-v2 h-full overflow-hidden">
        <ChaptersList
          chaptersOrder={rootChaptersOrder}
          chapters={chapters}
          questionGroups={questionGroups}
          questions={questions}
          scrollToSectionContent={scrollToSectionContent}
          mdaTopicId={topicId}
        />
        <Sidebar
          chaptersOrder={rootChaptersOrder}
          chapters={chapters}
          activeTab={activeSidebarTab}
          discussions={discussions}
          activeDiscussionItem={activeDiscussionItem}
          currentDiscussionsFilters={currentDiscussionsFilters}
          suggesting={suggesting}
          editingDiscussionItems={editingDiscussionItems}
          editingDiscussionReplies={editingDiscussionReplies}
          mdaTopicId={topicId}
        />
        {if isInsertModalOpen
          <ReferencesInsertModal
            onClose={ReferencesActions.closeInsertModal}
            onInsert={insertReferencesHandler}
            initialReferences={initialReferences}
          />
        }
        {isEDMSInsertModalOpen and <EDMSInsertModal
          onClose={EDMSActions.closeImportDialog}
          onInsert={insertEDMSHandler}
        />}
        {batchEditorOptions.get('modalOpen') && <BatchEditorOptionsModal
          batchEditorOptions={batchEditorOptions}
        />}
      </div>

storeConnectors =
  DocumentSectionsV2Store: (Store) ->
    batchEditorOptions: Store.getBatchEditorOptions()
    rootChaptersOrder: Store.getRootChaptersOrder()
    chapters: Store.getChapters()
    discussions: Store.getResultingDiscussions()
    activeSidebarTab: Store.getActiveSidebarTab()
    activeDiscussionItem: Store.getActiveDiscussionItem()
    currentDiscussionsFilters: Store.getDiscussionsFilters()
    suggesting: Store.getSuggesting()
    editingDiscussionItems: Store.getEditingDiscussionItems()
    editingDiscussionReplies: Store.getEditingDiscussionReplies()
    scrollToSectionContent: Store.getScrollToSectionContent()
  MdaTopicsStore: (Store) ->
    isFetchingChapters: Store.isFetching()
  QuestionGroupsStore: (Store) ->
    isFetchingQuestionGroups: Store.isFetching()
    questionGroups: Store.getQuestionGroups()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getQuestions()
  ReferencesStore: (Store) ->
    isInsertModalOpen: Store.isInsertModalOpen()
    insertReferencesHandler: Store.getInsertModalInsertHandler()
    initialReferences: Store.getInitialReferencesForInsert()
  EDMSStore: (Store) ->
    isEDMSInsertModalOpen: Store.isInsertModalOpen()
    insertEDMSHandler: Store.getInsertHandler()

module.exports = ConnectStore MdaTopic,
  [
    DocumentSectionsV2Store,
    EDMSStore,
    MdaTopicsStore,
    QuestionGroupsStore,
    QuestionsStore,
    ReferencesStore
  ],

  storeConnectors
