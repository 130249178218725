Spinner = require 'components/common/spinner'
LoginForm = require './login_form'
PasswordResetForm = require './password_reset_form'
CopyrightNotice = require './copyright_notice'
Translation = require 'components/mixins/translation'
Exceptions = require 'lib/exceptions'
mediator = require 'mediator'

EnterpriseAuthenticationScreen = createReactClass
  displayName: 'EnterpriseAuthenticationScreen'

  mixins: [Translation('registration')]

  propTypes:
    administratorEmail: PropTypes.string.isRequired
    service: PropTypes.shape
      login: PropTypes.func.isRequired
      getLocalAccountInformation: PropTypes.func.isRequired
      logout: PropTypes.func.isRequired
    modal: PropTypes.bool.isRequired
    intent: PropTypes.string
    preselection: PropTypes.shape
      licence: PropTypes.string
      term: PropTypes.string
      currency: PropTypes.string

  getInitialState: ->
    email: if @props.modal then mediator.user.getEmail() else ''
    password: ''
    action: 'login'

  emailChanged: (e) -> @setState(email: e.target.value)
  passwordChanged: (e) -> @setState(password: e.target.value)
  switchAction: (action) -> (e) => @setState({action})

  switchUser: ->
    # for now it will completely logout user, and redirect him to login page
    @props.service.logout().then -> mediator.publish '!reloadApplication'

  onNoSubscription: (error) ->
    @setState(action: 'subscriptions', previousSubscription: error.previousSubscription)

  onLogin: (loginData) ->
    # TODO: good place to launch domain migration
    mediator.publish 'login', loginData.login, loginData.status

  onInit: (message) =>
    if message.action is 'initialization'
      @setState action: 'loading', message: @i18n('db_initialization.start')
    else if @state.message?
      @setState action: 'loading', message: @i18n('db_initialization.#{message}')

  login: ->
    @props.service.login(@state.email, @state.password).then @onLogin, (error) =>
      if error instanceof Exceptions.authentication.no_active_subscription
        @onNoSubscription(error)
      else
        throw error
    , @onInit

  renderAction: ->
    switch @state.action
      when 'loading'
        <form>
          <div className='info'>{@state.message}</div>
          <Spinner />
        </form>
      when 'login'
        <LoginForm email={@state.email} onEmailChange={@emailChanged} emailDisabled={@props.modal}
          password={@state.password} onPasswordChange={@passwordChanged}
          forgotPassword={@switchAction('password_reset')} service={@props.service}
          onLogin={@onLogin} onInit={@onInit} onNoSubscription={@onNoSubscription}
          logoSrc='images/enterprise_logo.svg' withTitle={false} withLabeledFields={false}
          withLanguageSelector={not @props.modal} withPlaceholders />
      when 'password_reset'
        <PasswordResetForm email={@state.email} onEmailChange={@emailChanged}
          service={@props.service} logoSrc='images/enterprise_logo.svg' withTitle={false}
          withLabeledFields={false} withPlaceholders />

  renderActionSwitch: ->
    return null unless @state.action is 'password_reset'
    <button className='back-to-login-switch' onClick={@switchAction('login')}>
      {@i18n('log_in')}
    </button>

  render: ->
    { administratorEmail } = @props

    <div className="authentication-view__enterprise #{window.gdt.enterpriseId}">
      <div className='triangle' />
      <div className='authentication'>
        <div className='form-content'>
          {switch window.gdt.enterpriseId
            when 'ec'
              <div className='eu-logo'>
                {# <img src='images/ue.png' /> }
              </div>
            when 'conitec'
              <div className='conitec-logos'>
                <img src='images/brazil-gf-logo.png' />
                <img src='images/conitec-logo.png' />
              </div>
            when 'acp'
              <div className='acp-logo'>
                <img src='images/acp-logo.png' />
              </div>
            when 'rcp'
              <div className='nice-logo'>
                <img src='images/nice-logo.png' />
              </div>
            when 'covid'
              <div className='covid-logos'>
                <img src='images/covid.svg' />
                <img src='images/cihr-logo.svg' />
              </div>
            when 'ash'
              <div className='ash-logo'>
                <img src='images/ash-logo.svg' />
              </div>
            when 'minsal'
              <div className='minsal-logo'>
                <img src='images/minsal-logo.png' />
              </div>
            when 'cadth'
              <div className='cadth-logo'>
                <img src='images/cadth-logo.png' />
              </div>
            when 'tb'
              <div className='tb-logo'>
                <img src='images/who-logo.png' />
              </div>
            when 'elsevier'
              <div className='elsevier-logos'>
                <img src='images/elsevier-logo.png' />
                <img src='images/health-holding-company-logo.png' />
              </div>
            when 'mdg'
              <div className='mdg-logo'>
                <img src='images/mdg-logo.png' />
              </div>
            else null
          }
          {@renderAction()}
          {@renderActionSwitch()}
          <div className='contact-details'>
            {if window.gdt.enterpriseId is 'rcp'
              <div className="text-left">
                 {@i18n '/administrator_contact'}
              </div>
            else
              @i18n '/enterprise_administrator_contact', null,
                email: <div><a href={"mailto:#{administratorEmail}"}>{administratorEmail}</a></div>
            }
          </div>
        </div>
        {if @props.modal
          <div className='overlay'></div>
        else
          <CopyrightNotice />
        }
      </div>
    </div>

module.exports = EnterpriseAuthenticationScreen
