alt = require 'alt'
EtdStore = require 'stores/etd_store'
EtdActions = require 'actions/etd_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
Button = require 'components/common/button'
Modal = require 'components/common/modal'
DetailedJudgements = require './detailed_judgements'
OptionsList = require 'components/common/options_list'
DialogBoxMixin = require 'components/mixins/dialog_box_mixin'
RankingJudgement = require 'components/etd/judgements/ranking_judgement'
RankingJudgementV2 = require 'components/etd/judgements/ranking_judgement_v2'
JudgementTable = require 'components/etd/judgements/judgement_table'
Confirmation = require 'components/common/confirmation'
{ bool, func, instanceOf, string, number } = PropTypes

{ RadioButtonList } = ReactComponents

JudgementComponent = createReactClass
  displayName: 'JudgementComponent'

  propTypes:
    className: PropTypes.string
    criterion: PropTypes.instanceOf(Immutable.Map).isRequired
    criticalOutcomes: PropTypes.instanceOf(Immutable.List)
    editable: PropTypes.bool.isRequired
    highlightAsDiff: PropTypes.bool
    highlighted: PropTypes.bool
    isConsensus: PropTypes.bool
    noAutoSave: PropTypes.bool
    onEditClick: PropTypes.func
    onJudgementChange: PropTypes.func
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    parentSectionsData: PropTypes.instanceOf(Immutable.List)
    qualityIndicators: PropTypes.instanceOf(Immutable.List)
    questionType: PropTypes.string.isRequired
    readOnly: PropTypes.bool
    renderMode: PropTypes.string
    sectionData: PropTypes.instanceOf(Immutable.Map).isRequired
    sectionId: PropTypes.string.isRequired
    sectionName: PropTypes.string.isRequired
    selectedOption: PropTypes.string
    showJudgement: PropTypes.bool
    templateId: PropTypes.string.isRequired
    type: PropTypes.oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])

  getDefaultProps: ->
    className: ''
    highlightAsDiff: false
    isConsensus: false
    noAutoSave: false
    overarchingQuestionData: Immutable.Map()
    parentSectionsData: Immutable.List()
    qualityIndicators: Immutable.List()
    renderMode: 'regular'
    rowSpan: 1
    type: 'regular'

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
    DialogBoxMixin
  ]

  getInitialState: ->
    isModalOpen: false

  openModal: ->
    return if @props.readOnly or not @props.editable
    @etdStoreSnapshot = alt.takeSnapshot EtdStore
    @setState isModalOpen: true

  closeModal: ->
    @setState isModalOpen: false

  cancelDetailedJudgements: ->
    alt.bootstrap @etdStoreSnapshot
    @closeModal()

  applyDetailedJudgements: ->
    EtdActions.save() unless @props.noAutoSave
    @closeModal()

  changeJudgement: (value) ->
    { sectionId, isConsensus, noAutoSave } = @props
    actionName = if noAutoSave then 'changeJudgement' else 'changeAndSaveJudgement'
    EtdActions[actionName] sectionId, value, isConsensus
    @props.onJudgementChange?(sectionId, value, isConsensus)

  handleJudgementChange: (e) ->
    return if @props.readOnly
    @changeJudgement e.currentTarget.value

  clearOptions: (evt)->
    return unless @props.editable
    evt.persist() # React-related. To access the event properties in an asynchronous way
    props =
      options: [
          text: @i18n 'judgement_clear_factor'
          actionCbk: =>
            @closeDialogBox()
            @clearJudgement()
        ,
          text: @i18n 'judgement_clear_all'
          actionCbk: =>
            @closeDialogBox()
            @clearAllJudgementsAction(evt)
      ]
    @showInDialogBox evt, OptionsList, props, 'soj-options-list-dialog'
    evt.preventDefault()

  clearJudgement: ->
    EtdActions.clearJudgement @props.sectionId, @props.isConsensus
    EtdActions.save() unless @props.noAutoSave

  clearAllJudgementsAction: (evt)->
    props =
      onCancel: @closeDialogBox
      onConfirm: =>
        @closeDialogBox()
        EtdActions.clearAllJudgements @props.isConsensus
        EtdActions.save() unless @props.noAutoSave
      question: @i18n 'confirm_judgement_clear'
    @showInDialogBox evt, Confirmation, props, 'confirmation-modal soj-clear-dialog'

  render: ->
    {
      criterion
      criticalOutcomes
      editable
      isConsensus
      overarchingQuestionData
      parentSectionsData
      qualityIndicators
      questionType
      renderMode
      sectionData
      sectionId
      sectionName
      selectedOption
      templateId
      type
    } = @props

    criterionDescription = criterion.get('description')
    additionalOptions = criterion.get('additionalOptions', Immutable.List())
    details = criterion.get('details')
    options = criterion.get('options', Immutable.List())
    rating = criterion.get('rating', Immutable.Map())
    isRating = sectionData.get('rating', false) or sectionData.get('perIntervention', false)
    useJudgmentTable = sectionData.get('perOutcomes', false) or not
      sectionData.get('parentSections', Immutable.List()).isEmpty()
    isStarRating = templateId in ['overarchingDx-testCrPp', 'overarchingTx-crPp', 'qualityIndicators']

    if isRating and isStarRating
      <RankingJudgement
        criterion={criterion}
        editable={editable}
        qualityIndicators={qualityIndicators}
        overarchingQuestionData={overarchingQuestionData}
        questionType={questionType}
        rating={rating}
        renderMode={renderMode}
        sectionId={sectionId}
        type={type}
      />
    else if isRating
      <RankingJudgementV2
        criterion={criterion}
        editable={editable}
        overarchingQuestionData={overarchingQuestionData}
        questionType={questionType}
        renderMode={renderMode}
        sectionId={sectionId}
      />
    else if useJudgmentTable
      <JudgementTable
        criterion={criterion}
        editable={editable}
        overarchingQuestionData={overarchingQuestionData}
        parentSectionsData={parentSectionsData}
        questionType={questionType}
        renderMode={renderMode}
        sectionId={sectionId}
        templateId={templateId}
      />
    else
      <div onContextMenu={@clearOptions}>
        {if renderMode is 'preview'
          <div className="criteria-description">
            {criterionDescription}
          </div>
        }
        <RadioButtonList
          additionalOptions={additionalOptions}
          editable={editable}
          name={sectionId}
          onChange={@handleJudgementChange}
          options={options}
          sectionId={sectionId}
          sectionName={sectionName}
          selectedOption={selectedOption}
        />
        {if renderMode isnt 'printout'
          <div>
            <Button
              className="btn btn-block detailed-judgements-btn"
              label={if templateId.indexOf('overarching') isnt -1
                @i18n "ranking_judgement.judgment"
              else
                @i18n "detailed_judgements.name"}
              onClick={@openModal}
            />
            <Modal
              className="detailed-judgements"
              isOpen={@state.isModalOpen}
              closeButton={false}
              onClose={@closeModal}
              title={@i18n 'detailed_judgements.name'}
            >
              <DetailedJudgements
                criterionDescription={criterionDescription}
                criticalOutcomes={criticalOutcomes}
                details={details}
                editable={editable}
                finalJudgementAdditionalOptions={additionalOptions}
                finalJudgementOptions={options}
                finalJudgementSelectedOption={selectedOption}
                isConsensus={isConsensus}
                onApply={@applyDetailedJudgements}
                onCancel={@cancelDetailedJudgements}
                onFinalJudgementChange={@changeJudgement}
                questionType={questionType}
                sectionId={sectionId}
                sectionName={sectionName}
              />
            </Modal>
          </div>
        }
      </div>

module.exports = JudgementComponent
