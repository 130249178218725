$(function() {
  var Buffer, Storage, debug, error, initReactI18next, integration, isWebKit, mediator, test, _base, _i, _len, _ref, _ref1, _ref2;
  Storage = require('lib/storage');
  window.gdt.storage = new Storage();
  Buffer = buffer.Buffer;
  window.Buffer = Buffer;
  require('base/lib/when_reporting');
  mediator = require('mediator');
  initReactI18next = ReactI18next.initReactI18next;
  window.gdt.allLanguages = [
    {
      value: 'en',
      text: 'English'
    }, {
      value: 'zh',
      text: '中国 (Chinese)'
    }, {
      value: 'de',
      text: 'Deutsch'
    }, {
      value: 'es',
      text: 'Español'
    }, {
      value: 'it',
      text: 'Italiano'
    }, {
      value: 'ja',
      text: '日本語 (Japanese)'
    }, {
      value: 'nl',
      text: 'Nederlands'
    }, {
      value: 'pt',
      text: 'Português'
    }, {
      value: 'et',
      text: 'Eesti'
    }, {
      value: 'th',
      text: 'ไทย (Thai)'
    }, {
      value: 'fr',
      text: 'Français'
    }, {
      value: 'cs',
      text: 'Český'
    }, {
      value: 'fa',
      text: 'فارسی (Persian)',
      hidden: true
    }, {
      value: 'ar',
      text: 'العربية (Arabic)',
      hidden: true
    }, {
      value: 'zh_CHS',
      text: '汉语 (Chinese simplified)',
      hidden: true
    }, {
      value: 'cs',
      text: 'Čeština',
      hidden: true
    }, {
      value: 'hi',
      text: 'हिन्दी (Hindī)',
      hidden: true
    }, {
      value: 'sk',
      text: 'Slovenčina',
      hidden: true
    }
  ];
  window.gdt.languages = _.reject(window.gdt.allLanguages, function(_arg) {
    var hidden, value;
    value = _arg.value, hidden = _arg.hidden;
    return (_.includes(window.gdt.serverSwitches, 'ecFeatures') && value !== 'en') || hidden === true;
  });
  debug = window.gdt.debugMode;
  integration = window.gdt.integrationMode;
  if (!debug) {
    window.gdt.storage.get('debugMode').then(function(value) {
      return debug = value;
    });
  }
  test = typeof window !== "undefined" && window !== null ? (_ref = window.gdt) != null ? _ref.testMode : void 0 : void 0;
  ReactModal.setAppElement('#gdt-app');
  isWebKit = !!window.webkitURL;
  if (!isWebKit) {
    $('html').addClass('non-webkit');
  }
  window.gdt.versionTimestamp = Number('__!releaseTimestamp!__');
  window.gdt.tosVersion = Number('{!tosVersion!}');
  window.gdt.versionUpdateLink = "https://evidenceprime.com/update/" + window.gdt.versionTimestamp;
  window.document.domain = window.location.hostname;
  window.gdt.popupFinished = function(type, data) {
    return mediator.publish("popupFinished." + type, data);
  };
  if ((_base = window.gdt).POUCHDB_OPTIONS == null) {
    _base.POUCHDB_OPTIONS = {};
  }
  window.gdt.POUCHDB_OPTIONS.size = 20;
  window.gdt.POUCHDB_OPTIONS.revs_limit = 400;
  if (platform.name.toLowerCase() === 'firefox') {
    window.gdt.POUCHDB_OPTIONS.storage = 'persistent';
  }
  if (!(debug || test)) {
    Raven.config('https://5110803decdf40f085b19919186a0ccc@o1423188.ingest.sentry.io/4504485181521920', {
      release: window.gdt.versionTimestamp,
      ignoreErrors: ['max_retries', 'not_connecting', 'Server returned 429, turning heartbeat off', 'Failed to update a ServiceWorker for scope'],
      dataCallback: function(data) {
        _(data.modules != null ? data.modules : data.modules = {}).extend({
          '{!name!}': '{!version!}'
        });
        _(data.extra != null ? data.extra : data.extra = {}).extend({
          build_time: moment(Number(window.gdt.versionTimestamp)).format(),
          location_hash: window.location.hash,
          actions_list: mediator.actionsQueue.asArray().reverse()
        });
        return data;
      }
    }).install();
    _ref2 = typeof window !== "undefined" && window !== null ? (_ref1 = window.gdt) != null ? _ref1.ravenQueue : void 0 : void 0;
    for (_i = 0, _len = _ref2.length; _i < _len; _i++) {
      error = _ref2[_i];
      Raven.send(error);
    }
  }
  return window.gdt.storage.get('gdtLanguage').then(function(lng) {
    var languageIsAvailable, nl;
    languageIsAvailable = _.findWhere(window.gdt.languages, {
      value: lng
    });
    if (!lng || !languageIsAvailable) {
      nl = navigator.language.split('-')[0];
      lng = _.findWhere(window.gdt.languages, {
        value: nl
      }) ? nl : 'en';
      window.gdt.storage.set('gdtLanguage', lng);
    }
    jqueryI18next.init(i18next, $, {
      tName: 't',
      i18nName: 'i18n'
    });
    return i18next.use(i18nextXHRBackend).use(i18nextLocalStorageCache).use(initReactI18next).init({
      debug: debug,
      lng: lng,
      fallbackLng: 'en',
      compatibilityJSON: 'v1',
      returnObjects: true,
      interpolation: {
        prefix: '__',
        suffix: '__',
        escapeValue: false
      },
      backend: {
        loadPath: 'locales/__lng__/__ns__.json'
      },
      ns: ['translation', 'errors', 'es', 'projects', 'team', 'tasks', 'scope', 'docsec', 'dissemination', 'prognosis', 'settings', 'voting', 'references', 'archie', 'mc', 'emails', 'isof', 'over_review', 'gd_checklist', 'rob', 'da', 'dbep', 'quality_indicators', 'overarching_questions', 'countries', 'key_messages', 'mdg_tables', 'mda', 'nma', 'vi', 'algorithms'],
      defaultNS: 'translation'
    }, function(err, t) {
      var initApp;
      if (window.gdt.translationDebug) {
        $.t = function() {
          var key;
          key = arguments[0];
          return key;
        };
      }
      $('.non-webkit.warning').text($.t('partially_tested_browser'));
      $('.old-ie.warning').text($.t('ie_warning'));
      $('.loading-text').html($.t('application_loading'));
      $.ajaxSetup({
        timeout: 30 * 1000,
        cache: false
      });
      require('lib/localized_dates');
      initApp = function() {
        var Application, app;
        Application = require('application');
        app = new Application();
        return app.initialize(debug, integration);
      };
      if (typeof Raven !== "undefined" && Raven !== null) {
        return Raven.context(initApp);
      } else {
        return initApp();
      }
    });
  });
});
