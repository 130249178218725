BatchEditorOptionsModal = require 'components/document_sections/v2/batch_editor_options_modal'
ChaptersList = require 'components/document_sections/v2/chapters_list'
ConnectStore = require 'components/enhancers/connect_store'
DocumentSectionsCommonStore = require 'stores/document_sections_common_store'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DocumentSectionsV2Store = require 'stores/document_sections_v2_store'
EDMSActions = require 'actions/edms_actions'
EDMSStore = require 'stores/edms_store'
EDMSInsertModal = require 'components/common/edms/edms_insert_modal'
mediator = require 'mediator'
Modal = require 'components/common/modal'
QuestionGroupsStore = require 'stores/question_groups_store'
QuestionsStore = require 'stores/questions_store'
ReferencesActions = require 'actions/references_actions'
ReferencesInsertModal = require 'components/etd/references_insert_modal'
ReferencesStore = require 'stores/references_store'
Sidebar = require 'components/document_sections/v2/sidebar'
Spinner = require 'components/common/spinner'
Translation = require 'components/mixins/translation'

DocumentSectionsV2 = createReactClass
  displayName: 'DocumentSectionsV2'
  mixins: [Translation('docsec:management')]
  propTypes:
    batchEditorOptions: PropTypes.instanceOf(Immutable.Map).isRequired
    rootChaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    isEDMSInsertModalOpen: PropTypes.bool.isRequired
    isReferencesInsertModalOpen: PropTypes.bool.isRequired
    initialReferences: PropTypes.instanceOf(Immutable.Map)
    insertReferencesHandler: PropTypes.func.isRequired
    insertEDMSHandler: PropTypes.func.isRequired
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired
    currentDiscussionsFilters: PropTypes.instanceOf(Immutable.Map).isRequired
    isFetchingChapters: PropTypes.bool.isRequired
    isFetchingQuestionGroups: PropTypes.bool.isRequired
    isFetchingQuestions: PropTypes.bool.isRequired
    suggesting: PropTypes.bool.isRequired
    editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
    editingDiscussionReplies: PropTypes.instanceOf(Immutable.Map).isRequired
    activeDiscussionItem: PropTypes.oneOfType [
      PropTypes.string
      PropTypes.arrayOf PropTypes.string
    ]
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number.isRequired
    })

  render: ->
    {
      activeDiscussionItem,
      activeSidebarTab,
      batchEditorOptions
      chapters,
      currentDiscussionsFilters,
      discussions,
      editingDiscussionItems,
      editingDiscussionReplies
      initialReferences,
      insertEDMSHandler,
      insertReferencesHandler
      isEDMSInsertModalOpen,
      isFetchingChapters,
      isFetchingQuestionGroups,
      isFetchingQuestions,
      isReferencesInsertModalOpen,
      questionGroups,
      questions,
      rootChaptersOrder,
      scrollToSectionContent,
      suggesting,
    } = @props

    if isFetchingChapters || isFetchingQuestionGroups || isFetchingQuestions
      <Spinner />
    else
      <div className="document-sections-v2 h-full overflow-hidden">
        <ChaptersList
          chaptersOrder={rootChaptersOrder}
          chapters={chapters}
          questionGroups={questionGroups}
          questions={questions}
          scrollToSectionContent={scrollToSectionContent}
        />
        <Sidebar
          activeDiscussionItem={activeDiscussionItem}
          activeTab={activeSidebarTab}
          chapters={chapters}
          chaptersOrder={rootChaptersOrder}
          discussions={discussions}
          currentDiscussionsFilters={currentDiscussionsFilters}
          editingDiscussionItems={editingDiscussionItems}
          editingDiscussionReplies={editingDiscussionReplies}
          suggesting={suggesting}
        />
        {isReferencesInsertModalOpen and <ReferencesInsertModal
          onClose={ReferencesActions.closeInsertModal}
          onInsert={insertReferencesHandler}
          initialReferences={initialReferences}
        />}
        {isEDMSInsertModalOpen and <EDMSInsertModal
          onClose={EDMSActions.closeImportDialog}
          onInsert={insertEDMSHandler}
        />}
        {batchEditorOptions.get('modalOpen') && <BatchEditorOptionsModal
          batchEditorOptions={batchEditorOptions}
        />}
      </div>

storeConnectors =
  DocumentSectionsV2Store: (Store) ->
    activeDiscussionItem: Store.getActiveDiscussionItem()
    activeSidebarTab: Store.getActiveSidebarTab()
    batchEditorOptions: Store.getBatchEditorOptions()
    chapters: Store.getChapters()
    currentDiscussionsFilters: Store.getDiscussionsFilters()
    discussions: Store.getResultingDiscussions()
    editingDiscussionItems: Store.getEditingDiscussionItems()
    editingDiscussionReplies: Store.getEditingDiscussionReplies()
    isFetchingChapters: Store.isFetching()
    rootChaptersOrder: Store.getRootChaptersOrder()
    scrollToSectionContent: Store.getScrollToSectionContent()
    suggesting: Store.getSuggesting()
  QuestionGroupsStore: (Store) ->
    isFetchingQuestionGroups: Store.isFetching()
    questionGroups: Store.getQuestionGroups()
  QuestionsStore: (Store) ->
    isFetchingQuestions: Store.isFetching()
    questions: Store.getQuestions()
  ReferencesStore: (Store) ->
    isReferencesInsertModalOpen: Store.isInsertModalOpen()
    insertReferencesHandler: Store.getInsertModalInsertHandler()
    initialReferences: Store.getInitialReferencesForInsert()
  EDMSStore: (Store) ->
    isEDMSInsertModalOpen: Store.isInsertModalOpen()
    insertEDMSHandler: Store.getInsertHandler()

module.exports =
  ConnectStore DocumentSectionsV2,
  [
    EDMSStore,
    DocumentSectionsV2Store,
    QuestionGroupsStore,
    QuestionsStore,
    ReferencesStore
  ],
  storeConnectors
