var ReactComponent, TemplatesSettings, TemplatesSettingsActions, TemplatesSettingsView, View, alt,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

alt = require('alt');

View = require('base/views/view');

TemplatesSettings = require('components/administration/templates/templates_settings');

TemplatesSettingsActions = require('actions/templates_settings_actions');

ReactComponent = require('base/lib/traits/react_component');

module.exports = TemplatesSettingsView = (function(_super) {
  __extends(TemplatesSettingsView, _super);

  function TemplatesSettingsView() {
    return TemplatesSettingsView.__super__.constructor.apply(this, arguments);
  }

  TemplatesSettingsView.prototype.container = '#page-container';

  TemplatesSettingsView.prototype.className = 'templates-settings-view';

  TemplatesSettingsView.prototype.autoRender = true;

  TemplatesSettingsView.prototype.initialize = function() {
    this.enable(ReactComponent);
    return TemplatesSettingsView.__super__.initialize.apply(this, arguments);
  };

  TemplatesSettingsView.prototype.afterRender = function() {
    TemplatesSettingsView.__super__.afterRender.apply(this, arguments);
    return this.renderComponent(TemplatesSettings, TemplatesSettingsActions);
  };

  TemplatesSettingsView.prototype.dispose = function() {
    this.unmountComponent();
    alt.recycle('EtdTemplatesStore');
    return TemplatesSettingsView.__super__.dispose.apply(this, arguments);
  };

  return TemplatesSettingsView;

})(View);
