var Code, GeneralInformationDocShape, arrayOf, boolean, calculated, convertToDraftJS, convertToShape, docShape, documentId, extendedCheck, extendedParse, extraChecks, guidelineTagType, initial, mapOf, mediator, number, numberChecks, object, oneOf, optional, percentType, shape, string, _ref, _ref1, _ref2;

Code = require('/lib/db_docs/field_types/code_type');

docShape = require('lib/db_docs/field_types/doc_shape');

shape = require('lib/db_docs/field_types/shape');

arrayOf = require('lib/db_docs/field_types/array_of');

documentId = require('lib/db_docs/field_types/document_id');

oneOf = require('lib/db_docs/field_types/one_of');

mapOf = require('lib/db_docs/field_types/map_of');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, string = _ref.string, number = _ref.number, extraChecks = _ref.extraChecks, object = _ref.object;

_ref1 = require('lib/db_docs/field_types/type_decorators'), initial = _ref1.initial, extendedParse = _ref1.extendedParse, calculated = _ref1.calculated, extendedCheck = _ref1.extendedCheck;

numberChecks = extraChecks.number;

mediator = require('mediator');

_ref2 = require('lib/db_docs/field_types/shape').typeDecorators, optional = _ref2.optional, calculated = _ref2.calculated;

percentType = optional(extendedCheck(numberChecks.min(0))(number));

convertToDraftJS = require('lib/draft_utils').convertToDraftJS;

guidelineTagType = shape({
  id: documentId,
  value: initial('')(string),
  codes: optional(initial({})(mapOf(Code)))
});

convertToShape = function(content) {
  if (_.isString(content)) {
    return {
      showTextInput: true,
      text: convertToDraftJS(content)
    };
  } else {
    return _.extend({}, content, {
      text: convertToDraftJS(content.text || '')
    });
  }
};

GeneralInformationDocShape = docShape({
  guidelineNoId: optional(string),
  guidelineInterventions: optional(arrayOf(guidelineTagType)),
  guidelinePopulations: optional(arrayOf(guidelineTagType)),
  scopeAndPurpose: percentType,
  rigorOfDevelopment: percentType,
  editorialProcess: percentType,
  scorePassed: optional(boolean),
  title: initial('')(string),
  slug: optional(string),
  fullReference: initial('')(string),
  publicationDate: initial('')(string),
  version: optional(string),
  source: initial('')(string),
  whoIrisId: optional(string),
  authors: optional(string),
  keywords: optional(string),
  generalDescription: optional(string),
  organizationType: initial([])(arrayOf(string)),
  sourceDocumentLink: initial('')(string),
  isbn: initial('')(string),
  doi: initial('')(string),
  pmid: initial('')(string),
  guidelineGroupDetails: optional(extendedParse(convertToShape)(shape({
    showTextInput: optional(boolean),
    text: optional(extendedParse(convertToDraftJS)(object))
  }))),
  declarationOfInterest: optional(boolean),
  describedAsRapid: optional(boolean),
  describedAsLiving: optional(boolean),
  includeNonEnglishDatabases: optional(oneOf([true, false, 'notReported'])),
  latestLiteratureSearchDate: initial('')(string),
  gradingEvidenceMethod: optional(string),
  focus: optional(initial([])(arrayOf(string))),
  targetPopulation: optional(initial('')(string)),
  category: optional(initial([])(arrayOf(string))),
  progress: optional(initial([])(arrayOf(string))),
  plus: optional(initial([])(arrayOf(string))),
  progressPlus: optional(initial('')(string)),
  socialCapital: optional(initial([])(arrayOf(string))),
  consumerEngagement: optional(extendedParse(convertToShape)(shape({
    showTextInput: optional(boolean),
    text: optional(extendedParse(convertToDraftJS)(object))
  }))),
  peopleWithLivedExperience: optional(shape({
    showTextInput: optional(boolean),
    text: optional(extendedParse(convertToDraftJS)(object))
  })),
  targetAudience: optional(initial('')(string)),
  field: initial([])(arrayOf(string)),
  worldRegion: initial([])(arrayOf(string)),
  secondWorldRegionCategory: optional(initial([])(arrayOf(string))),
  country: initial([])(arrayOf(string)),
  intent: optional(initial('')(string)),
  subIntent: optional(initial('')(string)),
  collectionId: optional(number),
  gradebookChapter: optional(string),
  oldGradebookLink: optional(string)
});

module.exports = GeneralInformationDocShape;
