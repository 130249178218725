AdolopmentSummaryOfJudgements = require 'components/etd/adolopment_summary_of_judgements'
AssessmentSectionWithPanelVoiceResults =
  require 'components/etd/panel_voice_etd/assessment_section_with_panel_voice_results'
AssessmentCollapseButton = require 'components/etd/panel_voice_etd/assessment_collapse_button'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
EtdAssessmentMixin = require 'components/mixins/etd_assessment_mixin'
EtdSectionTitle = require 'components/etd/etd_section_title'
PrintoutColGroup = require 'components/etd/printout_colgroup'
SummaryOfJudgements = require 'components/etd/summary_of_judgements'
TableViewOptions = require 'components/etd/table_view_options'
TogglePanel = require 'components/common/toggle_panel'
Translation = require 'components/mixins/translation'

{ VOTING_RESULTS_VIEW_TYPES } = require 'lib/etd_helper'
{ instanceOf, bool, string, oneOf, func } = PropTypes

{ CellWithTitle, SectionRow, Card, CardContent } = ReactComponents

AssessmentWithPanelVoiceResults = createReactClass
  displayName: 'AssessmentWithPanelVoiceResults'

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  propTypes:
    activeJudgmentOptionBySection: instanceOf(Immutable.Map).isRequired
    activeResultsViewTypeBySection: instanceOf(Immutable.Map).isRequired
    additionalConsiderations: instanceOf(Immutable.Map)
    additionalConsiderationsVisible: bool.isRequired
    adolopmentData: instanceOf(Immutable.Map)
    adolopments: instanceOf(Immutable.Map)
    assessmentSections: instanceOf(Immutable.OrderedMap)
    attachments: instanceOf(Immutable.Map)
    comparison: string
    conclusionsSections: instanceOf(Immutable.OrderedMap)
    criterions: instanceOf(Immutable.Map)
    criticalOutcomes: instanceOf(Immutable.List)
    editingSectionId: string
    etdHighlights: instanceOf(Immutable.Map)
    etdId: string.isRequired,
    etdTab: string.isRequired
    etdViewSettings: instanceOf(Immutable.Map).isRequired
    getAdminVotingSummary: func.isRequired
    hiddenSojSections: instanceOf(Immutable.Map)
    intervention: string
    keepSojStatus: func
    memberNamesMap: instanceOf(Immutable.Map).isRequired
    outcomes: instanceOf(Immutable.List)
    overarchingQuestionData: instanceOf(Immutable.Map)
    qualityIndicators: instanceOf(Immutable.List)
    questionType: string
    renderMode: oneOf(['regular', 'printout', 'mcSource']).isRequired
    researchEvidences: instanceOf(Immutable.Map)
    showResultingDataOnly: bool
    sojStatus: string.isRequired
    templateId: string.isRequired
    type: oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])
    votedEtdData: instanceOf(Immutable.Map)
    votingResults: instanceOf(Immutable.Map).isRequired
    withSoj: bool

  getDefaultProps: ->
    adolopmentData: Immutable.Map()
    adolopments: Immutable.Map()
    etdHighlights: Immutable.Map()
    etdViewSettings: Immutable.Map()
    overarchingQuestionData: Immutable.Map()
    qualityIndicators: Immutable.List()
    showResultingDataOnly: false
    type: 'regular'
    withSoj: true

  renderAssessmentSections: ->
    {
      activeJudgmentOptionBySection
      activeResultsViewTypeBySection
      activeTab
      additionalConsiderations
      adolopmentData
      adolopments
      additionalConsiderationsVisible
      assessmentSections
      attachments
      collapsedAssessmentSections
      criterions
      criticalOutcomes
      editable
      editingSectionId
      etdHighlights
      etdId
      etdTab
      etdViewSettings
      getAdminVotingSummary
      memberNamesMap
      outcomes
      overarchingQuestionData
      qualityIndicators
      showResultingDataOnly
      questionType
      renderMode
      researchEvidences
      votedEtdData
      votingResults
      templateId
      type
      withSoj
    } = @props

    # maps sectionData to section component
    assessmentSections.mapEntries ([sectionId, sectionData], index) =>
      parentSectionsData = sectionData.get('parentSections', Immutable.List()).map (parentId) ->
        assessmentSections.get(parentId, Immutable.Map()).merge criterions.get(parentId)

      Section = <AssessmentSectionWithPanelVoiceResults
          activeJudgmentOptionBySection={activeJudgmentOptionBySection}
          activeResultsViewTypeBySection={activeResultsViewTypeBySection}
          activeTab={activeTab}
          additionalConsiderations={
            additionalConsiderations.get(sectionData.get('additionalConsiderationId'))}
          additionalConsiderationsVisible={additionalConsiderationsVisible}
          adolopmentData={adolopmentData.get(sectionId, Immutable.Map())}
          adolopments={adolopments}
          attachments={attachments}
          collapsedAssessmentSections={collapsedAssessmentSections}
          criterion={criterions.get(sectionData.get('criterionId'))}
          criticalOutcomes={criticalOutcomes}
          editable={if editable? then editable else @_inEditMode?()}
          editingSectionId={editingSectionId}
          etdHighlights={etdHighlights}
          etdId={etdId}
          etdTab={etdTab}
          etdViewSettings={etdViewSettings}
          getAdminVotingSummary={getAdminVotingSummary}
          index={index}
          key={sectionId}
          memberNamesMap={memberNamesMap}
          outcomes={outcomes}
          overarchingQuestionData={overarchingQuestionData}
          parentSectionsData={parentSectionsData}
          qualityIndicators={qualityIndicators}
          questionType={questionType}
          renderMode={renderMode}
          researchEvidence={researchEvidences.get(sectionData.get('researchEvidenceId'))}
          sectionData={sectionData}
          sectionId={sectionId}
          showResultingDataOnly={showResultingDataOnly}
          templateId={templateId}
          type={type}
          votedEtdData={votedEtdData}
          votingResults={votingResults}
          withSoj={withSoj}
        />

      [sectionId, Section]
    .toList()

  render: ->
    votingOngoing = @props.votingResults.get('status') not in ['closed', 'test']
    <div className="assessment-section">
      <EtdSectionTitle renderMode={@props.renderMode} title={@i18n 'assessment'} />
      {@props.renderMode isnt 'printout' and
        <div className="assessment-toolbar">
          <TableViewOptions
            adolopmentOptions={not @props.adolopments.isEmpty()}
            assessmentSections={@props.assessmentSections}
            conclusionsSections={@props.conclusionsSections}
            etdId={@props.etdId}
            etdViewSettings={@props.etdViewSettings}
          />
          <AssessmentCollapseButton
            assessmentSections={@props.assessmentSections}
            collapsedAssessmentSections={@props.collapsedAssessmentSections}
            etdId={@props.etdId}
          />
        </div>
      }
      <table className='standard-table judgement-table'>
        <PrintoutColGroup
          renderMode={@props.renderMode}
          withAdditionalConsiderations={@props.additionalConsiderationsVisible}
        />
        {@renderAssessmentSections()}
      </table>
      <br />
      {@props.withSoj and @props.adolopments.isEmpty() and
        <SummaryOfJudgements
          comparison={@props.comparison}
          criterions={@props.criterions}
          editable={true}
          etdId={@props.etdId}
          hiddenSections={@props.hiddenSojSections}
          intervention={@props.intervention}
          isConsensus={false}
          qualityIndicators={@props.qualityIndicators}
          overarchingQuestionData={@props.overarchingQuestionData}
          renderMode={@props.renderMode}
          sections={@props.assessmentSections}
          showResultingDataOnly={@props.showResultingDataOnly}
          templateId={@props.templateId}
          type={@props.type}
        />
      }
      {@props.withSoj and not @props.adolopments.isEmpty() and
        <AdolopmentSummaryOfJudgements
          adolopments={@props.adolopments}
          adolopmentData={@props.adolopmentData}
          criterions={@props.criterions}
          editable={true}
          etdId={@props.etdId}
          etdViewSettings={@props.etdViewSettings}
          hiddenSections={@props.hiddenSojSections}
          i18n={@i18n}
          isConsensus={false}
          renderMode={@props.renderMode}
          sections={@props.assessmentSections}
          showResultingDataOnly={@props.showResultingDataOnly}
          showOriginalJudgement={true}
        />
      }
    </div>

module.exports = AssessmentWithPanelVoiceResults
