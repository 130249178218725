var EtdTemplatesActions, W, alt, etdTemplateHelper, getEtdTemplateDocId, getEtdTemplateType, mediator, templateLanguages;

alt = require('alt');

mediator = require('mediator');

etdTemplateHelper = require('stores/utils/etd_template_helper');

templateLanguages = require('lib/etd_templates/templates_languages');

getEtdTemplateDocId = etdTemplateHelper.getEtdTemplateDocId, getEtdTemplateType = etdTemplateHelper.getEtdTemplateType;

W = require('when');

EtdTemplatesActions = (function() {
  function EtdTemplatesActions() {
    this.generateActions('cancelEdit', 'fetch', 'pouchFetchError', 'pouchSaveError', 'revertChanges', 'updateSection');
  }

  EtdTemplatesActions.prototype.pouchFetchSuccess = function(results) {
    var getTemplateDoc;
    getTemplateDoc = function(results, templateType) {
      var template;
      template = _(results.rows).findWhere({
        key: getEtdTemplateDocId(templateType)
      });
      if (!template || template.error || template.value.deleted) {
        return null;
      } else {
        return template.doc;
      }
    };
    return this.dispatch({
      txTemplate: getTemplateDoc(results, 'tx'),
      dxTemplate: getTemplateDoc(results, 'dx'),
      overarchingTxTemplate: getTemplateDoc(results, 'overarchingTx'),
      overarchingDxTemplate: getTemplateDoc(results, 'overarchingDx'),
      keyMessageTemplate: getTemplateDoc(results, 'keyMessage'),
      goodPracticeStatementTemplate: getTemplateDoc(results, 'goodPracticeStatement')
    });
  };

  EtdTemplatesActions.prototype.pouchSaveSuccess = function(doc) {
    return this.dispatch({
      templateDoc: doc,
      templateType: getEtdTemplateType(doc)
    });
  };

  EtdTemplatesActions.prototype.prepareTemplate = function(templateOrTemplateId, language) {
    if (_.isString(templateOrTemplateId)) {
      return this.dispatch(etdTemplateHelper.prepareBaseTemplate(templateOrTemplateId, language));
    } else {
      return etdTemplateHelper.prepareTemplate(templateOrTemplateId.get('templateDef'), templateOrTemplateId.get('templateData'), true).withMutations(function(template) {
        template.set('_id', templateOrTemplateId.get('_id'));
        return template.set('_rev', templateOrTemplateId.get('_rev'));
      });
    }
  };

  EtdTemplatesActions.prototype.toggleSection = function(blockName, sectionId) {
    return this.dispatch({
      sectionId: sectionId,
      blockName: blockName
    });
  };

  EtdTemplatesActions.prototype.togglePresentationSection = function(presentationGroup, sectionId) {
    return this.dispatch({
      presentationGroup: presentationGroup,
      sectionId: sectionId
    });
  };

  EtdTemplatesActions.prototype.updateName = function(name) {
    return this.dispatch({
      name: name
    });
  };

  EtdTemplatesActions.prototype.useTemplate = function(templateDef, templateData) {
    var projectDbName, template;
    projectDbName = mediator.project.id;
    template = etdTemplateHelper.prepareTemplate(templateDef, templateData);
    template = template.set('_id', getEtdTemplateDocId(getEtdTemplateType(template)));
    return this.actions.save(projectDbName, template);
  };

  EtdTemplatesActions.prototype.editTemplate = function(templateType) {
    return this.dispatch({
      templateType: templateType
    });
  };

  EtdTemplatesActions.prototype.saveTemplate = function(template, templateType) {
    var projectDbName, templateData, templateDef;
    projectDbName = mediator.project.id;
    templateDef = template.get('templateDef');
    templateData = template.get('templateData');
    return mediator.services.storePersistenceAdapter.fetch(projectDbName, getEtdTemplateDocId(templateType)).then((function(_this) {
      return function(doc) {
        template = etdTemplateHelper.prepareTemplate(templateDef, templateData).withMutations(function(template) {
          template.set('_id', doc._id);
          return template.set('_rev', doc._rev);
        });
        return _this.actions.save(projectDbName, template);
      };
    })(this));
  };

  EtdTemplatesActions.prototype.refreshTemplate = function(templateId, templateType, language) {
    var template;
    if (language == null) {
      language = templateLanguages.defaultLanguage;
    }
    template = etdTemplateHelper.prepareBaseTemplate(templateId, language);
    return this.actions.saveTemplate(template, templateType);
  };

  EtdTemplatesActions.prototype.save = function(projectDbName, template) {
    etdTemplateHelper.updateTemplatesInQuestions(projectDbName, template, getEtdTemplateType(template));
    return this.dispatch({
      projectDbName: projectDbName,
      template: template.toJS()
    });
  };

  return EtdTemplatesActions;

})();

module.exports = alt.createActions(EtdTemplatesActions);
