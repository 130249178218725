var DiagnosticPrevalencesView, Etd, FootnotesService, HtmlExport, OutcomesView, PanelVoiceEtd, PooledSensitivitySpecificityView, QuestionView, ReferencesSummary, W, createPrintoutContainer, etdHelper, exportToFile, mediator, printoutsMap, removeHTMLNode, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

mediator = require('mediator');

printoutsMap = require('lib/printouts_map');

OutcomesView = require('views/evidence_syntheses/outcomes_view');

QuestionView = require('views/evidence_syntheses/question_view');

PooledSensitivitySpecificityView = require('views/evidence_syntheses/pooled_sensitivity_specificity_view');

DiagnosticPrevalencesView = require('views/evidence_syntheses/diagnostic_prevalences_view');

exportToFile = require('lib/export_to_file').exportToFile;

FootnotesService = require('lib/services/footnotes_service');

Etd = require('components/etd/etd');

PanelVoiceEtd = require('components/etd/panel_voice_etd/panel_voice_etd');

etdHelper = require('lib/etd_helper');

ReferencesSummary = require('components/references/references_summary');

W = require('when');

_ref = require('base/lib/utils'), removeHTMLNode = _ref.removeHTMLNode, createPrintoutContainer = _ref.createPrintoutContainer;

module.exports = HtmlExport = (function() {
  HtmlExport.sofTypesMap = {
    'sof_v1': 'sof',
    'sof_v2': 'SOF_ACCP',
    'sof_v3': 'SOF_v3',
    'sof_v4': 'SOF_v4',
    'l1_sof': 'layerOneSof',
    'l2_sof': 'layerTwoSof'
  };

  function HtmlExport(model, printoutType, options) {
    if (options == null) {
      options = {};
    }
    this.model = model;
    this.printoutType = printoutType;
    this.printout = this.getPrintout(printoutType);
    this.outcomesOrAppendicesIds = options.outcomesOrAppendicesIds;
    this.options = options;
  }

  HtmlExport.prototype.getPrintout = function(type) {
    return printoutsMap[type];
  };

  HtmlExport.prototype.exportRecommendations = function(doc, questionId) {
    var printoutEl, _ref1, _ref2;
    if (this.printoutType === 'recommendations-v2') {
      return this._getETDTableHTML(questionId).then((function(_this) {
        return function(printoutEl) {
          var firstDiv, resultingElement, _ref1, _ref2, _ref3;
          if (((_ref1 = _this.options) != null ? _ref1.word : void 0) || ((_ref2 = _this.options) != null ? _ref2.outputFormat : void 0) === 'doc') {
            _this._replaceSVGImages(printoutEl);
            $(printoutEl).find('div[data-block=true]').replaceWith(function(idx, elText) {
              return "<p>" + elText + "</p>";
            });
            $(printoutEl).find('.gdt-editor-container img').each(function(idx, el) {
              var aspectRatio;
              aspectRatio = el.width / el.height;
              el.setAttribute('style', '');
              el.setAttribute('width', '500');
              return el.setAttribute('height', (500 / aspectRatio).toFixed(0));
            });
            $(printoutEl).find('hr').each(function(idx, el) {
              return removeHTMLNode(el);
            });
          }
          $(printoutEl).find('[contenteditable=true]').each(function(idx, el) {
            return el.setAttribute('contenteditable', false);
          });
          $(printoutEl).find('input[type=radio]:checked').replaceWith(function() {
            return '<span class="checked-marker">&#x25cf;</span>&nbsp;';
          });
          $(printoutEl).find('input[type=radio]:not(:checked)').replaceWith(function() {
            return '<span class="unchecked-marker">&#x25cb;</span>&nbsp;';
          });
          $(printoutEl).find(".gdt-rating-element--active").replaceWith(function() {
            return '<span class="gdt-rating-element--active">&#9733;</span>';
          });
          $(printoutEl).find('.ep-select-with-checkboxes__trigger').each(function(_idx, el) {
            return removeHTMLNode(el);
          });
          $(printoutEl).find('td[style="display: none;"],th[style="display: none;"]').remove();
          firstDiv = $(printoutEl).find('div').first();
          if (firstDiv != null) {
            firstDiv.addClass(((_ref3 = _this.options) != null ? _ref3.outputFormat : void 0) || 'html');
          }
          resultingElement = printoutEl.cloneNode(true);
          ReactDOM.unmountComponentAtNode(printoutEl);
          removeHTMLNode(printoutEl);
          printoutEl = null;
          etdHelper.restoreSavedState();
          resultingElement = _this.appendReferencesSummary(resultingElement);
          return _this.createAppendices(resultingElement);
        };
      })(this));
    } else {
      printoutEl = (_ref1 = this.options.printoutEl) != null ? _ref1 : $(doc).find('#recommendations')[0];
      printoutEl = printoutEl.cloneNode(true);
      if ((_ref2 = this.options) != null ? _ref2.word : void 0) {
        this._replaceSVGImages(printoutEl);
      }
      _($(printoutEl).find('button')).forEach(function(el) {
        return removeHTMLNode(el);
      });
      _($(printoutEl).find('.editable-content')).forEach(function(el) {
        return removeHTMLNode(el);
      });
      _($(printoutEl).find('img')).forEach(function(el) {
        return el.removeAttribute('style');
      });
      _($(printoutEl).find('input[type=text]')).forEach(function(el) {
        return $(el).replaceWith(function() {
          return "<span>" + ($(this).val()) + "</span>";
        });
      });
      _($(printoutEl).find('.standard-table tbody tr')).forEach(function(rowEl) {
        if (rowEl.querySelector('.inserted')) {
          return rowEl.classList.add('page-break');
        }
      });
      $(printoutEl).find('input[type=radio]:checked').replaceWith(function() {
        return '<span class="checked-marker">&#x25cf;</span>';
      });
      $(printoutEl).find('input[type=radio]:not(:checked)').replaceWith(function() {
        return '<span class="unchecked-marker">&#x25cb;</span>';
      });
      return W.resolve(this.createAppendices(printoutEl));
    }
  };

  HtmlExport.prototype.appendReferencesSummary = function(printoutEl) {
    var div, references;
    references = etdHelper.getReferences();
    if (references == null) {
      return printoutEl;
    }
    references.forEach(function(ref, idx) {
      var refId;
      refId = ref.get('_id');
      return _.forEach(printoutEl.querySelectorAll("[data-refid=\"" + refId + "\"]"), function(node) {
        return node.innerText = idx + 1;
      });
    });
    div = document.createElement('div');
    div.classList.add('references-summary');
    printoutEl.appendChild(div);
    ReactDOM.render(React.createElement(ReferencesSummary, {
      references: references
    }), printoutEl.querySelector('.references-summary'));
    return printoutEl;
  };

  HtmlExport.prototype.createAppendices = function(printoutEl) {
    var appendices;
    if (_(this.outcomesOrAppendicesIds).isEmpty()) {
      return printoutEl;
    }
    appendices = [];
    _(this.outcomesOrAppendicesIds).forEach(function(appendixId) {
      var appendixIndex, contentElement, contentElements, imgElement, _i, _len, _results;
      appendixIndex = appendices.length + 1;
      if (appendixId.match('#')) {
        imgElement = $(printoutEl).find("img[db-image=\"" + appendixId + "\"]")[0];
        if (!imgElement) {
          return;
        }
        imgElement.removeAttribute('style');
        appendices.push({
          index: appendixIndex,
          content: imgElement.outerHTML
        });
        return imgElement.parentNode.innerHTML = "<i>See Appendix " + appendixIndex + "</i>";
      } else {
        contentElements = _.union(_.toArray($(printoutEl).find("div[appendix-type=\"" + appendixId + "\"]")), _.toArray($(printoutEl).find("div[data-content-type=\"" + appendixId + "\"]")));
        if (_.isEmpty(contentElements)) {
          return;
        }
        appendices.push({
          index: appendixIndex,
          content: contentElements[0].innerHTML
        });
        _results = [];
        for (_i = 0, _len = contentElements.length; _i < _len; _i++) {
          contentElement = contentElements[_i];
          _results.push(contentElement.innerHTML = "<i>See Appendix " + appendixIndex + "</i>");
        }
        return _results;
      }
    });
    if (_(appendices).isEmpty()) {
      return printoutEl;
    }
    printoutEl.appendChild(this._generateAppendicesDIV(appendices));
    return printoutEl;
  };

  HtmlExport.prototype._replaceSVGImages = function(doc) {
    var canvas, svgImages;
    if (!/data:image\/svg/.test(doc.innerHTML)) {
      return;
    }
    svgImages = doc.querySelectorAll("img[src^='data:image/svg']");
    canvas = document.createElement('canvas');
    _(svgImages).forEach((function(_this) {
      return function(svgImage) {
        var PNGDataURL, domImg;
        domImg = document.querySelector("img[db-image='" + (svgImage.getAttribute('db-image')) + "']");
        PNGDataURL = _this._convertSVGtoPNG(domImg, canvas);
        return $(svgImage).attr({
          'src': PNGDataURL
        });
      };
    })(this));
    return canvas = null;
  };

  HtmlExport.prototype._convertSVGtoPNG = function(svgImage, canvas) {
    var ctx, _ref1;
    ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    _ref1 = [svgImage.width, svgImage.height], canvas.width = _ref1[0], canvas.height = _ref1[1];
    ctx.drawImage(svgImage, 0, 0);
    return canvas.toDataURL();
  };

  HtmlExport.prototype._generateAppendicesDIV = function(appendices) {
    var appendicesDiv, appendicesHTML;
    appendicesHTML = Handlebars.partials.recommendationsAppendices({
      appendices: appendices
    });
    appendicesDiv = document.createElement('div');
    appendicesDiv.innerHTML = appendicesHTML;
    return appendicesDiv;
  };

  HtmlExport.prototype.removeEllipsisWrappers = function($printoutEl) {
    return $printoutEl.find('div.ellipsis-wrapper > *').unwrap();
  };

  HtmlExport.prototype.exportOutcomes = function() {
    var $printoutEl, outcomesPrintoutView, _ref1;
    outcomesPrintoutView = new OutcomesView(this.getOutcomesObject(this.printout.viewType));
    outcomesPrintoutView.unsubscribeAllEvents();
    outcomesPrintoutView.off();
    outcomesPrintoutView.listSelector = this.printout.listSelector;
    if ((_ref1 = this.outcomesOrAppendicesIds) != null ? _ref1.length : void 0) {
      outcomesPrintoutView.filterer = (function(_this) {
        return function(outcome) {
          var _ref2, _ref3;
          return (_ref2 = outcome.cid, __indexOf.call(_this.outcomesOrAppendicesIds, _ref2) >= 0) || (_ref3 = outcome.get('_id'), __indexOf.call(_this.outcomesOrAppendicesIds, _ref3) >= 0);
        };
      })(this);
    }
    $printoutEl = outcomesPrintoutView.render().$el;
    _.defer(function() {
      return outcomesPrintoutView.dispose();
    });
    this.removeEllipsisWrappers($printoutEl);
    return $printoutEl[0];
  };

  HtmlExport.prototype.exportQuestion = function() {
    var $printoutEl, questionPrintoutView;
    questionPrintoutView = new QuestionView({
      model: this.model,
      isPrintout: true,
      viewTypeName: this.printout.viewType.name,
      tableTitle: this.model.get('tableTitle')
    });
    $printoutEl = questionPrintoutView.render().$el;
    return $printoutEl.find('div.content');
  };

  HtmlExport.prototype.exportPrevalence = function() {
    var $printoutEl, diagnosticPrevalencePrintoutView;
    diagnosticPrevalencePrintoutView = new DiagnosticPrevalencesView({
      model: this.model
    });
    return $printoutEl = diagnosticPrevalencePrintoutView.render().$el;
  };

  HtmlExport.prototype.exportPooledValues = function() {
    var $printoutEl, pooledSensitivitySpecificityView;
    pooledSensitivitySpecificityView = new PooledSensitivitySpecificityView({
      model: this.model,
      outcomesView: new OutcomesView(this.getOutcomesObject(this.printout.viewType))
    });
    $printoutEl = pooledSensitivitySpecificityView.render().$el;
    return $printoutEl[0];
  };

  HtmlExport.prototype.convertTooltipsToText = function(doc, targetSelector) {
    var documentClone, elem, replaceText, targetElement, _i, _len, _ref1;
    documentClone = doc.cloneNode(true);
    targetElement = $(documentClone).find(targetSelector);
    replaceText = function(element) {
      return element.textContent = element.getAttribute('data-title');
    };
    _ref1 = $(targetElement).find('[data-title]');
    for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
      elem = _ref1[_i];
      replaceText(elem);
    }
    return documentClone;
  };

  HtmlExport.prototype._getETDTableHTML = function(questionId, options) {
    if (options == null) {
      options = this.options;
    }
    return etdHelper.setRecommendationsView(this.options.etdViewMode || 'ALL-draftJudgement').then((function(_this) {
      return function() {
        var Component, etdVersion, recommendationsComponent, renderOptions, showVotingResults, tempContainer, votingStarted;
        etdVersion = options.etdVersion, renderOptions = options.renderOptions, votingStarted = options.votingStarted, showVotingResults = options.showVotingResults;
        Component = etdVersion && etdVersion === 'v2' && votingStarted ? PanelVoiceEtd : Etd;
        recommendationsComponent = React.createElement(Component, {
          renderMode: 'printout',
          questionId: questionId,
          renderOptions: renderOptions,
          showVotingResults: showVotingResults != null ? showVotingResults : true,
          type: options.type || 'regular',
          showResultingDataOnly: options.showResultingDataOnly
        });
        tempContainer = createPrintoutContainer();
        ReactDOM.render(recommendationsComponent, tempContainer);
        return tempContainer;
      };
    })(this));
  };

  HtmlExport.prototype._getPrintoutEl = function() {
    var doc, printoutEl, _ref1;
    if (/recommendations/.test(this.printoutType)) {
      if (this.options.printoutEl != null) {
        return this.exportRecommendations(document, this.model.get('_id'));
      } else {
        doc = this.convertTooltipsToText(document, '.summary-of-judgements');
        return this.exportRecommendations(doc, this.model.get('_id'));
      }
    } else {
      printoutEl = this.exportOutcomes();
      _($(printoutEl).find('button')).forEach(function(el) {
        return removeHTMLNode(el);
      });
      if ((_ref1 = this.printoutType) === 'layerOne' || _ref1 === 'layerTwo') {
        $(printoutEl).find('.pooled-values-container').append(this.exportPooledValues());
        $(printoutEl).find('.prevalences-container').append(this.exportPrevalence());
      } else {
        $(printoutEl).find('.question-data').append(this.exportQuestion());
      }
      return W.resolve(printoutEl);
    }
  };

  HtmlExport.prototype.getSource = function(orientation, margins, viewName) {
    if (orientation == null) {
      orientation = 'portrait';
    }
    if (margins == null) {
      margins = {};
    }
    this.model.store();
    return this._getPrintoutEl().then((function(_this) {
      return function(printoutEl) {
        var fs, notes, outcomes, printoutHTML, printoutTemplate, references, spliceIdx, _ref1;
        fs = new FootnotesService({
          model: _this.model,
          questionFootnotesAttr: _this.printout.viewType.questionFootnotesAttr
        });
        outcomes = ((_ref1 = _this.outcomesOrAppendicesIds) != null ? _ref1.length : void 0) ? _this.model.get('outcomes').filter(function(o) {
          var _ref2, _ref3;
          return (_ref2 = o.cid, __indexOf.call(_this.outcomesOrAppendicesIds, _ref2) >= 0) || (_ref3 = o.get('_id'), __indexOf.call(_this.outcomesOrAppendicesIds, _ref3) >= 0);
        }) : _this.model.get('outcomes').models;
        notes = fs.getOutcomesTableItemsObjects('footnotes', outcomes, $(printoutEl), viewName);
        references = fs.getOutcomesTableItemsObjects('references', outcomes, $(printoutEl), viewName);
        printoutTemplate = require(_this.printout.template);
        printoutHTML = printoutTemplate(_.extend(_this._getPrintoutData(orientation, margins), {
          notes: notes,
          references: references
        }));
        _.defer(function() {
          return _this.model.restore();
        });
        spliceIdx = printoutHTML.indexOf(_this.printout.spliceTag) + _this.printout.spliceTag.length;
        return _this.minify([printoutHTML.slice(0, spliceIdx), printoutEl.innerHTML, printoutHTML.slice(spliceIdx)].join(''));
      };
    })(this));
  };

  HtmlExport.prototype.minify = function(htmlString) {
    return htmlString.replace(/\n\s+|\n/g, "");
  };

  HtmlExport.prototype["export"] = function(orientation, margins) {
    return this.getSource(orientation, margins).then(function(content) {
      var fileName;
      fileName = "" + (this.model.get('question').substring(0, 100));
      return exportToFile('html', content, {
        fileName: fileName
      });
    }).then((function(_this) {
      return function() {
        return mediator.dialogs.success({
          message: $.t('projects:export_dialog.table_export_successful', {
            'tablename': _this.printout.name
          }),
          ms: 10000,
          closeBtn: true
        });
      };
    })(this))["catch"](function(reason) {
      if (reason !== 'cancelled') {
        return mediator.dialogs.error(reason);
      }
    });
  };

  HtmlExport.prototype.getOutcomesObject = function(viewType) {
    var _ref1;
    return {
      viewType: viewType,
      questionModel: this.model,
      short: (_ref1 = this.options) != null ? _ref1.short : void 0,
      conjunctions: this._getConjunctionsText(this.model)
    };
  };

  HtmlExport.prototype._getConjunctionsText = function(questionModel) {
    var CONJUNCTIONS, conjunctionsAttrs;
    CONJUNCTIONS = {
      CI: $.t('es:outcome.printout.ci_only'),
      HR: $.t('es:outcome.printout.hr_only'),
      MD: $.t('es:outcome.printout.md_only'),
      OR: $.t('es:outcome.printout.or_only'),
      RR: $.t('es:outcome.printout.rr_only'),
      SMD: $.t('es:outcome.printout.smd_only')
    };
    conjunctionsAttrs = ['effectMeasure'];
    return _.chain(questionModel.get('outcomes').models).reduce(function(result, outcome) {
      return result.concat(conjunctionsAttrs.map(function(attr) {
        return outcome.get(attr);
      }));
    }, ['CI']).map(function(conj) {
      var _ref1;
      if (mediator.services.switches.isServerSwitchOn('cadthFeatures')) {
        return (_ref1 = CONJUNCTIONS[conj]) != null ? _ref1.replace(':', "=") : void 0;
      } else {
        return CONJUNCTIONS[conj];
      }
    }).compact().sortBy().uniq().value().join('; ');
  };

  HtmlExport.prototype._getPrintoutData = function(orientation, margins) {
    var _ref1;
    return {
      diagnostic: this.model.isDiagnostic(),
      short: (_ref1 = this.options) != null ? _ref1.short : void 0,
      question: this.model.get('question'),
      orientation: orientation,
      margins: margins,
      activeWorkspace: mediator.activeWorkspace
    };
  };

  return HtmlExport;

})();
