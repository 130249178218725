var W, getHyperlinkPluginOptions, getSchema, maybeResolveSectionsConflicts, mdgHyperlinkPluginOptions, mediator, prepareAnnotation, prepareState, searchInCommentTextOrAuthor,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; },
  __slice = [].slice;

mdgHyperlinkPluginOptions = require('lib/mdg_helper').mdgHyperlinkPluginOptions;

mediator = require('mediator');

getSchema = Epiditor.getSchema, prepareState = Epiditor.prepareState;

W = require('when');

maybeResolveSectionsConflicts = function(projectId) {
  return function(sections) {
    return W.map(sections, function(section) {
      var conflictDocs, conflicts;
      conflicts = section != null ? section['_conflicts'] : void 0;
      if (_.isEmpty(conflicts)) {
        return section;
      } else {
        conflictDocs = conflicts.map(function(conflictRef) {
          return {
            id: section._id,
            rev: conflictRef
          };
        });
        return mediator.services.storePersistenceAdapter.bulkGet(projectId, {
          docs: conflictDocs
        }).then(function(response) {
          var conflictingSectionDocs;
          conflictingSectionDocs = R.chain(function(result) {
            return result.docs.reduce(function(acc, docResult) {
              if ('ok' in docResult) {
                acc.push(docResult.ok);
              }
              return acc;
            }, []);
          }, response.results);
          return conflictingSectionDocs;
        }).then(function(conflictingSectionDocs) {
          var currentContent, docsToSave, updatedSection;
          currentContent = section.content;
          updatedSection = null;
          docsToSave = [];
          conflictingSectionDocs.forEach(function(conflictingDoc) {
            var annotationsToMerge, conflictingDocContent, mergedAnnotations;
            conflictingDocContent = conflictingDoc.content;
            if (_.isEqual(currentContent.doc, conflictingDocContent.doc) && !_.isEqual(currentContent.annotations, conflictingDocContent.annotations)) {
              annotationsToMerge = R.pickBy(function(_v, id) {
                return !(id in currentContent.annotations);
              }, conflictingDocContent.annotations);
              if (!R.isEmpty(annotationsToMerge)) {
                docsToSave.push(R.assoc('_deleted', true, conflictingDoc));
                mergedAnnotations = R.mergeAll([annotationsToMerge, currentContent.annotations]);
                return currentContent = R.assoc('annotations', mergedAnnotations, currentContent);
              }
            }
          });
          if (currentContent !== section.content) {
            updatedSection = R.assoc('content', currentContent, section);
            docsToSave.push(updatedSection);
          }
          if (R.isEmpty(docsToSave)) {
            return section;
          } else {
            return mediator.services.storePersistenceAdapter.bulkDocs(projectId, docsToSave).then(function() {
              return updatedSection != null ? updatedSection : section;
            });
          }
        })["catch"](function(err) {
          console.log(err);
          return section;
        });
      }
    });
  };
};

getHyperlinkPluginOptions = function(forExport) {
  if (forExport == null) {
    forExport = false;
  }
  if (mediator.services.switches.isServerSwitchOn('mdgFeatures')) {
    if (forExport) {
      return _.pick(mdgHyperlinkPluginOptions, 'targetTransformer');
    } else {
      return mdgHyperlinkPluginOptions;
    }
  } else {
    return {};
  }
};

prepareAnnotation = function(annotation, annotations, discussionsFiltersStatus, discussionsFilterVisibleFor, draftDiscussions, sectionId, mdaTableId, visibleFor) {
  var annotationContent, authorId, contentState, editorSchema, id, isDraft, isResolved, plainTextValue;
  if (visibleFor == null) {
    visibleFor = null;
  }
  if (!annotation) {
    return;
  }
  editorSchema = getSchema();
  annotationContent = Immutable.Map.isMap(annotation.getIn(['data', 'text'])) ? annotation.getIn(['data', 'text']).toJS() : '';
  contentState = prepareState(editorSchema, [], annotationContent);
  id = annotation.get('id');
  isResolved = annotation.get('resolved');
  authorId = annotation.getIn(['author', 'id']);
  if (discussionsFiltersStatus !== 'all' && isResolved !== (discussionsFiltersStatus === 'resolved')) {
    return;
  }
  visibleFor = visibleFor || annotation.get('visibleFor', Immutable.List()).toJS();
  if (discussionsFilterVisibleFor && __indexOf.call(visibleFor, discussionsFilterVisibleFor) < 0) {
    return;
  }
  isDraft = draftDiscussions.hasIn([sectionId, 'annotations', id]);
  if (mediator.user.get('_id') === authorId || _.isEmpty(visibleFor) || _.intersection(mediator.user.getAccessRights(), __slice.call(visibleFor).concat(['admin'])).length > 0) {
    plainTextValue = contentState.doc.textContent.toLowerCase();
    return Immutable.Map({
      id: id,
      isDraft: isDraft,
      sectionId: sectionId,
      mdaTableId: mdaTableId,
      type: 'comments',
      plainTextValue: plainTextValue,
      data: annotation.set('replies', annotation.get('replies', Immutable.List()).map(function(replyId) {
        return prepareAnnotation(annotations.get(replyId), annotations, discussionsFiltersStatus, discussionsFilterVisibleFor, draftDiscussions, sectionId, mdaTableId, visibleFor);
      }).filter(function(annotation) {
        return annotation;
      }))
    });
  }
};

searchInCommentTextOrAuthor = function(searchString, currentFilters) {
  return function(comment) {
    var authorPart, inReplies, stringPart;
    authorPart = currentFilters.get('author') != null ? comment.getIn(['data', 'author', 'id']) === currentFilters.get('author') : true;
    searchString = searchString.toLowerCase();
    stringPart = !_.isEmpty(searchString) ? comment.get('plainTextValue', '').indexOf(searchString) !== -1 || comment.getIn(['data', 'author', 'name']).toLowerCase().indexOf(searchString) !== -1 : true;
    inReplies = comment.getIn(['data', 'replies'], Immutable.List()).isEmpty() ? true : comment.getIn(['data', 'replies'], Immutable.List()).filter(searchInCommentTextOrAuthor(searchString, currentFilters));
    return authorPart && stringPart || inReplies.size > 0;
  };
};

module.exports = {
  getHyperlinkPluginOptions: getHyperlinkPluginOptions,
  maybeResolveSectionsConflicts: maybeResolveSectionsConflicts,
  prepareAnnotation: prepareAnnotation,
  searchInCommentTextOrAuthor: searchInCommentTextOrAuthor,
  getSettingsOrder: function(parentType, view) {
    var settingsOrder;
    if (view == null) {
      view = 'document-sections';
    }
    settingsOrder = (function() {
      switch (parentType) {
        case 'chapter-header':
          switch (view) {
            case 'visual-guidelines':
              return ['add_new_text_field'];
            default:
              return ['insert_mda_table', 'insert_recommendation', 'add_new_text_field', 'mark_for_panel_review', 'insert_subchapter', mediator.services.switches.isServerSwitchOn('evolentFeatures') && 'insert_references', 'clear_highlights', 'accept_all_changes'];
          }
          break;
        case 'mda-table-section':
          return ['delete', 'insert_mda_table', 'add_code', 'add_new_text_field'];
        case 'text-section':
        case 'acp_visual_guideline_general_information_form':
          return ['duplicate', 'delete', 'insert_mda_table', 'insert_recommendation', 'insert_subchapter', 'add_new_text_field', mediator.services.switches.isServerSwitchOn('evolentFeatures') && 'insert_references'];
        case 'visual_guideline_text_section':
          return ['delete'];
        case 'acp_recommendations_populations':
          return ['insert_recommendation', 'add_manual_recommendation', 'insert_subgroup', '__separator__', 'delete'];
      }
    })();
    return _.compact(settingsOrder);
  },
  getSettingsOptions: function(settingsOrder) {
    var isMabOrMar, isMdaOn, isMdgOn;
    isMdaOn = mediator.services.switches.isOn('mda');
    isMdgOn = mediator.services.switches.isOn('mdgTables');
    isMabOrMar = _.intersection(mediator.user.getAccessRights(), ['mda_mab', 'mda_mar']).length > 0;
    return _.chain(settingsOrder).filter(function(value) {
      var submoduleToCheck;
      switch (value) {
        case 'edit_appendices':
          return !isMdgOn;
        case 'insert_mda_table':
          return isMdaOn && !isMabOrMar;
        case 'insert_recommendation':
          return !isMdaOn;
        case 'open_question':
        case 'open_recommendation':
          if (isMdaOn) {
            return false;
          }
          submoduleToCheck = (function() {
            switch (value) {
              case 'open_question':
                if (isMdgOn) {
                  return 'mdg_evidence_table';
                } else {
                  return 'quality_of_evidence';
                }
                break;
              case 'open_recommendation':
                if (isMdgOn) {
                  return 'mdg_recommendations';
                } else {
                  return 'recommendations';
                }
            }
          })();
          return mediator.services.modules.isVisible("evidence_syntheses#" + submoduleToCheck, mediator.project);
        default:
          return true;
      }
    }).map(function(value) {
      return {
        value: value,
        text: $.t("docsec:chapter_settings_options:" + value)
      };
    }).value();
  },
  discussionsDbView: {
    map: function(doc) {
      var annotations, changes, chapterId, docContent, mdaTopicId, sectionId;
      if (doc.type === 'document_sections_section') {
        docContent = doc.content || {};
        annotations = Object.values(docContent.annotations || {});
        changes = Object.values((docContent.changeTracker || {}).changes || {});
        if (annotations.length || changes.length) {
          sectionId = doc._id;
          chapterId = doc.parent;
          mdaTopicId = doc.mdaTopicId;
          return emit(mdaTopicId, {
            annotations: docContent.annotations,
            changeTracker: docContent.changeTracker,
            chapterId: chapterId,
            mdaTopicId: mdaTopicId,
            sectionId: sectionId
          });
        }
      }
    }
  },
  SECTION_STATUSES: ['IN_PROGRESS', 'IN_REVIEW', 'UPDATED', 'DONE'],
  PLACEMENT_BEFORE: 'before',
  PLACEMENT_AFTER: 'after',
  SIDEBAR_CHAPTER_ITEM_DNT_TYPE: 'sidebarChapterItem',
  CHAPTER_SECTION_DND_TYPE: 'chapterSection',
  RECOMMENDATION_SECTION_APPENDICES_ORDER: ['additionalInformation', 'evidenceTable', 'etd']
};
