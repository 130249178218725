{ useMemo } = React

# adapted from https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/

range = (start, end) ->
  length = end - start + 1
  Array.from({ length }, (_, idx) => idx + start)

DOTS = '...'

usePagination = ({
  totalCount,
  pageSize,
  siblingCount,
  currentPage
}) ->
  paginationRange = useMemo () ->
    totalPageCount = Math.ceil(totalCount / pageSize)
    totalPageNumbers = siblingCount + 5

    #  If the number of pages is less than the page numbers we want to show in our paginationComponent, we return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount)
      return range(1, totalPageCount)

    # Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
    rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    )

    # We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    shouldShowLeftDots = leftSiblingIndex > 2
    shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    firstPageIndex = 1
    lastPageIndex = totalPageCount

    # No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots)
      leftRange = range(1, currentPage + siblingCount)
      return [leftRange..., DOTS, totalPageCount]

    # Case 3: No right dots to show, but left dots to be shown

    if (shouldShowLeftDots && !shouldShowRightDots)
      rightRange = range(currentPage - siblingCount, totalPageCount)
      return [firstPageIndex, DOTS, rightRange...]

    # Both left and right dots to be shown
    middleRange = range(leftSiblingIndex, rightSiblingIndex)
    return [firstPageIndex, DOTS, middleRange..., DOTS, lastPageIndex]

  , [totalCount, pageSize, siblingCount, currentPage]

  paginationRange


Pagination = ({ paginationData, onPageChange }) ->

  currentPage = paginationData.get('currentPage')

  paginationRange = usePagination({
    currentPage: currentPage,
    totalCount: paginationData.get('documentsCount'),
    siblingCount: 1,
    pageSize: paginationData.get('documentsPerPage')
  })

  if (currentPage is 0 || paginationRange?.length < 2)
    return null

  lastPage = paginationRange[paginationRange.length - 1]

  onNext = () -> onPageChange(currentPage + 1)
  onPrevious = () -> onPageChange(currentPage - 1)

  <div className="pagination-container">
    <ul className="pagination-list-container">
      <li
        className={classNames("pagination-item", { disabled: currentPage is 1 })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, idx) ->

        if (pageNumber is DOTS)
          return <li key={"dots-#{idx}"} className="pagination-item dots">&#8230</li>

        return (
          <li
            key={pageNumber}
            className={classNames("pagination-item", {
              selected: pageNumber is currentPage
            })}
            onClick={() -> onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      )}

      <li
        className={classNames("pagination-item", {
          disabled: currentPage is lastPage
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  </div>

module.exports = Pagination
