assessmentAdolopments = require 'components/etd/assessment_adolopments'
AssessmentSectionEditModal = require 'components/etd/panel_voice_etd/assessment_section_edit_modal'
AssessmentSectionHeader = require 'components/etd/assessment_section_header'
AssessmentSectionRow = require 'components/etd/assessment_section_row'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
PanelVoiceResultsRow = require 'components/etd/panel_voice_etd/panel_voice_results_row'
PrintoutCellTitlesRow = require 'components/etd/printout_cell_titles_row'
Translation = require 'components/mixins/translation'
{ getSectionEditToggleHandler } = require 'lib/panel_voice_helper'

AssessmentSectionWithPanelVoiceResults = createReactClass

  displayName: 'AssessmentSectionWithPanelVoiceResults'

  propTypes:
    activeJudgmentOptionBySection: PropTypes.instanceOf(Immutable.Map).isRequired
    activeResultsViewTypeBySection: PropTypes.instanceOf(Immutable.Map).isRequired
    additionalConsiderations: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map).isRequired
    attachments: PropTypes.instanceOf(Immutable.Map)
    collapsedAssessmentSections: PropTypes.instanceOf(Immutable.Map).isRequired
    criterion: PropTypes.instanceOf(Immutable.Map).isRequired
    editingSectionId: PropTypes.string
    etdHighlights: PropTypes.instanceOf(Immutable.Map).isRequired
    etdId: PropTypes.string.isRequired
    etdTab: PropTypes.string.isRequired
    etdViewSettings: PropTypes.instanceOf(Immutable.Map).isRequired
    getAdminVotingSummary: PropTypes.func.isRequired
    memberNamesMap: PropTypes.instanceOf(Immutable.Map).isRequired
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    parentSectionsData: PropTypes.instanceOf(Immutable.List).isRequired
    renderMode: PropTypes.string.isRequired
    researchEvidence: PropTypes.instanceOf(Immutable.Map).isRequired
    sectionData: PropTypes.instanceOf(Immutable.Map).isRequired
    showResultingDataOnly: PropTypes.bool
    sectionId: PropTypes.string.isRequired
    templateId: PropTypes.string.isRequired
    votedEtdData: PropTypes.instanceOf(Immutable.Map).isRequired
    votingResults: PropTypes.instanceOf(Immutable.Map).isRequired

  getDefaultProps: ->
    showResultingDataOnly: false
    overarchingQuestionData: Immutable.Map()

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  render: ->
    {
      activeJudgmentOptionBySection
      activeResultsViewTypeBySection
      additionalConsiderations
      adolopmentData
      adolopments
      collapsedAssessmentSections
      criterion
      editingSectionId
      etdHighlights
      etdId
      etdTab
      etdViewSettings
      getAdminVotingSummary
      index
      memberNamesMap
      overarchingQuestionData
      renderMode
      researchEvidence
      sectionData
      sectionId
      showResultingDataOnly
      templateId
      votedEtdData
      votingResults
    } = @props

    sectionName = sectionData.get 'name'
    expanded = not collapsedAssessmentSections.get(sectionId, false)
    tbodyClassess = classNames 'expanded': expanded
    sectionVotingResults = votingResults.getIn ['sections', sectionId]
    votingStatus = votingResults.get 'status'
    closedOrTestVoting = votingStatus in ['closed', 'test']

    sectionProps = _.pick @props, [
        'activeTab'
        'additionalConsiderationsVisible'
        'adolopmentData'
        'attachments'
        'criticalOutcomes'
        'editable'
        'outcomes'
        'overarchingQuestionData'
        'parentSectionsData'
        'showResultingDataOnly'
        'questionType'
        'renderMode'
        'sectionData'
        'sectionId'
        'templateId'
        'withSoj'
      ]

    # mixin voting-results-related props if there are voting results for this section
    if sectionVotingResults?
      _.extend sectionProps,
        highlightToPanelMembers: etdHighlights.get sectionId
        editable: closedOrTestVoting and etdTab is 'workspace'
        onEditClick: if closedOrTestVoting then null else getSectionEditToggleHandler sectionId

    withAdolopment = adolopmentData.get('withAdolopment', false)

    rows = []

    adolopmentSectionCollapsed = etdViewSettings.getIn(
      ['collapsedAdolopmentSections', sectionId], false)

    crId = sectionData.get('criterionId')
    criterion = if adolopments.isEmpty()
      criterion
    else if withAdolopment
      criterion
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'assessment', 'criteria', crId]


    reId = sectionData.get('researchEvidenceId')
    researchEvidence = if adolopments.isEmpty()
      researchEvidence
    else if withAdolopment
      researchEvidence
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'assessment', 'researchEvidences', reId]

    acId = sectionData.get('additionalConsiderationId')
    additionalConsideration = if adolopments.isEmpty()
      additionalConsiderations
    else if withAdolopment
      additionalConsiderations
    else
      adolopment = adolopments.last()
      adolopment.getIn ['templateData', 'assessment', 'additionalConsiderations', acId]

    sectionKey = if not adolopments.isEmpty() and withAdolopment
      'adolopment'
    else
      'original'

    sectionDescription = if sectionData.get('rating') and
    not _.isEmpty(criterion.get('ratingDescription'))
      criterion.get('ratingDescription')
    else
      criterion.get('description')

    sectionWithResultingData = <AssessmentSectionRow
      additionalConsiderations={additionalConsideration}
      criterion={criterion}
      isAdoloped={not adolopments.isEmpty()}
      key={"#{sectionId}-row"}
      researchEvidence={researchEvidence}
      sectionKey={sectionKey}
      sectionName={sectionName}
      withPanelVoice
      {...sectionProps}
    />

    if showResultingDataOnly
      rows.push sectionWithResultingData
    else
      if not adolopments.isEmpty() and withAdolopment
        adolopmentProps = _.extend {},
          adolopments: adolopments
          etdId: etdId,
          etdViewSettings: etdViewSettings
          i18n: @i18n,
          sectionProps,
          sectionData,
          sectionName

        rows.push assessmentAdolopments(adolopmentProps)
      if not adolopmentSectionCollapsed or not withAdolopment
        rows.push sectionWithResultingData

    rows.push <PanelVoiceResultsRow
      activeJudgmentOptionBySection={activeJudgmentOptionBySection}
      activeResultsViewTypeBySection={activeResultsViewTypeBySection}
      criterion={criterion}
      etdId={etdId}
      etdPart='judgements'
      etdTab={etdTab}
      getAdminVotingSummary={getAdminVotingSummary}
      key={"#{sectionId}-panel-voice-results"}
      memberNamesMap={memberNamesMap}
      overarchingQuestionData={overarchingQuestionData}
      renderMode={renderMode}
      responseColSpan={2}
      sectionId={sectionId}
      templateId={templateId}
      sectionData={sectionData}
      votedEtdData={votedEtdData.get(sectionId)}
      votingResults={votingResults}
    />
    if editingSectionId is sectionId
      rows.push <tr key={"#{sectionId}-panel-voice-edit-modal"}>
        <td>
          <AssessmentSectionEditModal
            additionalConsiderations={additionalConsideration}
            criterion={criterion}
            etdId={etdId}
            sectionName={sectionName}
            researchEvidence={researchEvidence}
            {...sectionProps}
          />
        </td>
      </tr>

    <tbody className={tbodyClassess}>
      <AssessmentSectionHeader
        colSpan={3}
        content={sectionDescription}
        etdId={etdId}
        expanded={expanded}
        index={index + 1}
        isBeingAdoloped={not adolopments.isEmpty()}
        info={criterion.get('info')}
        renderMode={renderMode}
        sectionId={sectionId}
        sectionName={sectionName}
        withAdolopment={withAdolopment}
      />
      <PrintoutCellTitlesRow i18n={@i18n} renderMode={renderMode}/>
      {rows if renderMode is 'printout' or expanded}
    </tbody>


module.exports = AssessmentSectionWithPanelVoiceResults
