var AdolopedEtdStore, AdolopmentDataStore, EpPresentations, EtdActions, EtdHelper, EtdStore, EtdsHelper, EtdsStore, Question, QuestionGroupsStore, QuestionsHelper, QuestionsStore, createPrintoutContainer, exportToFile, getDocPrefix, getDocumentType, getElementAppliedStylesString, getPresentationPropsFromAdolopment, getPresentationPropsFromEtdStore, getQuestionDataForExport, isofDocPrefix, mcDocPrefix, mergeEtdsBasedOnAdolopmentData, outcomesAttributesForPresentation, premiumDocPrefix, questionAttributesForPresentation, removeHTMLNode, _ref, _ref1;

AdolopedEtdStore = require('stores/adoloped_etd_store');

AdolopmentDataStore = require('stores/adolopment_data_store');

EtdActions = require('actions/etd_actions');

EtdHelper = require('lib/etd_helper');

EtdsHelper = require('lib/etds_helper');

EtdStore = require('stores/etd_store');

EtdsStore = require('stores/etds_store');

Question = require('models/question');

QuestionsHelper = require('lib/questions_helper');

QuestionsStore = require('stores/questions_store');

QuestionGroupsStore = require('stores/question_groups_store');

mergeEtdsBasedOnAdolopmentData = require('lib/adolopments_helper').mergeEtdsBasedOnAdolopmentData;

_ref = require('lib/doc_prefixes'), isofDocPrefix = _ref.isofPresentation, mcDocPrefix = _ref.mcPresentation, premiumDocPrefix = _ref.premiumPresentation;

exportToFile = require('lib/export_to_file').exportToFile;

_ref1 = require('base/lib/utils'), removeHTMLNode = _ref1.removeHTMLNode, createPrintoutContainer = _ref1.createPrintoutContainer, getElementAppliedStylesString = _ref1.getElementAppliedStylesString;

EpPresentations = window.epPresentations;

questionAttributesForPresentation = ['question', 'patients', 'intervention', 'indexTest', 'comparatorTest', 'comparatorTestPresent', 'comparison', 'healthProblemOrPopulation', 'type'];

outcomesAttributesForPresentation = ['name'];

getPresentationPropsFromAdolopment = function() {
  return {
    adolopments: AdolopedEtdStore.getAdolopments(),
    adolopmentData: AdolopmentDataStore.getAdolopmentData()
  };
};

getPresentationPropsFromEtdStore = function(premium) {
  if (premium == null) {
    premium = false;
  }
  return {
    attachments: EtdStore.getAttachments(),
    assessment: EtdStore.getAssessment(),
    assessmentSections: EtdStore.getAssessmentSections(),
    backgroundDetailSections: EtdStore.getBackgroundDetailSections(),
    clinicians: EtdStore.getPresentationSection('clinicians'),
    conclusions: EtdStore.getConclusionsSections(),
    currentEditable: EtdStore.getActiveContentEditable(),
    isFetchingEtd: EtdStore.isFetching(),
    isEditable: EtdStore.isEditable(),
    patients: EtdStore.getPresentationSection('patients'),
    policymakers: EtdStore.getPresentationSection('policymakers'),
    presentationData: EtdStore.getPresentationData(),
    presentationType: premium ? 'premium-patients' : EtdStore.getPresentationType(),
    question: EtdStore.getQuestion(),
    recommendation: EtdStore.getRecommendation(),
    relatedQuestionsIds: EtdStore.getRelatedQuestionsIds(),
    isIsofVisibleInPremiumView: EtdStore.isIsofVisibleInPremiumView(),
    templateUndefined: EtdStore.isTemplateUndefined()
  };
};

getDocPrefix = function(presentationType) {
  if (presentationType === 'premium-patients') {
    return "" + premiumDocPrefix + ":";
  } else {
    return '';
  }
};

getDocumentType = function(questionData) {
  switch (questionData.get('type')) {
    case 'good-practice-statement':
      return 'gps';
    case 'additional-guidance':
      return 'additional-guidance';
    default:
      return 'regular';
  }
};

getQuestionDataForExport = function(questionModel) {
  var outcomes;
  outcomes = _(questionModel.get('outcomes').toArray()).map(function(outcome) {
    return _(outcome.attributes).pick(outcomesAttributesForPresentation);
  });
  return _.extend({
    outcomes: outcomes
  }, _(questionModel.attributes).pick(questionAttributesForPresentation));
};

_.namespace(module, function(require) {
  var DbHelper, Exceptions, W, mediator, utils;
  DbHelper = require('base/lib/db_helper');
  Exceptions = require('lib/exceptions');
  W = require('when');
  mediator = require('mediator');
  utils = require('base/lib/utils');
  return {
    getEtdPresentationData: function(recommendationId, questionModel, presentationType) {
      var dbName, etds, projectName, questionGroups, questions;
      dbName = mediator.project.id;
      questions = QuestionsStore.getQuestions();
      questionGroups = QuestionGroupsStore.getQuestionGroups();
      etds = EtdsStore.getEtds();
      projectName = mediator.project.get('name');
      return this._prepareEtdDocForExport(dbName, recommendationId, questionModel, questions, questionGroups, etds, presentationType, projectName);
    },
    _prepareEtdDocForExport: function(dbName, etdId, questionModel, questions, questionGroups, etds, presentationType, projectName) {
      return W(mediator.services.storePersistenceAdapter.fetch(dbName, etdId, {
        attachments: true
      })).then(function(recommendation) {
        var adolopmentRecId, i18nPresentationType, questionData, recId, recommendationDoc, references, relatedQuestions, relatedQuestionsIds, relatedRecommendations, _ref2;
        delete recommendation._rev;
        relatedQuestionsIds = (_ref2 = recommendation.templateData.relatedQuestionsIds) != null ? _ref2 : Immutable.List();
        relatedQuestions = QuestionsHelper.getRelatedQuestions(questionGroups, questions, relatedQuestionsIds);
        relatedRecommendations = EtdsHelper.getRelatedRecommendations(etds, relatedQuestions);
        i18nPresentationType = $.t("es:recommendations.presentations.types." + presentationType);
        recId = _.last(questionModel.get('recommendationIds'));
        adolopmentRecId = _.last(questionModel.get('recommendationAdolopmentIds', []));
        recommendationDoc = _.isEmpty(adolopmentRecId) ? recommendation : mergeEtdsBasedOnAdolopmentData(AdolopedEtdStore.getAdolopments().get(adolopmentRecId), recommendation, AdolopmentDataStore.getAdolopmentData().get(recId, Immutable.Map()));
        references = EtdHelper.getReferencesFromEtd(Immutable.fromJS({
          template: recommendationDoc
        }));
        questionData = getQuestionDataForExport(questionModel);
        return _.extend(recommendationDoc, {
          isofData: QuestionsHelper.getQuestionIsofData(questionModel, projectName),
          presentationTitle: $.t("es:recommendations.presentations.for", {
            for_whom: i18nPresentationType
          }),
          presentationType: presentationType,
          documentType: getDocumentType(Immutable.fromJS(questionData)),
          questionData: questionData,
          questionTitle: questionModel.get('question'),
          relatedQuestions: relatedQuestions,
          references: references.toJS(),
          relatedRecommendations: relatedRecommendations
        });
      });
    },
    _preparePresentationDoc: function(questionModel, presentationType) {
      var dbName, docPrefix, loadingIndicator, projectId, recommendationId;
      projectId = mediator.project.id;
      loadingIndicator = mediator.dialogs.loading($.t('messages.working'));
      dbName = DbHelper.getPresentationsDbUrl();
      docPrefix = getDocPrefix(presentationType);
      recommendationId = _.first(questionModel.get('recommendationIds'));
      return this.getEtdPresentationData(recommendationId, questionModel, presentationType).then(this.uploadPresentation(dbName, "" + docPrefix + projectId + "_" + recommendationId))["catch"](this._handleError)["finally"](function() {
        return loadingIndicator.destroy();
      });
    },
    uploadPresentation: function(dbName, docId) {
      return function(recommendation) {
        return mediator.services.storePersistenceAdapter.updateOrCreate(dbName, docId, function(doc) {
          return _.extend({}, doc, recommendation, {
            _id: docId
          });
        });
      };
    },
    exportPresentation: function(projectId, presentationType, question, questions, questionGroups, etds, projectName) {
      var dbName, docPrefix, newDocId, questionModel, recommendationId;
      if (!Immutable.Map.isMap(questions)) {
        throw Error('Expected questions as Immutable.Map');
      }
      if (!Immutable.Map.isMap(questionGroups)) {
        throw Error('Expected questionGroups as Immutable.Map');
      }
      if (!Immutable.Map.isMap(etds)) {
        throw Error('Expected etds as Immutable.Map');
      }
      dbName = DbHelper.getPresentationsDbUrl();
      questionModel = new Question(question);
      recommendationId = _.first(questionModel.get('recommendationIds'));
      docPrefix = getDocPrefix(presentationType);
      newDocId = "" + docPrefix + projectId + "_" + recommendationId + "-" + presentationType + "-" + (+Date.now());
      return this._prepareEtdDocForExport(projectId, recommendationId, questionModel, questions, questionGroups, etds, presentationType, projectName).then(this.uploadPresentation(dbName, newDocId)).then((function(_this) {
        return function(docOrResponse) {
          var presentationDocId, _ref2;
          presentationDocId = (_ref2 = docOrResponse.id) != null ? _ref2 : docOrResponse._id;
          return DbHelper.getPresentationUrl(_this._getDocUrlPrefix(presentationDocId));
        };
      })(this))["catch"](this._handleError);
    },
    _createPresentationDocHTML: function(presentationType, presentationProps) {
      var $isofEl, $presentations, $styleEl, Presentations, fakeDoc, presentationsDocHTML, tempContainer;
      tempContainer = createPrintoutContainer();
      Presentations = React.createElement(EpPresentations, presentationProps);
      ReactDOM.render(Presentations, tempContainer);
      $isofEl = tempContainer.querySelector('.isof-card');
      if ($isofEl) {
        removeHTMLNode($isofEl.parentNode);
      }
      $presentations = tempContainer.querySelector('#presentations');
      $presentations.classList.toggle('pdf-export');
      fakeDoc = document.implementation.createHTMLDocument('');
      $styleEl = document.createElement('style');
      $styleEl.innerHTML = getElementAppliedStylesString($presentations);
      fakeDoc.head.appendChild($styleEl);
      fakeDoc.body.innerHTML = $presentations.outerHTML;
      presentationsDocHTML = '<!doctype html>' + fakeDoc.documentElement.outerHTML;
      fakeDoc = null;
      removeHTMLNode(tempContainer);
      $presentations.classList.toggle('pdf-export');
      return presentationsDocHTML;
    },
    exportPresentationDocHTML: function(presentationType, question, questions, questionGroups, etd, etds, projectName) {
      var clinicians, patients, policymakers, presentationsDocHTML, presentationsProps, questionData;
      EtdHelper.saveState();
      EtdActions.setTemplateWithDataForPdf(etd);
      questionData = Immutable.fromJS(question);
      presentationsProps = _.extend({}, {
        etds: Immutable.fromJS(etds),
        isofData: QuestionsHelper.getQuestionIsofData(new Question(question), projectName),
        isFetchingEtds: false,
        questionData: questionData,
        questions: Immutable.fromJS(questions),
        questionGroups: Immutable.fromJS(questionGroups),
        renderMode: 'printout',
        documentType: getDocumentType(questionData),
        toggleSofTableInPremiumView: function() {
          return null;
        }
      }, getPresentationPropsFromEtdStore(presentationType === 'premium-patients'), getPresentationPropsFromAdolopment());
      clinicians = presentationsProps.clinicians, patients = presentationsProps.patients, policymakers = presentationsProps.policymakers;
      if (_.every([clinicians, patients, policymakers], function(data) {
        return data.isEmpty();
      })) {
        return '';
      }
      presentationsDocHTML = this._createPresentationDocHTML(presentationType, presentationsProps);
      EtdHelper.restoreSavedState();
      return presentationsDocHTML;
    },
    saveToPDF: function(questionModel, presentationType) {
      var fileName, orientation, presentationsDocHTML, presentationsProps, questionData;
      EtdHelper.saveState();
      EtdActions.setEditableFlag(false);
      questionData = QuestionsStore.getQuestion();
      presentationsProps = _.extend({}, getPresentationPropsFromEtdStore(presentationType === 'premium-patients'), {
        isofData: QuestionsHelper.getQuestionIsofData(questionModel, mediator.project.get('name')),
        renderMode: 'printout',
        isFetchingEtds: EtdsStore.isFetching(),
        etds: EtdsStore.getEtds(),
        documentType: getDocumentType(questionData),
        questionData: questionData,
        questions: QuestionsStore.getQuestions(),
        questionGroups: QuestionGroupsStore.getQuestionGroups(),
        toggleSofTableInPremiumView: function() {
          return null;
        }
      }, getPresentationPropsFromAdolopment());
      presentationsDocHTML = this._createPresentationDocHTML(presentationType, presentationsProps);
      fileName = 'presentation_export';
      EtdHelper.restoreSavedState();
      orientation = presentationType === 'premium-patients' ? 'portrait' : 'landscape';
      return exportToFile('pdf', presentationsDocHTML, {
        fileName: fileName,
        orientation: orientation
      });
    },
    exportEtdPresentation: function(questionModel, presentationType) {
      var newTab;
      newTab = window.open('about:blank', '_blank');
      return this._preparePresentationDoc(questionModel, presentationType).then(this._openDocumentLink(newTab));
    },
    _uploadIsof: function(isofData, questionId, title, useTimestamp) {
      var dbName, docId, timestampPart;
      if (useTimestamp == null) {
        useTimestamp = true;
      }
      dbName = DbHelper.getPresentationsDbUrl();
      timestampPart = useTimestamp ? "-" + (+Date.now()) : '';
      docId = "" + isofDocPrefix + questionId + timestampPart;
      return mediator.services.storePersistenceAdapter.updateOrCreate(dbName, docId, function(doc) {
        return _.extend(doc, {
          isofData: isofData,
          title: title
        });
      });
    },
    _prepareIsofPresentationsDoc: function(isofData, questionId, title, useTimestamp) {
      var loadingIndicator;
      if (useTimestamp == null) {
        useTimestamp = true;
      }
      loadingIndicator = mediator.dialogs.loading($.t('messages.working'));
      return this._uploadIsof(isofData, questionId, title, useTimestamp)["catch"](this._handleError)["finally"](function() {
        return loadingIndicator.destroy();
      });
    },
    _prepareMcPresentationDoc: function(presentationData, mcDocId) {
      var dbName, docId;
      dbName = DbHelper.getPresentationsDbUrl();
      docId = "" + mcDocPrefix + mcDocId;
      return mediator.services.storePersistenceAdapter.updateOrCreate(dbName, docId, function(doc) {
        return _.extend(doc, {
          presentationData: presentationData
        });
      });
    },
    _getEmbeddableHTML: function(presentationURL) {
      var attrsString;
      attrsString = 'frameborder=\"0\" width=\"100%\" height=\"100%\" allowfullscreen';
      return "<iframe src=\"" + presentationURL + "\" " + attrsString + "> </iframe>";
    },
    getEmbeddablePresentationLink: function(questionModel, presentationType) {
      return this._preparePresentationDoc(questionModel, presentationType).then((function(_this) {
        return function(_arg) {
          var docId;
          docId = _arg._id;
          return _this._getEmbeddableHTML(DbHelper.getPresentationUrl(_this._getDocUrlPrefix(docId)));
        };
      })(this));
    },
    getEmbeddableIsofLink: function(isofData, questionId, title) {
      return this._prepareIsofPresentationsDoc(isofData, questionId, title).then((function(_this) {
        return function(_arg) {
          var docId;
          docId = _arg._id;
          return _this._getEmbeddableHTML(DbHelper.getPresentationUrl(_this._getDocUrlPrefix(docId)));
        };
      })(this));
    },
    getEmbeddableMcPresentationLink: function(presentationData, mcDocId) {
      return this._prepareMcPresentationDoc(presentationData, mcDocId).then((function(_this) {
        return function(_arg) {
          var docId;
          docId = _arg._id;
          return _this._getEmbeddableHTML(DbHelper.getPresentationUrl(_this._getDocUrlPrefix(docId)));
        };
      })(this));
    },
    exportIsof: function(question, projectName, useTimestamp) {
      var isofData;
      if (useTimestamp == null) {
        useTimestamp = true;
      }
      isofData = QuestionsHelper.getQuestionIsofData(new Question(question), projectName);
      return this._uploadIsof(isofData, question._id, question.question, useTimestamp).then((function(_this) {
        return function(_arg) {
          var docId;
          docId = _arg._id;
          return DbHelper.getPresentationUrl(_this._getDocUrlPrefix(docId));
        };
      })(this));
    },
    exportIsofPresentation: function(isofData, questionId, title, useTimestamp) {
      var newTab;
      if (useTimestamp == null) {
        useTimestamp = true;
      }
      newTab = window.open('about:blank', '_blank');
      return this._prepareIsofPresentationsDoc(isofData, questionId, title, useTimestamp).then(this._openDocumentLink(newTab));
    },
    exportMcPresentation: function(presentationData, mcDocId) {
      var newTab;
      newTab = window.open('about:blank', '_blank');
      return this._prepareMcPresentationDoc(presentationData, mcDocId).then(this._openDocumentLink(newTab));
    },
    _getDocUrlPrefix: function(docId) {
      if (_.str.startsWith(docId, isofDocPrefix)) {
        return "isof/" + docId;
      } else if (_.str.startsWith(docId, mcDocPrefix)) {
        return "multi-comparisons/" + docId;
      } else if (_.str.startsWith(docId, premiumDocPrefix)) {
        return "premium/" + docId;
      } else {
        return docId;
      }
    },
    _openDocumentLink: function(newTab) {
      return (function(_this) {
        return function(docOrResponse) {
          var presentationDocId, _ref2;
          presentationDocId = (_ref2 = docOrResponse.id) != null ? _ref2 : docOrResponse._id;
          return newTab.location.href = DbHelper.getPresentationUrl(_this._getDocUrlPrefix(presentationDocId));
        };
      })(this);
    },
    _handleError: function(error) {
      mediator.dialogs.error(error);
      return utils.reportRavenError(error);
    }
  };
});
