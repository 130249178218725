var ADOLOPMENT_OPTIONS, AGE, ALL_COUNTRIES, BOOLEAN_OPTIONS, BOOLEAN_OPTIONS_WITH_NOT_REPORTED, CERTAINTIES_OF_EVIDENCE, COUNTRIES, COVID_ASTERIXED_FIELDS, COVID_GENERAL_INFORMATION_FORM_ITEMS, COVID_RECOMMENDATION_FORM_ITEMS, FIELDS, FOCUS, GRADEBOOK_CHAPTERS, GRADE_HANDBOOK_FIELDS, GRADING_OPTIONS, INTENDED_POPULATIONS, INTERVENTION_TYPES, PCC_MODULES, PLUS, PROGRESS, PROGRESS_FIELDS, PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS, PUBLICATION_PLATFORM_RECOMMENDATION_FORM_ITEMS, PlainLanguageLinksField, RECOMMENDATION_INTENTS, RECOMMENDATION_INTENTS_WHO, RECOMMENDATION_PATH, RECOMMENDATION_STRENGTHS, SECOND_WORLD_REGION_OPTIONS, SYMPTOMS, TYPES_OF_ORGANIZATION, VACCINATION, VERSION_OPTIONS, WORLD_REGIONS, mediator, updateRecommendationWithProgressPlusDataFromGeneralInformationDoc;

mediator = require('mediator');

ALL_COUNTRIES = require('data/countries');

PlainLanguageLinksField = require('components/covid_extraction_form/plain_language_links_field');

TYPES_OF_ORGANIZATION = _.map(['nonProfit', 'governmental', 'academic', 'guidelineSociety', 'intergovernmental'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.typeOfOrganization." + value)
  };
});

BOOLEAN_OPTIONS = [
  {
    value: true,
    textKey: 'yes'
  }, {
    value: false,
    textKey: 'no'
  }
];

GRADING_OPTIONS = [
  {
    value: 'notGraded',
    textKey: 'notGraded'
  }, {
    value: 'grade',
    textKey: 'grade'
  }
];

BOOLEAN_OPTIONS_WITH_NOT_REPORTED = [
  {
    value: true,
    textKey: 'yes'
  }, {
    value: false,
    textKey: 'no'
  }, {
    value: 'notReported',
    textKey: 'notReported'
  }
];

VERSION_OPTIONS = [
  {
    value: 'prePrint',
    textKey: 'draft'
  }, {
    value: 'final',
    textKey: 'final'
  }
];

PROGRESS = _.map(['placeOfResidence', 'raceEthnicityCultureOrLanguage', 'occupation', 'genderOrSex', 'religion', 'education', 'ses', 'socialCapital'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.progress." + value)
  };
});

AGE = _.map(["any", "neonate", "infant", "child", "adolescent", "adult"], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.age." + value)
  };
});

PLUS = _.map(['personalCharacteristics', 'featuresOfRelationships', 'timeDependentRelationships'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.plus." + value)
  };
});

FIELDS = _.map(['clinicalPractice', 'hta', 'publicHealth', 'healthPolicyAndSystems'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.field." + value)
  };
});

FOCUS = _.map(['infectionPreventionAndControl', 'screeningAndDiagnosis', 'therapeuticsAndClinicalManagement', 'epidemiologyAndTransmission', 'travel', 'emergencyPreparedness', 'healthServicesAndSystem', 'basicScience'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.focus." + value)
  };
});

WORLD_REGIONS = _.map(['world', 'eastAsiaPacific', 'europeCentralAsia', 'latinAmericaCaribbean', 'middleEastNorthAfrica', 'northAmerica', 'southAsia', 'subSaharanAfrica'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.worldRegion." + value)
  };
});

SECOND_WORLD_REGION_OPTIONS = _.map(['global', 'afro', 'amro_paho', 'emro', 'euro', 'searo', 'wipro', 'missing'], function(value) {
  return {
    value: value,
    text: $.t("settings:general_information.values.secondWorldRegionCategory." + value)
  };
});

COUNTRIES = _.map(ALL_COUNTRIES, function(value) {
  return {
    value: value,
    text: $.t("countries:" + value)
  };
});

COVID_GENERAL_INFORMATION_FORM_ITEMS = {
  agree: [
    {
      fieldKey: 'scopeAndPurpose',
      type: 'percent'
    }, {
      fieldKey: 'rigorOfDevelopment',
      type: 'percent'
    }, {
      fieldKey: 'editorialProcess',
      type: 'percent'
    }, {
      fieldKey: 'scorePassed',
      type: 'radioOptions',
      options: BOOLEAN_OPTIONS,
      infoText: true
    }
  ],
  main: [
    {
      fieldKey: 'guidelineNoId',
      type: 'text'
    }, {
      fieldKey: 'title',
      type: 'text'
    }, {
      fieldKey: 'authors',
      type: 'text'
    }, {
      fieldKey: 'fullReference',
      type: 'text'
    }, {
      fieldKey: 'publicationDate',
      type: 'date'
    }, {
      fieldKey: 'version',
      type: 'radioOptions',
      options: VERSION_OPTIONS,
      placeholderFromI18n: true
    }, {
      fieldKey: 'source',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'organizationType',
      type: 'multiSelect',
      options: TYPES_OF_ORGANIZATION
    }, {
      fieldKey: 'sourceDocumentLink',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'whoIrisId',
      type: 'text'
    }, {
      fieldKey: 'isbn',
      type: 'text'
    }, {
      fieldKey: 'doi',
      type: 'text'
    }, {
      fieldKey: 'pmid',
      type: 'text'
    }, {
      fieldKey: 'guidelineGroupDetails',
      type: 'switchableText',
      options: BOOLEAN_OPTIONS,
      placeholderFromI18n: true
    }, {
      fieldKey: 'declarationOfInterest',
      type: 'radioOptions',
      options: BOOLEAN_OPTIONS,
      helperTextFromI18n: true
    }, {
      fieldKey: 'describedAsRapid',
      type: 'radioOptions',
      options: BOOLEAN_OPTIONS
    }, {
      fieldKey: 'describedAsLiving',
      type: 'radioOptions',
      options: BOOLEAN_OPTIONS
    }, {
      fieldKey: 'includeNonEnglishDatabases',
      type: 'radioOptions',
      options: BOOLEAN_OPTIONS_WITH_NOT_REPORTED
    }, {
      fieldKey: 'latestLiteratureSearchDate',
      type: 'date'
    }, {
      fieldKey: 'gradingEvidenceMethod',
      type: 'radioOptions',
      options: GRADING_OPTIONS,
      placeholderFromI18n: true,
      withOther: true
    }, {
      fieldKey: 'focus',
      type: 'multiSelect',
      options: FOCUS
    }
  ],
  codes: [
    {
      fieldKey: 'guidelineInterventions',
      type: "codes"
    }, {
      fieldKey: 'guidelinePopulations',
      type: "codes"
    }
  ],
  progress: [
    {
      fieldKey: 'progress',
      type: 'multiSelect',
      options: PROGRESS
    }, {
      fieldKey: 'plus',
      type: 'multiSelect',
      options: PLUS
    }, {
      fieldKey: "progressPlus",
      type: 'text'
    }, {
      fieldKey: 'consumerEngagement',
      type: 'switchableText',
      options: BOOLEAN_OPTIONS_WITH_NOT_REPORTED
    }, {
      fieldKey: 'peopleWithLivedExperience',
      type: 'switchableText',
      options: BOOLEAN_OPTIONS
    }, {
      fieldKey: 'targetAudience',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'field',
      type: 'multiSelect',
      options: FIELDS
    }, {
      fieldKey: 'secondWorldRegionCategory',
      type: 'multiSelect',
      options: SECOND_WORLD_REGION_OPTIONS
    }, {
      fieldKey: 'worldRegion',
      type: 'multiSelect',
      options: WORLD_REGIONS
    }, {
      fieldKey: 'country',
      type: 'multiSelect',
      options: COUNTRIES
    }
  ],
  seo: [
    {
      fieldKey: 'keywords',
      type: 'text',
      placeholderFromI18n: true
    }
  ]
};

PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'title',
      showClear: false,
      type: 'text'
    }, {
      fieldKey: 'slug',
      helperTextFromI18n: true,
      showClear: false,
      type: 'text'
    }, {
      fieldKey: 'generalDescription',
      showClear: false,
      type: 'text'
    }, {
      fieldKey: 'authors',
      type: 'text'
    }, {
      fieldKey: 'fullReference',
      type: 'text'
    }, {
      fieldKey: 'publicationDate',
      type: 'date'
    }, {
      fieldKey: 'version',
      type: 'radioOptions',
      options: VERSION_OPTIONS,
      placeholderFromI18n: true
    }, {
      fieldKey: 'source',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'sourceDocumentLink',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'isbn',
      type: 'text'
    }, {
      fieldKey: 'doi',
      type: 'text'
    }, {
      fieldKey: 'pmid',
      type: 'text'
    }, {
      fieldKey: 'latestLiteratureSearchDate',
      type: 'date'
    }, {
      fieldKey: 'targetAudience',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'field',
      type: 'multiSelect',
      options: FIELDS
    }
  ]
};

GRADEBOOK_CHAPTERS = _.map(['introduction', 'makingAnswerableQuestions', 'assessingTheCertaintyOfTheEvidenceAboutEffects', 'assessingTheCertaintyOfTheEvidenceOfTestsAccuracy', 'assessingTheCertaintyOfTheEvidenceOfPrognosisAndBaselineRisk', 'multipleComparisonsAndNma', 'fromEvidenceToDecision', 'fromEvidenceToDecisionOnDiagnosticTests', 'presentationDisseminationAndImplementation', 'theGradeToolKit', 'adolopment', 'livingGuidelines', 'usingOfGradeInSpecialtyAreas'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.gradebookChapters." + value)
  };
});

GRADE_HANDBOOK_FIELDS = [
  {
    fieldKey: 'gradebookChapter',
    type: 'select',
    options: GRADEBOOK_CHAPTERS
  }, {
    fieldKey: 'oldGradebookLink',
    type: 'text',
    placeholderFromI18n: true
  }
];

RECOMMENDATION_INTENTS = _.map(['infectionControl', 'vaccination', 'screening', 'diagnosis', 'treatmentAndRehabilitation', 'prognosis', 'planningAndMonitoring', 'healthServicesAndSystems'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.recommendationIntent." + value)
  };
});

RECOMMENDATION_INTENTS_WHO = _.map(['infectionPreventionControl', 'vaccination', 'screening', 'diagnosis', 'clinicalManagement', 'prognosis', 'emergencyPreparedness', 'healthSystemsAndService'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.recommendationIntentWHO." + value)
  };
});

INTENDED_POPULATIONS = _.map(['healthcareWorkers', 'publicHealthOrganizations', 'hospitals', 'educationalInstitutions', 'patientsOrPublic', 'governments', 'humanitarianOrganizations'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.intendedPopulation." + value)
  };
});

CERTAINTIES_OF_EVIDENCE = _.map(['very_low', 'very_low_to_low', 'low', 'low_to_moderate', 'moderate', 'moderate_to_high', 'high', 'other', 'missing'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.certainties." + value)
  };
});

RECOMMENDATION_STRENGTHS = _.map(['recommend_against', 'suggest_against', 'suggest_either', 'suggest', 'recommend', 'other', 'missing'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.strengthOfRecommendation." + value)
  };
});

SYMPTOMS = _.map(['respiratory', 'musculoskeletal', 'cognitive', 'fatigue', 'chest_pain', 'other', 'not_applicable'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.symptoms." + value)
  };
});

VACCINATION = _.map(['fully_vaccinated', 'partially_vaccinated', 'unvaccinated', 'not_reported'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.vaccination." + value)
  };
});

INTERVENTION_TYPES = _.map(['pharmacological', 'non_pharmacological', 'psychosocial'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.intervention_types." + value)
  };
});

ADOLOPMENT_OPTIONS = _.map(['adapted', 'adopted', 'de_novo'], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.adolopment." + value)
  };
});

PCC_MODULES = _.map(["prevention", "testing", "clinical_interventions", "neurological_and_psychiatric_topics", "pediatric_and_adolescent_topics", "health_care_services_and_systems"], function(value) {
  return {
    value: value,
    text: $.t("es:recommendations.metadataExtractionForm.values.pccModule." + value)
  };
});

COVID_RECOMMENDATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'age',
      type: 'multiSelect',
      options: AGE
    }, {
      fieldKey: 'recommendationIntent',
      type: 'select',
      options: RECOMMENDATION_INTENTS
    }, {
      fieldKey: 'canPCCModule',
      type: 'select',
      options: PCC_MODULES
    }, {
      fieldKey: 'publicationDate',
      type: 'date'
    }, {
      fieldKey: 'authors',
      type: 'editorText'
    }, {
      fieldKey: 'recommendationIntentWHO',
      type: 'select',
      options: RECOMMENDATION_INTENTS_WHO,
      featureSwitch: 'show_who_covid_intents'
    }, {
      fieldKey: 'coexistingCondition',
      type: 'text',
      placeholderFromI18n: true,
      helperTextFromI18n: true
    }, {
      fieldKey: 'intendedPopulation',
      type: 'multiSelect',
      options: INTENDED_POPULATIONS
    }, {
      fieldKey: 'informal',
      type: 'switcher'
    }, {
      fieldKey: 'research',
      type: 'switcher'
    }, {
      fieldKey: 'reportedAsGPS',
      type: 'switcher'
    }, {
      fieldKey: 'adapted',
      type: 'switcher',
      hide: function() {
        return mediator.activeWorkspace === 'o_can_pcc_0';
      }
    }, {
      fieldKey: 'adolopment',
      type: 'select',
      options: ADOLOPMENT_OPTIONS
    }, {
      fieldKey: 'transformed',
      type: 'switcher'
    }, {
      fieldKey: 'gradingEvidenceMethod',
      type: 'radioOptions',
      options: GRADING_OPTIONS,
      placeholderFromI18n: true,
      withOther: true
    }, {
      fieldKey: 'sofSupplemented',
      type: 'switcher'
    }, {
      fieldKey: 'etdModified',
      type: 'switcher'
    }, {
      fieldKey: 'certaintyOfEvidence',
      type: 'selectWithOther',
      options: CERTAINTIES_OF_EVIDENCE,
      placeholderFromI18n: true
    }, {
      fieldKey: 'strengthOfRecommendation',
      type: 'selectWithOther',
      options: RECOMMENDATION_STRENGTHS,
      placeholderFromI18n: true
    }, {
      fieldKey: 'expertComment',
      type: 'editorText'
    }, {
      fieldKey: 'plainLanguageSummaryDescription',
      type: 'editorText'
    }, {
      fieldKey: 'plainLanguageSummary',
      type: 'custom',
      customWithArray: true,
      component: PlainLanguageLinksField,
      showClear: false
    }, {
      fieldKey: 'relatedRecommendationLinks',
      type: 'custom',
      customWithArray: true,
      component: PlainLanguageLinksField,
      showClear: false,
      additionalProps: {
        addBtnLabel: $.t('/actions.add_related_recommendation')
      }
    }, {
      fieldKey: 'individualRecommendation',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'evidenceTableUrl',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'loveUrl',
      type: 'text',
      placeholderFromI18n: true
    }, {
      fieldKey: 'emlUrl',
      type: 'multiText',
      placeholderFromI18n: true,
      addValueLabel: $.t('actions.add_link')
    }, {
      fieldKey: 'symptoms',
      type: 'multiSelect',
      options: SYMPTOMS
    }, {
      fieldKey: 'vaccination',
      type: 'select',
      options: VACCINATION
    }, {
      fieldKey: 'interventionTypes',
      type: 'select',
      options: INTERVENTION_TYPES
    }, {
      fieldKey: 'hospitalization',
      type: 'radioOptions',
      options: BOOLEAN_OPTIONS_WITH_NOT_REPORTED
    }
  ],
  progress: [
    {
      fieldKey: 'progress',
      type: 'multiSelect',
      options: PROGRESS
    }, {
      fieldKey: 'plus',
      type: 'multiSelect',
      options: PLUS
    }, {
      fieldKey: "progressPlus",
      type: 'text'
    }
  ],
  seo: [
    {
      fieldKey: 'keywords',
      type: 'text',
      placeholderFromI18n: true
    }
  ]
};

PUBLICATION_PLATFORM_RECOMMENDATION_FORM_ITEMS = {
  main: [
    {
      fieldKey: 'age',
      type: 'multiSelect',
      options: AGE
    }, {
      fieldKey: 'recommendationIntent',
      type: 'select',
      options: RECOMMENDATION_INTENTS
    }, {
      fieldKey: 'coexistingCondition',
      type: 'text',
      placeholderFromI18n: true,
      helperTextFromI18n: true
    }, {
      fieldKey: 'intendedPopulation',
      type: 'multiSelect',
      options: INTENDED_POPULATIONS
    }, {
      fieldKey: 'informal',
      type: 'switcher'
    }, {
      fieldKey: 'research',
      type: 'switcher'
    }, {
      fieldKey: 'adapted',
      type: 'switcher'
    }, {
      fieldKey: 'transformed',
      type: 'switcher'
    }, {
      fieldKey: 'certaintyOfEvidence',
      type: 'selectWithOther',
      options: CERTAINTIES_OF_EVIDENCE,
      placeholderFromI18n: true
    }, {
      fieldKey: 'strengthOfRecommendation',
      type: 'selectWithOther',
      options: RECOMMENDATION_STRENGTHS,
      placeholderFromI18n: true
    }, {
      fieldKey: 'plainLanguageSummary',
      type: 'custom',
      customWithArray: true,
      component: PlainLanguageLinksField,
      showClear: false
    }
  ]
};

PROGRESS_FIELDS = ['progress', 'plus', 'progressPlus'];

RECOMMENDATION_PATH = ['templateData', 'conclusions', 'sections', 'recommendation'];

updateRecommendationWithProgressPlusDataFromGeneralInformationDoc = function(recommendationDoc, generalInformationDoc) {
  var immDoc, updatedDoc;
  immDoc = Immutable.fromJS(recommendationDoc);
  updatedDoc = _.reduce(PROGRESS_FIELDS, function(acc, field) {
    var generalInformationDocValue;
    generalInformationDocValue = generalInformationDoc[field];
    if (!generalInformationDocValue) {
      return acc;
    }
    return acc.setIn(_.union(RECOMMENDATION_PATH, [field]), generalInformationDocValue);
  }, immDoc);
  return updatedDoc.toJS();
};

COVID_ASTERIXED_FIELDS = ['age', 'authors', 'certaintyOfEvidence', 'consumerEngagement', 'declarationOfInterest', 'describedAsLiving', 'describedAsRapid', 'emlUrl', 'evidenceTableUrl', 'field', 'focus', 'fullReference', 'gradingEvidenceMethod', 'guidelineGroupDetails', 'guidelineNoId', 'includeNonEnglishDatabases', 'individualRecommendation', 'informal', 'intendedPopulation', 'latestLiteratureSearchDate', 'publicationDate', 'recommendationIntent', 'research', 'secondWorldRegionCategory', 'source', 'sourceDocumentLink', 'strengthOfRecommendation', 'targetAudience', 'title', 'whoIrisId', 'recommendationIntentWHO'];

module.exports = {
  COVID_GENERAL_INFORMATION_FORM_ITEMS: COVID_GENERAL_INFORMATION_FORM_ITEMS,
  COVID_RECOMMENDATION_FORM_ITEMS: COVID_RECOMMENDATION_FORM_ITEMS,
  COVID_ASTERIXED_FIELDS: COVID_ASTERIXED_FIELDS,
  GRADE_HANDBOOK_FIELDS: GRADE_HANDBOOK_FIELDS,
  PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS: PUBLICATION_PLATFORM_GENERAL_INFORMATION_FORM_ITEMS,
  PUBLICATION_PLATFORM_RECOMMENDATION_FORM_ITEMS: PUBLICATION_PLATFORM_RECOMMENDATION_FORM_ITEMS,
  updateRecommendationWithProgressPlusDataFromGeneralInformationDoc: updateRecommendationWithProgressPlusDataFromGeneralInformationDoc
};
