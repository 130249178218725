var DbHelper, Model, W;

Model = require('models/base/model');

DbHelper = require('base/lib/db_helper');

W = require('when');

module.exports = {
  initialize: function() {
    return this.adjustClock();
  },
  _calculateAndSaveClockSkew: function(startTime, serverTimestamp) {
    var clockSkew, endTime;
    endTime = Date.now();
    clockSkew = Math.round(serverTimestamp - endTime + (endTime - startTime) / 2);
    return window.gdt.storage.set('clockSkew', clockSkew).then(function() {
      return clockSkew;
    });
  },
  adjustClock: function() {
    var startTime, url;
    startTime = Date.now();
    url = DbHelper.getClockUrl();
    return fetch(url).then(function(res) {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    }).then((function(_this) {
      return function(res) {
        return _this._calculateAndSaveClockSkew(startTime, res.timestamp);
      };
    })(this))["catch"](function() {
      return window.gdt.storage.get('clockSkew');
    }).then((function(_this) {
      return function(clockSkew) {
        return _this._clockSkew = Number(clockSkew);
      };
    })(this));
  },
  getTimestamp: function() {
    return Date.now() + this._clockSkew;
  }
};
