IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
{ NetworkGraphSvg } = NmaComponents
NMAVizActions = require 'actions/nma_viz_actions'
NMAVizExportModal = require 'components/nma/nma_viz_export_modal'
NMAToolbarWithQuestionList = require 'components/nma/nma_toolbar_with_question_list'
Router = require 'router'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useI18n } = require 'lib/react_utils'

NMAVizToolbar = ({ questionId }) ->
  onBack = useCoffeeCallback [questionId], ->
    route =  Router::getProjectRelativeUrl "/nma/#{questionId}"
    mediator.publish '!router:route', route

  i18n = useI18n 'nma:viz'

  rightElement =
    <React.Fragment>
      <Tooltip>
        <button
          className="network-graph-button"
          onClick={NMAVizActions.openNetworkGraph}
          title={i18n 'network_graph'}
        >
          <NetworkGraphSvg />
        </button>
      </Tooltip>
      <Tooltip>
        <div
          className="export-button"
          onClick={NMAVizActions.openExportModal}
          title={i18n '/translation:actions.export'}
        >
          <IconButton iconName="export" />
        </div>
      </Tooltip>
      <NMAVizExportModal />
    </React.Fragment>

  <NMAToolbarWithQuestionList
    className="nma-viz-toolbar"
    onBack={onBack}
    rightElement={rightElement}
  />

NMAVizToolbar.propTypes =
  questionId: PropTypes.string.isRequired

module.exports = NMAVizToolbar
