var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

_.namespace(module, function(require) {
  var AdolopedEtdActions, AdolopmentDataActions, DbHelper, EBMonFHIRManagementQuestionExporter, EtdActions, EtdVotingStore, Exceptions, HtmlExport, ISoFDxConverter, ISoFTxConverter, RECOMMENDATION_FIELDS, RECOMMENDATION_OPTIONS_TO_CHECK, SELECTED_OPTION_FIELDS, W, alt, mediator, option, section, sequence, utils;
  AdolopedEtdActions = require('actions/adoloped_etd_actions');
  AdolopmentDataActions = require('actions/adolopment_data_actions');
  HtmlExport = require('lib/html_export');
  ISoFTxConverter = require('models/evidence_syntheses/isof-tx-converter');
  ISoFDxConverter = require('models/evidence_syntheses/isof-dx-converter');
  EtdActions = require('actions/etd_actions');
  mediator = require('mediator');
  alt = require('alt');
  W = require('when');
  sequence = require('when/sequence');
  DbHelper = require('base/lib/db_helper');
  Exceptions = require('lib/exceptions');
  EtdVotingStore = require('stores/etd_voting_store');
  EBMonFHIRManagementQuestionExporter = require('lib/services/jsonld_exporter/ebmonfhir_management_question_exporter');
  utils = require('base/lib/utils');
  RECOMMENDATION_FIELDS = ['recommendationTypeIntervention', 'recommendationTypeOption', 'decisionTypeCoverage', 'decisionTypeImplementation'];
  SELECTED_OPTION_FIELDS = ['selectedOptionConsensus', 'selectedOption'];
  RECOMMENDATION_OPTIONS_TO_CHECK = _((function() {
    var _i, _len, _results;
    _results = [];
    for (_i = 0, _len = RECOMMENDATION_FIELDS.length; _i < _len; _i++) {
      section = RECOMMENDATION_FIELDS[_i];
      _results.push((function() {
        var _j, _len1, _results1;
        _results1 = [];
        for (_j = 0, _len1 = SELECTED_OPTION_FIELDS.length; _j < _len1; _j++) {
          option = SELECTED_OPTION_FIELDS[_j];
          _results1.push([section, option]);
        }
        return _results1;
      })());
    }
    return _results;
  })()).flatten(true);
  return {
    _mapQualityJudgement: function(qualityJudgement) {
      var qualities;
      qualities = {
        'very_low': 1,
        'low': 2,
        'moderate': 3,
        'high': 4
      };
      return qualities[qualityJudgement];
    },
    _getPrintout: function(question, type, options) {
      var htmlExport;
      if (options == null) {
        options = {};
      }
      htmlExport = new HtmlExport(question, type, _.extend({
        options: options,
        showResultingDataOnly: true
      }));
      return htmlExport.getSource();
    },
    _getAttr: function(q, v) {
      var _ref;
      return _.str.stripTags((_ref = q.get(v)) != null ? _ref : '');
    },
    _getIEtDData: function(project, q, recommendationId) {
      return W.promise((function(_this) {
        return function(resolve) {
          var actions, actionsToStoresMap, callbackId, exportAfterDataIsFetched, hasAdolopment, isFetchingInStores, recommendationAdolopmentIds;
          exportAfterDataIsFetched = function() {
            return mediator.services.presentationService.getEtdPresentationData(recommendationId, q, 'clinicians').then(function(ietd) {
              var conclusionSections, conclusions, options, _ref, _ref1;
              options = EtdVotingStore.getVotingData().isEmpty() ? {} : {
                etdViewMode: 'judgements-consensus#recommendation-consensus#conclusions-proposed',
                showVotingResults: false
              };
              conclusionSections = ietd != null ? (_ref = ietd.templateData) != null ? (_ref1 = _ref.conclusions) != null ? _ref1.sections : void 0 : void 0 : void 0;
              conclusions = _.chain(RECOMMENDATION_OPTIONS_TO_CHECK).map(function(_arg) {
                var option, section, _ref2;
                section = _arg[0], option = _arg[1];
                return conclusionSections != null ? (_ref2 = conclusionSections[section]) != null ? _ref2[option] : void 0 : void 0;
              }).compact().value();
              if (conclusions.length) {
                return _this._getPrintout(q, 'recommendations-v2', options).then(function(printout) {
                  return {
                    ietdPrintout: printout,
                    ietd: ietd
                  };
                });
              } else {
                return {};
              }
            });
          };
          recommendationAdolopmentIds = q.get('recommendationAdolopmentIds');
          hasAdolopment = !_.isEmpty(recommendationAdolopmentIds);
          isFetchingInStores = {
            etd: true,
            questions: true,
            voting: true,
            references: true,
            adolopmentData: hasAdolopment,
            adolopmentEtd: hasAdolopment
          };
          actionsToStoresMap = {
            'EtdActions.fetchSuccess': 'etd',
            'EtdActions.fetchError': 'etd',
            'ReferencesActions.fetchSuccess': 'references',
            'ReferencesActions.fetchError': 'references',
            'EtdVotingActions.fetchSuccess': 'voting',
            'EtdVotingActions.fetchError': 'voting',
            'QuestionsActions.fetchSuccess': 'questions',
            'QuestionsActions.fetchError': 'questions',
            'AdolopedEtdActions.fetchSuccess': 'adolopmentEtd',
            'AdolopedEtdActions.fetchError': 'adolopmentEtd',
            'AdolopmentDataActions.fetchAdolopmentDataSuccess': 'adolopmentData',
            'AdolopmentDataActions.fetchAdolopmentDataError': 'adolopmentData'
          };
          actions = _(actionsToStoresMap).keys();
          callbackId = alt.dispatcher.register(function(payload) {
            var _ref;
            if (_ref = payload.action, __indexOf.call(actions, _ref) < 0) {
              return;
            }
            isFetchingInStores[actionsToStoresMap[payload.action]] = false;
            if (_.any(_(isFetchingInStores).values())) {
              return;
            }
            alt.dispatcher.unregister(callbackId);
            return resolve(exportAfterDataIsFetched());
          });
          EtdActions.fetch({
            dbId: project.id,
            docId: [recommendationId]
          });
          if (hasAdolopment) {
            AdolopedEtdActions.fetch({
              dbId: project.id,
              docIds: recommendationAdolopmentIds
            });
            return AdolopmentDataActions.fetchAdolopmentData(project.id);
          }
        };
      })(this));
    },
    _getOldRecommendationData: function(q) {
      var balance;
      balance = q.get('consequencesBalance');
      return {
        benefitsAndHarms: this._getAttr(q, 'benefitsHarmsAdditionalConsiderations'),
        valuesAndPreferences: this._getAttr(q, 'valuesPreferencesExplanation'),
        remarks: this._getAttr(q, 'recommendationJustification'),
        resources: this._getAttr(q, 'incrementalCostToNetBenefitsAdditionalConsiderations'),
        evidenceQuality: this._mapQualityJudgement(this._getAttr(q, 'overallCertaintyJudgement')),
        recommendations: [
          {
            text: this._getAttr(q, 'recommendationText'),
            strength: (balance != null ? balance.indexOf('probably') : void 0) === 0 ? 1 : (balance != null ? balance.indexOf('clearly') : void 0) === 0 ? 2 : void 0
          }
        ]
      };
    },
    _getRecommendationData: function(project, q) {
      var balance, recommendationId, _ref;
      recommendationId = (_ref = q.get('recommendationIds')) != null ? _ref[0] : void 0;
      balance = q.get('consequencesBalance');
      return W(recommendationId ? this._getIEtDData(project, q, recommendationId) : balance ? this._getOldRecommendationData(q) : {});
    },
    _getAdditionalData: function(project, q) {
      var projectName, workspaceId;
      projectName = project.get('name');
      workspaceId = project.get('organizationId');
      return this._getRecommendationData(project, q).then((function(_this) {
        return function(recommendationData) {
          var additionalFormats, ebmOnFHIRExporter, err, extendedObj, layers, questionJson, sources, _ref, _ref1;
          extendedObj = _.extend(recommendationData, {
            guidelineId: project.id,
            guidelineTitle: projectName,
            guidelineWorkspace: workspaceId
          });
          if (mediator.services.switches.isServerSwitchOn('minsalFeatures')) {
            extendedObj['availablePresentationSectionTabs'] = ['background', 'justification', 'implementationConsiderations'];
          }
          if (q.isDiagnostic()) {
            layers = ['layerOne', 'layerTwo', 'layerOneSof', 'layerTwoSof'];
            return W.all(_(layers).map(function(l) {
              return _this._getPrintout(q, l);
            })).then(function(printouts) {
              var comparatorTestPresent;
              comparatorTestPresent = q.get('comparatorTestPresent');
              return _.extend(extendedObj, {
                layerOne: printouts[0],
                layerTwo: printouts[1],
                layerOneSof: printouts[2],
                layerTwoSof: printouts[3],
                isofData: ISoFDxConverter.convertDiagnostic(q, false, projectName),
                comparatorISoFData: comparatorTestPresent ? ISoFDxConverter.convertDiagnostic(q, true, projectName) : void 0
              });
            });
          } else {
            additionalFormats = {};
            if (mediator.services.switches.isOn('ebmonfhir-dbep-export')) {
              questionJson = q.toJSON();
              try {
                ebmOnFHIRExporter = new EBMonFHIRManagementQuestionExporter(questionJson);
                additionalFormats.ebmonfhir = ebmOnFHIRExporter["export"]();
              } catch (_error) {
                err = _error;
                console.error('Could not export EBM on FHIR format; skipping:', err);
                utils.reportRavenError(err, {
                  extra: {
                    info: "Could not export EBM on FHIR format: " + ((_ref = err.message) != null ? _ref : ''),
                    projectId: (_ref1 = mediator.project) != null ? _ref1.id : void 0,
                    questionId: questionJson._id
                  }
                });
              }
            }
            sources = ['sof', 'oneRow'];
            return W.all(_(sources).map(function(s) {
              return _this._getPrintout(q, s);
            })).then(function(printouts) {
              return _.extend(extendedObj, additionalFormats, {
                sofSource: printouts[0],
                oneRowSource: printouts[1],
                isofData: ISoFTxConverter.convertTreatment(q, false),
                comparatorISoFData: ISoFTxConverter.convertTreatment(q, true)
              });
            });
          }
        };
      })(this));
    },
    _export: function(project, processQuestion, questionIds) {
      if (questionIds == null) {
        questionIds = null;
      }
      return project.getCollections().questions.fetch().then(function(questions) {
        var question, questionModels;
        questionModels = questionIds ? _(questions.models).filter(function(qm) {
          var _ref;
          return _ref = qm.id, __indexOf.call(questionIds, _ref) >= 0;
        }) : questions.models;
        return sequence((function() {
          var _i, _len, _results;
          _results = [];
          for (_i = 0, _len = questionModels.length; _i < _len; _i++) {
            question = questionModels[_i];
            _results.push(processQuestion(question));
          }
          return _results;
        })());
      });
    },
    _exportProject: function(project, questionIds, progressBarHandler) {
      var is_ietd, processQuestion;
      is_ietd = project.get('recommendationsMode') === 'static';
      processQuestion = (function(_this) {
        return function(question) {
          return function() {
            var pendingExtra, pendingJsonLd, questionJson, recommendationType;
            recommendationType = is_ietd ? 'ietd' : 'v1';
            questionJson = question.toJSON();
            pendingExtra = _this._getAdditionalData(project, question).then(function(data) {
              return _(questionJson).extend(data);
            });
            pendingJsonLd = question.isDiagnostic() ? mediator.services.jsonldDiagnosticExport["export"](questionJson, recommendationType) : mediator.services.jsonldManagementExport["export"](questionJson, recommendationType);
            return W.all([pendingExtra, pendingJsonLd], function(_arg) {
              var extra, jsonLd, recommendation;
              extra = _arg[0], jsonLd = _arg[1];
              progressBarHandler();
              question = _(jsonLd[0]).extend({
                extra: extra
              });
              recommendation = jsonLd[1];
              if (recommendation) {
                return [question, recommendation];
              } else {
                return [question];
              }
            });
          };
        };
      })(this);
      return this._export(project, processQuestion, questionIds);
    },
    uploadProject: function(project, profiles) {
      var okHandler, params, url;
      url = "" + (DbHelper.getDbepApiUrl()) + "/guidelines";
      params = this._getUploadParams(project, profiles);
      okHandler = function(response) {
        return {
          ok: true,
          link: response.url
        };
      };
      return fetch(url, params).then(function(res) {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      }).then(okHandler)["catch"](this._errorHandler);
    },
    uploadProjectToStaging: function(project, profiles) {
      var okHandler, params, url;
      url = "" + (DbHelper.getBackendUrl()) + "/dbep/" + project.id + "/_publish";
      params = _.extend({
        xhrFields: {
          withCredentials: true
        }
      }, this._getUploadParams(project, profiles));
      okHandler = function(response) {
        return {
          ok: true,
          link: response.url
        };
      };
      return fetch(url, params).then(function(res) {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      }).then(okHandler)["catch"](this._errorHandler);
    },
    cancelPublication: function(projectId, questionId) {
      var params, url;
      url = "" + (DbHelper.getBackendUrl()) + "/dbep/" + projectId + "/" + questionId + "/_cancel";
      params = {
        type: 'POST',
        xhrFields: {
          withCredentials: true
        }
      };
      return W($.ajax(url, params))["catch"](function(xhr) {
        switch (xhr.status) {
          case 400:
            throw new Exceptions.dbep.cancel_fail;
            break;
          case 500:
            throw new Exceptions.dbep.server_error;
            break;
          default:
            throw new Exceptions.dbep.no_connection;
        }
      });
    },
    validateProject: function(project, questionIds, progressBarHandler) {
      return this._exportProject(project, questionIds, progressBarHandler).then((function(_this) {
        return function(profiles) {
          var okHandler, params, url;
          url = "" + (DbHelper.getDbepApiUrl()) + "/guidelines/_validate";
          params = _this._getUploadParams(project, profiles);
          okHandler = function(response) {
            return {
              ok: response.ok,
              profiles: profiles,
              errors: response.errors
            };
          };
          return fetch(url, params).then(function(res) {
            if (!res.ok) {
              throw res;
            }
            return res.json();
          }).then(okHandler)["catch"](_this._errorHandler);
        };
      })(this));
    },
    uploadPreview: function(project, questionIds, progressBarHandler) {
      return this._exportProject(project, questionIds, progressBarHandler).then((function(_this) {
        return function(profiles) {
          var okHandler, params, url;
          url = "" + (DbHelper.getDbepApiUrl()) + "/previews";
          params = _this._getUploadParams(project, profiles);
          okHandler = function(response) {
            var ok, _ref;
            ok = _.isEmpty((_ref = response.validationErrors) != null ? _ref : {});
            return {
              ok: ok,
              profiles: profiles,
              link: response.url,
              errors: response.validationErrors
            };
          };
          return fetch(url, params).then(function(res) {
            if (!res.ok) {
              throw res;
            }
            return res.json();
          }).then(okHandler)["catch"](_this._errorHandler);
        };
      })(this));
    },
    _getUploadParams: function(project, profiles) {
      var data;
      data = {
        guidelineId: project.id,
        guidelineTitle: project.get('name'),
        profiles: profiles
      };
      return {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        timeout: 60000,
        body: JSON.stringify(data)
      };
    },
    _errorHandler: function(err) {
      if (err instanceof window.Response) {
        switch (err.status) {
          case 400:
            throw new Exceptions.dbep.upload_fail;
            break;
          case 403:
            throw new Exceptions.dbep.feature_disabled;
            break;
          case 500:
            throw new Exceptions.dbep.server_error;
            break;
          default:
            throw new Exceptions.dbep.no_connection;
        }
      }
      throw new Exceptions.dbep.no_connection;
    }
  };
});
