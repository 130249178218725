var DbHelper, W, absoluteServerUrl, dbUrl, launchedFromExtension, mediator, _ref, _ref1, _ref2,
  __bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

mediator = require('mediator');

W = require('when');

launchedFromExtension = window.location.protocol === 'chrome-extension:';

absoluteServerUrl = ((_ref = window.gdt) != null ? _ref.debugMode : void 0) ? (_ref1 = (_ref2 = window.localStorage.getItem('absoluteServerUrl')) != null ? _ref2 : window.gdt.serverUrl) != null ? _ref1 : 'https://gdt.test.evidenceprime.com' : launchedFromExtension ? 'https://gdt.gradepro.org' : window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

dbUrl = "" + absoluteServerUrl + "/_db";

DbHelper = (function() {
  function DbHelper() {
    this.centralReplica = __bind(this.centralReplica, this);
  }

  DbHelper.prototype.getPresentationsDbUrl = function() {
    return "" + dbUrl + "/presentations";
  };

  DbHelper.prototype.getPresentationUrl = function(id) {
    return "" + absoluteServerUrl + "/presentations/#/" + id;
  };

  DbHelper.prototype.getVIGuidelinesUrl = function(id) {
    return "" + absoluteServerUrl + "/presentations/#/vi_guidelines/" + id;
  };

  DbHelper.prototype.getVIGuidelinesDbUrl = function() {
    return "" + dbUrl + "/vi_guidelines";
  };

  DbHelper.prototype.getNmaUrl = function(id) {
    return "" + absoluteServerUrl + "/presentations/#/nma/" + id;
  };

  DbHelper.prototype.getNmaDbUrl = function() {
    return "" + dbUrl + "/nma-viz";
  };

  DbHelper.prototype.getDBEPUrl = function() {
    var ecPrefix, hostname, prefix, subDomain, _ref3, _ref4;
    if ((_ref3 = window.gdt) != null ? _ref3.dbepUrl : void 0) {
      return window.gdt.dbepUrl;
    } else if (((_ref4 = window.gdt) != null ? _ref4.debugMode : void 0) || window.location.hostname === 'gdt.test.evidenceprime.com') {
      return 'https://guidelines.test.evidenceprime.com';
    } else {
      prefix = 'gdt.';
      ecPrefix = 'ec.gdt.';
      hostname = window.location.hostname;
      subDomain = hostname.indexOf(ecPrefix) === 0 ? 'dbep' : 'guidelines';
      if (hostname.indexOf(prefix) === 0) {
        hostname = hostname.substring(prefix.length);
      }
      return "https://" + subDomain + "." + hostname;
    }
  };

  DbHelper.prototype.getDbepApiUrl = function() {
    var ecPrefix, hostname, prefix, subDomain, _ref3, _ref4;
    if ((_ref3 = window.gdt) != null ? _ref3.dbepUrl : void 0) {
      return "" + window.gdt.dbepUrl + "/api";
    } else if (((_ref4 = window.gdt) != null ? _ref4.debugMode : void 0) || window.location.hostname === 'gdt.test.evidenceprime.com') {
      return 'https://guidelines.test.evidenceprime.com/api';
    } else {
      prefix = 'gdt.';
      ecPrefix = 'ec.gdt.';
      hostname = window.location.hostname;
      subDomain = hostname.indexOf(ecPrefix) === 0 ? 'dbep' : 'guidelines';
      if (hostname.indexOf(prefix) === 0) {
        hostname = hostname.substring(prefix.length);
      }
      return "https://" + subDomain + "." + hostname + "/api";
    }
  };

  DbHelper.prototype.getWebPreviewUrl = function() {
    return "" + absoluteServerUrl + "/mobile-preview/preview.html";
  };

  DbHelper.prototype.getMobilePreviewDocUrl = function(id) {
    return "" + dbUrl + "/mobile-preview/" + id;
  };

  DbHelper.prototype.getMobilePreviewUrl = function(id) {
    return "" + absoluteServerUrl + "/mobile-preview/#" + id;
  };

  DbHelper.prototype.getEtdPanelVoiceFormLink = function(projectId, memberId, questionId, etdId) {
    return "" + absoluteServerUrl + "/panel-voice/#/" + projectId + "/" + memberId + "/" + questionId + "/" + etdId;
  };

  DbHelper.prototype.getFormsAppUrl = function() {
    return "" + absoluteServerUrl + "/forms/";
  };

  DbHelper.prototype.getBackendUrl = function() {
    var _ref3, _ref4, _ref5;
    if ((_ref3 = window.gdt) != null ? _ref3.debugMode : void 0) {
      return (_ref4 = (_ref5 = window.localStorage.getItem('backendUrl')) != null ? _ref5 : window.gdt.backendUrl) != null ? _ref4 : 'https://api.gdt.test.evidenceprime.com';
    } else {
      return "" + absoluteServerUrl + "/_api";
    }
  };

  DbHelper.prototype.getClockUrl = function() {
    return "" + dbUrl + "/clock/_design/clock/_show/serverTime";
  };

  DbHelper.prototype.centralDbUrl = function(dbName) {
    return "" + dbUrl + "/" + dbName;
  };

  DbHelper.prototype.centralReplica = function(name) {
    return PouchDB(this.centralDbUrl(name), {
      skip_setup: true,
      ajax: {
        timeout: 60000
      }
    });
  };

  DbHelper.prototype.localReplica = function(name) {
    return PouchDB(name, window.gdt.POUCHDB_OPTIONS);
  };

  DbHelper.prototype.getUserSessionUrl = function() {
    return "" + (this.getBackendUrl()) + "/session";
  };

  DbHelper.prototype.getRegistrationUrl = function() {
    return "" + (this.getBackendUrl()) + "/accounts";
  };

  DbHelper.prototype.getAdminPanelUrl = function() {
    return "" + absoluteServerUrl + "/admin";
  };

  DbHelper.prototype.getStaticAssetsUrl = function(path) {
    if (path == null) {
      path = '';
    }
    if (window.gdt.debugMode) {
      return "https://gdt.test.evidenceprime.com/static" + path;
    } else {
      return "/static" + path;
    }
  };

  DbHelper.prototype.replicateFromCentral = function(dbName) {
    return this.centralReplica(dbName).replicate.to(this.localReplica(dbName));
  };

  return DbHelper;

})();

module.exports = new DbHelper();
