ApplyCancelButtons = require 'components/common/apply_cancel_buttons'
VisibilityMarks = require 'components/document_sections/v2/discussions/visibility_marks'
{ useI18n } = require 'lib/react_utils'

CommentToolbar = ({
  editing,
  handleCancel
  handleSaveComment,
  nestedComment,
  toggleCommentVisibility,
  visibleFor,
}) ->
  i18n = useI18n()
  <div>
    {!nestedComment and <VisibilityMarks
      visibleFor={visibleFor}
      toggleCommentVisibility={toggleCommentVisibility}
    />}
    {editing and
      <ApplyCancelButtons
        applyLabel={i18n 'actions.submit'}
        onApply={handleSaveComment}
        onCancel={handleCancel}
      />
    }
  </div>

CommentToolbar.propTypes = {
  editing: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func
  handleSaveComment: PropTypes.func,
  nestedComment: PropTypes.bool,
  toggleCommentVisibility: PropTypes.func,
  visibleFor: PropTypes.arrayOf(PropTypes.string),
}

module.exports = CommentToolbar
