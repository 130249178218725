Importance = require 'components/etd/soj_importance'
SummaryOfJudgementSectionNameCell = require 'components/etd/soj_section_name_cell'

getValueForKey = (isConsensus, section, key) ->
  if isConsensus
    section.get('#{key}Consensus') or section.get key
  else
    section.get key

getSelectedOptionText = (isConsensus, criterion) ->
  selectedOption = getValueForKey isConsensus, criterion, 'selectedOption'
  additionalOptions = criterion.get('additionalOptions', Immutable.List())
  criterion.get('options').concat(additionalOptions).find (option) ->
    option.get('value') is selectedOption
  , null, Immutable.Map()
  .get('text', '')

AdolopmentSojRow = ({
  adolopmentsCriteria
  adolopmentSections
  criterion
  criterionId
  editable
  isConsensus
  i18n
  onHideSection
  renderMode
  section
  sectionHidden
  sectionKey
  showAdolopmentJudgement
  showOriginalJudgement
  withAdolopment
}) ->

  sectionName = section.get('name')
  adolopmentSection = adolopmentSections.last().get(criterionId)

  importance = getValueForKey isConsensus, section, 'importance'
  rowClassName = classNames _.string.dasherize(criterionId),
    'hidden-section': sectionHidden

  displayCellContent = not sectionHidden or renderMode is 'printout'

  lastOriginalSection = adolopmentsCriteria.last().get(criterionId)
  lastOriginalSelectedOption = getSelectedOptionText isConsensus, lastOriginalSection
  selectedOption = getSelectedOptionText isConsensus, criterion

  isSelectedOptionEmpty = _.isEmpty(selectedOption)

  selectedOptionChanged = lastOriginalSelectedOption isnt selectedOption and not
     isSelectedOptionEmpty and withAdolopment

  optionClassess = classNames 'option',
    'changed': selectedOptionChanged

  adolopment = adolopmentsCriteria.last()
  adolopmentImportance = adolopmentSection.get('importance')

  selectedAdolopmentOption = if withAdolopment
    selectedOption
  else if not _.isEmpty(lastOriginalSelectedOption)
    i18n 'the_same_as_original'
  else null

  <tr className={rowClassName}>
    <SummaryOfJudgementSectionNameCell
      displayCellContent={displayCellContent}
      renderMode={renderMode}
      sectionHidden={sectionHidden}
      sectionName={sectionName}
      onHideSection={onHideSection}
    />
    <td key={"option"} className="option readOnly">
      {showOriginalJudgement && displayCellContent && getSelectedOptionText(isConsensus,
        adolopment.get(criterionId))}
    </td>
    <Importance
      editable={false}
      hidden={sectionHidden}
      isConsensus={isConsensus}
      key={"importance"}
      sectionKey={sectionKey}
      sectionName={sectionName}
      value={adolopmentImportance}
    />
    <td className={optionClassess}>
      {selectedAdolopmentOption if displayCellContent and showAdolopmentJudgement}
    </td>
    <Importance
      editable={editable}
      hidden={sectionHidden}
      isConsensus={isConsensus}
      sectionKey={sectionKey}
      sectionName={sectionName}
      value={importance}
    />
  </tr>

AdolopmentSojRow.propTypes = {
  adolopmentsCriteria: PropTypes.instanceOf(Immutable.Map).isRequired,
  criterion: PropTypes.instanceOf(Immutable.Map).isRequired,
  criterionId: PropTypes.string.isRequired
  editable: PropTypes.bool.isRequired,
  isConsensus: PropTypes.bool.isRequired,
  onHideSection: PropTypes.func.isRequired,
  renderMode: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Immutable.Map).isRequired,
  sectionHidden: PropTypes.bool.isRequired,
  sectionKey: PropTypes.string.isRequired,
  showAdolopmentJudgement: PropTypes.bool.isRequired,
  showOriginalJudgement: PropTypes.bool.isRequired,
  withAdolopment: PropTypes.bool.isRequired
}

module.exports = AdolopmentSojRow
