var INITIAL_STATE, NMAVizActions, NMA_OUTCOME_PREFIX, generateStore;

generateStore = require('stores/utils/generate_store');

NMA_OUTCOME_PREFIX = require('lib/doc_prefixes').nmaOutcome;

NMAVizActions = require('actions/nma_viz_actions');

INITIAL_STATE = Immutable.fromJS({
  isExportModalOpen: false,
  isFetching: false,
  question: Immutable.Map(),
  outcomes: Immutable.List(),
  forestPlotOutcomeId: null,
  networkGraphOpen: false
});

module.exports = generateStore({
  name: 'NMAVizStore',
  initialState: INITIAL_STATE,
  boundActions: [NMAVizActions],
  methods: {
    onFetch: function() {
      return this.setState(this.state.set('isFetching', true));
    },
    onFetchSuccess: function(_arg) {
      var outcomes, question;
      outcomes = _arg.outcomes, question = _arg.question;
      return this.setState(this.state.withMutations(function(state) {
        state.set('outcomes', Immutable.fromJS(outcomes));
        state.set('question', Immutable.fromJS(question));
        return state.set('isFetching', false);
      }));
    },
    onDbChange: function(change) {
      var _ref;
      if (change.id === this.state.getIn(['question', '_id'])) {
        return this.setState(this.state.set('question', Immutable.fromJS((_ref = change.doc) != null ? _ref : {})));
      } else if (change.id.indexOf(NMA_OUTCOME_PREFIX) === 0) {
        if (change.deleted) {
          return this.setState(this.state.update('outcomes', function(outcomes) {
            return outcomes["delete"](change.id);
          }));
        } else {
          return this.setState(this.state.update('outcomes', function(outcomes) {
            return outcomes.set(change.id, Immutable.fromJS(change.doc));
          }));
        }
      }
    },
    onFetchError: function() {
      return this.setState(this.state.set('isFetching', false));
    },
    onOpenForestPlot: function(outcomeId) {
      return this.setState(this.state.set('forestPlotOutcomeId', outcomeId));
    },
    onCloseForestPlot: function() {
      return this.setState(this.state.set('forestPlotOutcomeId', null));
    },
    onOpenNetworkGraph: function() {
      return this.setState(this.state.set('networkGraphOpen', true));
    },
    onCloseNetworkGraph: function() {
      return this.setState(this.state.set('networkGraphOpen', false));
    },
    onOpenExportModal: function() {
      return this.setState(this.state.set('isExportModalOpen', true));
    },
    onCloseExportModal: function() {
      return this.setState(this.state.set('isExportModalOpen', false));
    },
    getOutcomes: function() {
      return this.state.get('outcomes');
    },
    getQuestion: function() {
      return this.state.get('question');
    },
    isFetching: function() {
      return this.state.get('isFetching');
    },
    getForestPlotOutcomeId: function() {
      return this.state.get('forestPlotOutcomeId');
    },
    isNetworkGraphOpen: function() {
      return this.state.get('networkGraphOpen');
    },
    isExportModalOpen: function() {
      return this.state.get('isExportModalOpen');
    }
  }
});
