BasicErrorBoundary = require 'components/common/basic_error_boundary'
CommentItem = require 'components/document_sections/v2/comment_item'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
IconButton = require 'components/common/icon_button'
Popover = require 'components/common/popover'
mediator = require 'mediator'
Select = require 'components/common/select_custom'
SuggestionItem = require 'components/document_sections/v2/suggestion_item'
{ searchInCommentTextOrAuthor } = require 'lib/document_sections_v2_helper'
useTranslation = require 'components/hooks/use_translation'
{ Button } = ReactComponents
{ isCurrentUserAdmin, getAccessRightsList } = require 'lib/members_helper'
{ useCoffeeMemo, useCoffeeCallback } = require 'lib/react_utils'
{ useState, useEffect } = React

COMMENTS_FILTER_OPTIONS = ['all', 'open', 'resolved'].map (value) -> {
  value,
  text: $.t "docsec:comments_filters.#{value}"
}

VISIBLE_FOR_OPTIONS = _.chain(getAccessRightsList())
.map (accessRight) ->
  return if accessRight.value is 'no_access'
  _.pick accessRight, 'text', 'value'
.toArray()
.compact()
.value()

Discussions = React.memo ({
  activeDiscussionItem,
  activeTab,
  discussions,
  currentDiscussionsFilters,
  editingDiscussionItems,
  editingDiscussionReplies,
  mdaTopicId
}) ->
  i18n = useTranslation('docsec:discussions')
  [searchString, setSearchString] = useState('')

  discussionsWithTopicFiltered = useCoffeeMemo [discussions, mdaTopicId], ->
    if mdaTopicId?
      discussions.filter (item) ->
        switch item.get 'type'
          when 'comments'
            return false unless item.getIn(['data', 'data', 'context', 'topicId']) is mdaTopicId
            isCurrentUserAdmin() or item.getIn(['data', 'author', 'id']) is mediator.user.id
          else
            true
    else
      discussions

  membersOptions = useCoffeeMemo [discussionsWithTopicFiltered], ->
    authors = discussionsWithTopicFiltered.map (discussion) ->
      authorObj = discussion.getIn ['data', 'author']
      return unless authorObj
      text: authorObj.get 'name'
      value: authorObj.get 'id'
    .toJS()
    _.uniq _.compact(authors), 'value'

  discussionsFiltered = useCoffeeMemo [searchString, currentDiscussionsFilters, discussionsWithTopicFiltered], ->

    return discussionsWithTopicFiltered if not currentDiscussionsFilters.get('author')? and _.isEmpty(searchString)

    discussionsWithTopicFiltered.filter(
      searchInCommentTextOrAuthor(searchString, currentDiscussionsFilters))

  handleSuggestionChange = (accept) -> ->
    DocumentSectionsV2Actions.setBatchEditorOptions {
      type: 'suggestions', chapterId: 'full_document', accept
    }

  showAcceptRejectBtns = not discussionsWithTopicFiltered.isEmpty() and
    mediator.user.hasRole ['admin', 'mdg_editor_in_chief']

  <div className="discussions flex flex-col overflow-auto h-full">
    <div className="items-list flex-1 overflow-auto">
      {if discussionsWithTopicFiltered.isEmpty()
        <span className="italic">{i18n 'no_discussions'}</span>
      }
      <React.Fragment>
        <div className="flex mb-10">
          <div className="flex-grow">
            {switch activeTab
              when 'COMMENTS'
                <input
                  type="text"
                  value={searchString}
                  onChange={(e) -> setSearchString(e.target.value)}
                  placeholder={i18n('search_placeholder')}
                />
              when 'SUGGESTIONS'
                if showAcceptRejectBtns
                  <div className="accept-reject-buttons">
                    <IconButton
                      iconName='accept'
                      label={i18n 'accept_all'}
                      labelPosition='right'
                      onClick={handleSuggestionChange(true)}
                    />
                    <IconButton
                      iconName='reject'
                      label={i18n 'reject_all'}
                      labelPosition='right'
                      onClick={handleSuggestionChange(false)}
                    />
                  </div>
                else null
            }

          </div>
          <DiscussionsFilters
            currentDiscussionsFilters={currentDiscussionsFilters}
            filtersDef={[
              {
                valueKey: 'author',
                name: 'filter_by_author'
                default: null,
                options: membersOptions
                visible: true
              },
              {
                valueKey: 'status'
                name: 'filter_by_status'
                default: 'open'
                options: COMMENTS_FILTER_OPTIONS
                visible: activeTab is 'COMMENTS'
              },
              {
                valueKey: 'visibleFor'
                name: 'filter_by_type'
                default: null
                options: VISIBLE_FOR_OPTIONS
                visible: activeTab is 'COMMENTS'
              }
            ]}
          />
        </div>
        <div className="clearfix" />
        {discussionsFiltered.map((item) ->
          <DiscussionsItem
            key={item.get('id')}
            currentDiscussionsFilters={currentDiscussionsFilters}
            activeDiscussionItem={activeDiscussionItem}
            editingDiscussionItems={editingDiscussionItems}
            editingDiscussionReplies={editingDiscussionReplies}
            item={item}
            searchString={searchString}
          />
        ).toArray()}
      </React.Fragment>
    </div>
  </div>

Discussions.propTypes =
  activeTab: PropTypes.string.isRequired
  discussions: PropTypes.instanceOf(Immutable.List).isRequired
  currentDiscussionsFilters: PropTypes.instanceOf(Immutable.Map).isRequired
  editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
  editingDiscussionReplies: PropTypes.instanceOf(Immutable.Map).isRequired
  activeDiscussionItem: PropTypes.oneOfType [
      PropTypes.string
      PropTypes.arrayOf PropTypes.string
    ]

DiscussionsFilters = ({
  currentDiscussionsFilters,
  filtersDef,
}) ->

  [showFilters, setShowFilters] = useState(false)

  openFilters = useCoffeeCallback [], -> setShowFilters(true)
  closeFilters = useCoffeeCallback [], -> setShowFilters(false)

  onChangeFilter = useCoffeeCallback [], (filterKey) -> (filterValue) ->
    DocumentSectionsV2Actions.setDiscussionsFilters { filterKey, filterValue }

  <Popover
    onRequestClose={closeFilters}
    visible={showFilters}
  >
    <IconButton className="sidebar-filter-icon" iconName="filter" onClick={openFilters}/>
    <div className="sidebar-filters">
      <IconButton className="sidebar-filter-close-icon" iconName="cross" onClick={closeFilters} />
      <div>
        {_.map filtersDef, (filter) ->
          <DiscussionsFilter
            key={filter.valueKey}
            filter={filter}
            onChangeFilter={onChangeFilter(filter.valueKey)}
            selected={currentDiscussionsFilters.get(filter.valueKey)}
          />
        }
      </div>
    </div>
  </Popover>

DiscussionsFilters.propTypes = {
  currentDiscussionsFilters: PropTypes.instanceOf(Immutable.Map).isRequired,
  filtersDef: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }))
    default: PropTypes.any
  })).isRequired
}

DiscussionsFilter = ({ filter, onChangeFilter, selected }) ->
  i18n = useTranslation('docsec:discussions')

  clearFilter = useCoffeeCallback [filter, onChangeFilter], -> onChangeFilter filter.default

  return null if not filter.visible

  <div className="flex flex-row items-center w-full mb-10">
    <div className="filter-label mr-5">
      {i18n filter.name}
    </div>
    <div className="flex flex-row items-center flex-grow">
      <Select
        options={filter.options}
        selected={selected}
        onChange={onChangeFilter}
      />
      {selected isnt filter.default and
        <IconButton iconName='cross' onClick={clearFilter} />}
    </div>
  </div>

DiscussionsItem = ({
  activeDiscussionItem,
  currentDiscussionsFilters,
  editingDiscussionItems,
  editingDiscussionReplies,
  item
  searchString
}) ->
  itemId = item.get 'id'

  discussionType = item.get 'type'
  sectionId = item.get 'sectionId'

  mdaTableId = item.get 'mdaTableId'

  if discussionType is 'comments'
    <BasicErrorBoundary>
      <CommentItem
        activeDiscussionItem={activeDiscussionItem}
        currentDiscussionsFilters={currentDiscussionsFilters}
        data={item.get 'data'}
        editingDiscussionItems={editingDiscussionItems}
        editingReplyId={editingDiscussionReplies.get(itemId)}
        isDraft={item.get 'isDraft'}
        mdaTableId={mdaTableId}
        searchString={searchString}
        sectionId={sectionId}
      />
    </BasicErrorBoundary>
  else if discussionType is 'suggestions'
    <SuggestionItem
      activeDiscussionItem={activeDiscussionItem}
      data={item.get 'data'}
      editingReplyId={editingDiscussionReplies.get(itemId)}
      isDraft={item.get 'isDraft'}
      sectionId={sectionId}
    />

DiscussionsItem.propTypes = {
  searchString: PropTypes.string.isRequired
  activeDiscussionItem: PropTypes.oneOfType [
      PropTypes.string
      PropTypes.arrayOf PropTypes.string
    ]
  editingDiscussionItems: PropTypes.instanceOf(Immutable.Map).isRequired
  editingDiscussionReplies: PropTypes.instanceOf(Immutable.Map).isRequired
  item: PropTypes.instanceOf(Immutable.Map).isRequired
}


module.exports = Discussions
