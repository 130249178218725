ConnectStore = require 'components/enhancers/connect_store'
Translation = require 'components/mixins/translation'
ConfirmationModal = require 'components/common/confirmation_modal'
DiscardChangesStore = require 'stores/discard_changes_store'
DiscardChangesActions = require 'actions/discard_changes_actions'

DiscardChanges = createReactClass
  displayName: 'DiscardChanges'
  mixins: [Translation()]

  propTypes:
    callback: PropTypes.func

  handleDiscardChanges: ->
    @props.callback()
    DiscardChangesActions.clear()

  handleContinueEditing: ->
    DiscardChangesActions.setCallback(null)

  render: ->
    { callback, hasChangesInComments } = @props

    return null unless callback

    translationKey = if hasChangesInComments then 'discard_comments' else 'discard_changes'

    <ConfirmationModal
      isOpen
      question={"<span class='bold'>#{@i18n "#{translationKey}.title"}</span>"}
      message={@i18n "#{translationKey}.description"}
      confirmLabel={@i18n "#{translationKey}.confirm_label"}
      cancelLabel={@i18n "#{translationKey}.cancel_label"}
      onConfirm={@handleDiscardChanges}
      onCancel={@handleContinueEditing}
      confirmClass="btn-danger"
      cancelClass="btn-apply"
    />

storeConnectors =
  DiscardChangesStore: (Store) ->
    callback: Store.getCallback()
    hasChangesInComments: Store.hasChangesInComments()


module.exports =
  ConnectStore DiscardChanges, DiscardChangesStore, storeConnectors
