ACPVisualGuidelinesActions = require 'actions/acp_visual_guidelines_actions'
ACPVisualGuidelinesStore = require 'stores/acp_visual_guidelines_store'
ConnectStore = require 'components/enhancers/connect_store'
Spinner = require 'components/common/spinner'
{ isAcpFeaturesOn } = require 'stores/utils/etd_template_helper'
{ getOrganizationName } = require 'lib/app_utils'
{ Visualization } = VIComponents
{ useEffect } = React

storeConnector =
  ACPVisualGuidelinesStore: (Store) ->
    isPreparingData: Store.isPreparingData()
    settings: Store.getSettings()
    visualizationData: Store.getVisualizationDataForPreview()

VisualizationPreview = ({ settings, itemId, isPreparingData, visualizationData }) ->
  useEffect ->
    ACPVisualGuidelinesActions.prepareDataForVisualizationPreview(itemId)
    # settings are not used here, but I need a trigger to re-fetch data after settings are changed
    return undefined
  , [settings]

  if isPreparingData
    return <Spinner />
  else
    not visualizationData.isEmpty() && <Visualization
      i18n={i18next}
      data={visualizationData.toJS()}
      config={
        printout: false,
      }
    />

VisualizationPreview.propTypes =
  itemId: PropTypes.string.isRequired
  isPreparingData: PropTypes.bool.isRequired
  visualizationData: PropTypes.object.isRequired

module.exports = ConnectStore VisualizationPreview, [ACPVisualGuidelinesStore], storeConnector
