DocSectionsTemplatesActions = require 'actions/doc_sections_templates_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
BlockSections = require 'components/administration/templates/block_sections'
PresentationsBlockSections = require 'components/administration/templates/presentations_block_sections'
TemplateName = require 'components/administration/templates/template_name'
TemplateButtons = require 'components/administration/templates/template_buttons'

DocSectionsTemplateSectionsSelection = createReactClass
  displayName: 'DocSectionsTemplateSectionsSelection'

  propTypes:
    template: PropTypes.instanceOf(Immutable.Map)
    templateName: PropTypes.string
    templateChanged: PropTypes.bool
    autoUpdateName: PropTypes.bool
    useCurrentTemplate: PropTypes.func
    templateInUse: PropTypes.bool

  mixins: [CustomRenderMixin]

  saveTemplateChanges: ->
    DocSectionsTemplatesActions.saveTemplate @props.template

  cancelEdit: ->
    @revertChanges()
    DocSectionsTemplatesActions.cancelEdit()

  revertChanges: ->
    DocSectionsTemplatesActions.revertChanges()

  render: ->
    templateDef = @props.template?.get 'templateDef'
    templateData = @props.template?.get 'templateData'
    <div className='sections-selection'>
      <TemplateName
        nameEditable={false}
        templateName={@props.templateName}
        templateChanged={@props.templateChanged}
        autoUpdateName={@props.autoUpdateName} />
      <BlockSections
        blockName='chapters'
        blockDef={templateDef?.get('chapters')}
        blockData={templateData?.get('chapters')}
        onSectionToggle={DocSectionsTemplatesActions.toggleSection}
      />
      <TemplateButtons
        templateInUse={true}
        useCurrentTemplate={@props.useCurrentTemplate}
        templateInUse={@props.templateInUse}
        closeEdit={@cancelEdit}
        cancelEdit={@cancelEdit}
        revertChanges={@revertChanges}
        saveTemplateChanges={@saveTemplateChanges}
        templateChanged={@props.templateChanged} />
    </div>

module.exports = DocSectionsTemplateSectionsSelection
