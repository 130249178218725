AdolopmentDataActions = require 'actions/adolopment_data_actions'
EtdSectionTitle = require 'components/etd/etd_section_title'
ConclusionsSection = require 'components/etd/conclusions_section'
ConclusionsSectionEditModal =
  require 'components/etd/panel_voice_etd/conclusions_section_edit_modal'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
PanelVoiceResultsRow = require 'components/etd/panel_voice_etd/panel_voice_results_row'
RecommendationDecisionSection = require 'components/etd/recommendation_decision_section'
Switcher = require 'components/common/switcher'
Translation = require 'components/mixins/translation'
{ getPanelVoiceAdminSummaryUpdater, getSectionEditToggleHandler } = require 'lib/panel_voice_helper'
{ VOTING_RESULTS_VIEW_TYPES, getRecommendationTypeSections } = require 'lib/etd_helper'

{ SectionRow, CellWithTitle, Card, CardContent } = ReactComponents

TYPE_OF_RECOMMENDATION_SECTIONS = getRecommendationTypeSections()

ConclusionsWithPanelVoiceResults = createReactClass
  displayName: 'ConclusionsWithPanelVoiceResults'

  propTypes:
    adolopmentData: PropTypes.instanceOf(Immutable.Map).isRequired
    adolopments: PropTypes.instanceOf(Immutable.Map)
    assessmentSections: PropTypes.instanceOf(Immutable.OrderedMap)
    conclusionsSections: PropTypes.instanceOf(Immutable.OrderedMap)
    editingSectionId: PropTypes.string
    etdHighlights: PropTypes.instanceOf(Immutable.Map)
    etdId: PropTypes.string.isRequired
    etdSnapshots: PropTypes.instanceOf(Immutable.Map)
    etdTab: PropTypes.string.isRequired
    getAdminVotingSummary: PropTypes.func.isRequired
    memberNamesMap: PropTypes.instanceOf(Immutable.Map).isRequired
    questionName: PropTypes.string
    renderMode: PropTypes.string.isRequired
    showResultingDataOnly: PropTypes.bool
    votingResults: PropTypes.instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  getDefaultProps: ->
    etdHighlights: Immutable.Map()
    showResultingDataOnly: false

  getSectionProps: (sectionData, sectionId) ->
    adolopmentData: @props.adolopmentData
    adolopments: @props.adolopments
    assessmentSections: @props.assessmentSections
    attachments: @props.attachments
    contentColSpan: 2
    editable: true
    etdId: @props.etdId
    etdViewSettings: @props.etdViewSettings
    highlightToPanelMembers: @props.etdHighlights.get sectionId
    renderMode: @props.renderMode
    section: sectionData
    sectionId: sectionId
    showResultingDataOnly: @props.showResultingDataOnly

  toggleAdolopmentForSection: (sectionId) -> =>
    AdolopmentDataActions.toggleAdolopmentForSection @props.etdId, 'conclusions', sectionId

  toggleAdolopmentForSections: (sectionIds) -> =>
    AdolopmentDataActions.toggleAdolopmentForSections @props.etdId, 'conclusions', sectionIds

  renderConclusionsSection: (sectionData, sectionId) ->
    {
      activeJudgmentOptionBySection,
      activeResultsViewTypeBySection
      adolopments
      adolopmentData
      etdId
      etdSnapshots
      etdTab
      renderMode
      showResultingDataOnly
      votingResults,
    } = @props
    sectionProps = @getSectionProps sectionData, sectionId

    sectionVotingResults = votingResults.getIn ['sections', sectionId]
    votingStatus = votingResults.get 'status'
    closedOrTestVoting = votingStatus in ['closed', 'test']
    # mixin voting-results-related props if there are voting results for this section

    if sectionVotingResults?
      _.extend sectionProps,
        editable: closedOrTestVoting and etdTab is 'workspace'
        onEditClick: if closedOrTestVoting then null else getSectionEditToggleHandler sectionId

    SectionComponent = if sectionId in ['recommendation', 'decision']
      RecommendationDecisionSection
    else
      ConclusionsSection

    withAdolopment = adolopmentData.getIn([sectionId, 'withAdolopment'], false)

    votedEtdData = etdSnapshots.getIn ["#{votingResults.get('timestamp')}", 'templateData',
      'conclusions', 'sections', sectionId]

    adolopmentSwitcherCb = if sectionId in ['recommendation', 'decision']
      @toggleAdolopmentForSections([sectionId, 'justification'])
    else
      @toggleAdolopmentForSection(sectionId)

    <Card key={sectionId}>
      <CardContent>
        {not adolopments.isEmpty() and
          renderMode isnt 'printout' and sectionId isnt 'justification' and
          <div className="conclusions__adolopment-switcher">
            <Switcher
              buttonText={@i18n '/es:recommendations.table-view-options.toggle_adolopment'}
              checked={withAdolopment}
              onChange={adolopmentSwitcherCb}
            />
          </div>
        }
        <SectionComponent
          {...sectionProps}
          withPanelVoice
          withAdolopment={withAdolopment}
        />
        <table className="standard-table recommendation-table-static">
          <tbody>
            <PanelVoiceResultsRow
              activeJudgmentOptionBySection={@props.activeJudgmentOptionBySection}
              activeResultsViewTypeBySection={@props.activeResultsViewTypeBySection}
              etdId={etdId}
              etdPart='conclusions'
              etdTab={etdTab}
              getAdminVotingSummary={@props.getAdminVotingSummary}
              key={"#{sectionId}-panel-voice-results"}
              memberNamesMap={@props.memberNamesMap}
              renderMode={renderMode}
              responseColSpan={1}
              sectionId={sectionId}
              votedEtdData={votedEtdData}
              votingResults={votingResults}
            />
            {if @props.editingSectionId is sectionId
              <tr>
                <td>
                  <ConclusionsSectionEditModal {...sectionProps} etdId={@props.etdId} />
                </td>
              </tr>
            }
          </tbody>
        </table>
      </CardContent>
    </Card>

  render: ->
    <div className="conclusions-section">
      <EtdSectionTitle renderMode={@props.renderMode} title={@i18n 'conclusions'} />
      {@props.conclusionsSections.map(@renderConclusionsSection).toList()}
    </div>

module.exports = ConclusionsWithPanelVoiceResults
