var DbHelper, NMAExportService, alt, gdtDataToNmaData, getOrganizationName;

alt = require('alt');

DbHelper = require('base/lib/db_helper');

gdtDataToNmaData = require('lib/nma_helper').gdtDataToNmaData;

getOrganizationName = require('lib/app_utils').getOrganizationName;

module.exports = NMAExportService = (function() {
  function NMAExportService(adapter) {
    this.adapter = adapter;
  }

  NMAExportService.prototype._getNmaDataForPresentations = function(question, outcomes) {
    return gdtDataToNmaData(question, outcomes);
  };

  NMAExportService.prototype.exportToLink = function() {
    var data, organizationName, outcomes, presentationId, question;
    question = alt.stores.NMAVizStore.getQuestion();
    outcomes = alt.stores.NMAVizStore.getOutcomes();
    data = this._getNmaDataForPresentations(question, outcomes);
    presentationId = question.get('_id');
    organizationName = getOrganizationName();
    return this.adapter.updateOrCreate(DbHelper.getNmaDbUrl(), presentationId, function(doc) {
      return _.extend({}, doc, {
        _id: presentationId,
        data: data,
        organizationName: organizationName
      });
    }).then(function() {
      return DbHelper.getNmaUrl(presentationId);
    });
  };

  NMAExportService.prototype.exportToIframe = function() {
    return this.exportToLink().then(function(url) {
      var attrsString;
      attrsString = 'frameborder=\"0\" width=\"100%\" height=\"100%\" allowfullscreen';
      return "<iframe src=\"" + url + "\" " + attrsString + "></iframe>";
    });
  };

  return NMAExportService;

})();
