var ChapterDiscussions, Discussion, SectionDiscussions, array, arrayOf, boolean, docShape, documentId, initial, mapOf, number, object, oneOf, optional, rootDocShape, shape, string, _ref;

documentId = require('lib/db_docs/field_types/document_id');

shape = require('lib/db_docs/field_types/shape');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

arrayOf = require('lib/db_docs/field_types/array_of');

oneOf = require('lib/db_docs/field_types/one_of');

mapOf = require('lib/db_docs/field_types/map_of');

_ref = require('lib/db_docs/field_types/built_in_types'), array = _ref.array, string = _ref.string, boolean = _ref.boolean, number = _ref.number, object = _ref.object;

optional = shape.typeDecorators.optional;

Discussion = shape({
  id: string,
  ts: number,
  done: optional(boolean),
  resolved: boolean,
  type: oneOf(['comment', 'suggestion']),
  text: object,
  replies: array,
  user: shape({
    id: string,
    name: string
  })
});

SectionDiscussions = mapOf(Discussion);

ChapterDiscussions = mapOf(SectionDiscussions);

rootDocShape = docShape({
  chaptersOrder: initial([])(arrayOf(documentId)),
  discussions: optional(initial({})(mapOf(ChapterDiscussions))),
  mdaTopicId: optional(documentId)
});

module.exports = rootDocShape;
