var EtdTemplatesActions, EtdTemplatesStore, PouchSource, alt, errorHandler, getTemplateKey, immutable;

alt = require('alt');

errorHandler = require('lib/app_utils').errorHandler;

immutable = require('stores/utils/immutable');

EtdTemplatesActions = require('actions/etd_templates_actions');

PouchSource = require('stores/sources/pouch_source');

getTemplateKey = function(templateType) {
  if (templateType) {
    return "" + templateType + "Template";
  } else {
    return 'template';
  }
};

EtdTemplatesStore = (function() {
  function EtdTemplatesStore() {
    this.state = Immutable.fromJS({
      txTemplate: null,
      dxTemplate: null,
      overarchingTxTemplate: null,
      overarchingDxTemplate: null,
      keyMessageTemplate: null,
      goodPracticeStatementTemplate: null,
      template: null,
      originalTemplate: null,
      _meta: {}
    });
    this.bindActions(EtdTemplatesActions);
    this.exportPublicMethods({
      getTemplate: this.getTemplate,
      isTemplateChanged: this.isTemplateChanged,
      getTemplateName: this.getTemplateName,
      getEditingTemplate: this.getEditingTemplate,
      isFetching: this.isFetching
    });
    this.registerAsync(PouchSource(EtdTemplatesActions));
  }

  EtdTemplatesStore.prototype.onFetch = function(params) {
    var dbId, docId;
    dbId = params.dbId, docId = params.docId;
    this.setState(this.state.setIn(['_meta', 'fetching'], true));
    return this.getInstance().fetch(dbId, docId);
  };

  EtdTemplatesStore.prototype.onPouchFetchSuccess = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('txTemplate', Immutable.fromJS(data.txTemplate)).set('dxTemplate', Immutable.fromJS(data.dxTemplate)).set('overarchingTxTemplate', Immutable.fromJS(data.overarchingTxTemplate)).set('overarchingDxTemplate', Immutable.fromJS(data.overarchingDxTemplate)).set('keyMessageTemplate', Immutable.fromJS(data.keyMessageTemplate)).set('goodPracticeStatementTemplate', Immutable.fromJS(data.goodPracticeStatementTemplate)).setIn(['_meta', 'fetching'], false);
    }));
  };

  EtdTemplatesStore.prototype.onPouchFetchFailure = function(e) {
    this.state.setIn(['_meta', 'fetching'], false);
    if (e.status !== 404) {
      return errorHandler(e);
    }
  };

  EtdTemplatesStore.prototype.onPouchSaveSuccess = function(data) {
    var editing, state;
    editing = this.state.getIn(['_meta', 'editing']);
    state = this.state.withMutations(function(state) {
      state.set('template', null);
      state.set(getTemplateKey(data.templateType), Immutable.fromJS(data.templateDoc));
      if (editing) {
        return state.setIn(['_meta', 'editing'], null);
      } else {
        return state;
      }
    });
    return this.setState(state);
  };

  EtdTemplatesStore.prototype.onPouchSaveError = function(e) {
    return errorHandler(e);
  };

  EtdTemplatesStore.prototype.getTemplate = function(templateType) {
    return this.state.get(getTemplateKey(templateType)) || Immutable.Map();
  };

  EtdTemplatesStore.prototype.getTemplateName = function(templateType) {
    var template;
    template = this.getTemplate(templateType);
    return template.getIn(['templateDef', 'name'], null);
  };

  EtdTemplatesStore.prototype.isTemplateChanged = function() {
    return this.state.getIn(['_meta', 'changed']);
  };

  EtdTemplatesStore.prototype.getEditingTemplate = function() {
    return this.state.getIn(['_meta', 'editing']);
  };

  EtdTemplatesStore.prototype.isFetching = function() {
    return this.state.getIn(['_meta', 'fetching']);
  };

  EtdTemplatesStore.prototype.onPrepareTemplate = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('template', data).set('originalTemplate', data).setIn(['_meta', 'changed'], false);
    }));
  };

  EtdTemplatesStore.prototype.onToggleSection = function(data) {
    this.state = this.state.updateIn(['template', 'templateDef', data.blockName], function(sections) {
      var idx, section, _ref;
      _ref = sections.findEntry(function(sec) {
        return sec.get('id') === data.sectionId;
      }), idx = _ref[0], section = _ref[1];
      return sections.set(idx, section.set('checked', !section.get('checked')));
    });
    this.state = this.state.setIn(['_meta', 'changed'], true);
    return this.setState(this.state);
  };

  EtdTemplatesStore.prototype.onTogglePresentationSection = function(data) {
    var presentations;
    presentations = this.state.getIn(['template', 'templateDef', 'presentations']);
    presentations = presentations.withMutations(function(presentations) {
      var idx, presentationGroup, _ref;
      _ref = presentations.findEntry(function(pg) {
        return pg.get('id') === data.presentationGroup;
      }), idx = _ref[0], presentationGroup = _ref[1];
      return presentations.set(idx, presentationGroup.update('sections', function(sections) {
        var section, _ref1;
        _ref1 = sections.findEntry(function(sec) {
          return sec.get('id') === data.sectionId;
        }), idx = _ref1[0], section = _ref1[1];
        return sections.set(idx, section.set('checked', !section.get('checked')));
      }));
    });
    this.state = this.state.setIn(['template', 'templateDef', 'presentations'], presentations);
    this.state = this.state.setIn(['_meta', 'changed'], true);
    return this.setState(this.state);
  };

  EtdTemplatesStore.prototype.onRevertChanges = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('template', state.get('originalTemplate')).setIn(['_meta', 'changed'], false);
    }));
  };

  EtdTemplatesStore.prototype.onUpdateName = function(data) {
    return this.setState(this.state.withMutations(function(state) {
      return state.setIn(['template', 'templateDef', 'name'], data.name).setIn(['_meta', 'changed'], true);
    }));
  };

  EtdTemplatesStore.prototype.onSave = function(params) {
    var projectDbName, template;
    projectDbName = params.projectDbName, template = params.template;
    return this.getInstance().save(projectDbName, template);
  };

  EtdTemplatesStore.prototype.onEditTemplate = function(data) {
    return this.setState(this.state.withMutations((function(_this) {
      return function(state) {
        return state.setIn(['_meta', 'editing'], data.templateType);
      };
    })(this)));
  };

  EtdTemplatesStore.prototype.onCancelEdit = function() {
    return this.setState(this.state.withMutations(function(state) {
      return state.set('template', null).setIn(['_meta', 'editing'], null);
    }));
  };

  EtdTemplatesStore.prototype.onUpdateSection = function(sectionData) {
    var currentData, label, path, sectionId;
    sectionId = sectionData.sectionId, label = sectionData.label;
    path = ['template', 'templateData', 'assessment', 'criteria', sectionId];
    currentData = this.state.getIn(path).toJS();
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(path, Immutable.fromJS(_.extend(currentData, _.omit(sectionData, ['sectionId', 'label']))));
      state.setIn(['template', 'templateData', 'assessment', 'sections', sectionId, 'name'], label);
      return state.setIn(['_meta', 'changed'], true);
    }));
  };

  return EtdTemplatesStore;

})();

module.exports = alt.createStore(immutable(EtdTemplatesStore), 'EtdTemplatesStore');
