var FIELDS_MAP, FIELDS_WITH_SEMICOLON, REST_FIELDS, exportAndSplitBySemicolon, exportGuideline, exportGuidelineFields, get;

exportGuidelineFields = require('lib/services/covid_exporter/utils').exportGuidelineFields;

get = require('lodash').get;

FIELDS_MAP = {
  'fullReference': 'citation',
  'targetAudience': 'users'
};

FIELDS_WITH_SEMICOLON = ['source'];

REST_FIELDS = ['title', 'generalDescription', 'fullReference', 'publicationDate', 'version', 'organizationType', 'sourceDocumentLink', 'isbn', 'doi', 'pmid', 'latestLiteratureSearchDate', 'targetAudience', 'field', 'authors', 'gradebookChapter', 'oldGradebookLink'];

exportAndSplitBySemicolon = function(fieldValue) {
  if (_.isEmpty(fieldValue)) {
    return [];
  }
  return _.map(fieldValue.split(';'), function(value) {
    return value.trim();
  });
};

exportGuideline = function(projectId, guidelineDoc) {
  var fieldsWithSemicolon, publicationYear, restFields;
  publicationYear = guidelineDoc['publicationDate'] ? moment(guidelineDoc['publicationDate']).format('YYYY') : void 0;
  fieldsWithSemicolon = exportGuidelineFields(exportAndSplitBySemicolon, FIELDS_WITH_SEMICOLON, FIELDS_MAP, guidelineDoc);
  restFields = exportGuidelineFields(_.identity, REST_FIELDS, FIELDS_MAP, guidelineDoc);
  return _.extend({
    '@id': guidelineDoc['slug'],
    originalId: projectId,
    publicationYear: publicationYear
  }, fieldsWithSemicolon, restFields);
};

module.exports = exportGuideline;
