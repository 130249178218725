{ string, func, bool } = PropTypes
Modal = require 'components/common/modal'
Translation = require 'components/mixins/translation'

style = content: top: 150

module.exports = createReactClass
  displayName: 'ExportEmbeddableCodeDialog'
  mixins: [Translation('projects:embeddable_code_dialog')]

  propTypes:
    code: string.isRequired
    onClose: func.isRequired
    isOpen: bool.isRequired
    parentSelector: func
    title: string

  getDefaultProps: ->
    parentSelector: null
    title: null

  _onCopy: ->
    @textarea.select()
    document.execCommand 'copy'

  _textareaRef: (el) ->
    @textarea = el

  render: ->
    <Modal
      isOpen={@props.isOpen}
      onClose={@props.onClose}
      parentSelector={@props.parentSelector}
      closeButton
      className="embeddableCodeDialog"
      style={style}
      title={@props.title ? @i18n 'title'}
    >
      <div className="row mt-20">
        {@i18n 'copy_code'}
      </div>
      <div className="row mt-20">
        <textarea
          ref={@_textareaRef}
          defaultValue={@props.code}
          rows="1"
          autoComplete="false"
        />
      </div>
      <div className="buttons row mt-20">
        <div className="col-9" />
        <div className="col-3">
          <button
            className="btn btn-apply"
            onClick={@_onCopy}
          >
            {@i18n '/actions.copy'}
          </button>
        </div>
      </div>
    </Modal>
