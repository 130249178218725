appUtils = require 'lib/app_utils'
DiscardChangesActions = require 'actions/discard_changes_actions'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
{
  default: Editor
  resetState: resetEditorState
  createControlExtension
  serializeState: serializeEditorState
  changeTrackerPluginKey
  annotationsPluginKey
  acceptChange
  rejectChange
  getSchema
} = Epiditor
{ isCurrentUserAdmin } = require 'lib/members_helper'
{ isImmutable } = require 'lib/immutable_utils'
{ getHyperlinkPluginOptions } = require 'lib/document_sections_v2_helper'
{ MDG_EIC_HIGHLIGHT_COLOR, MDG_WORKSPACE } = require 'lib/mdg_helper'
mediator = require 'mediator'
insertExtension = require './editor_extensions/insert/insert'
{ resetSelection } = require 'lib/epiditor_utils'
sectionLinksExtension = require './editor_extensions/section_links/section_links'
{ useCallback, useEffect, useMemo, useRef, useState } = React
useTranslation = require 'components/hooks/use_translation'
useScrollToDocSection = require 'components/hooks/use_scroll_to_doc_section'

getJSEditorState = _.memoize (immEditorState) ->
  immEditorState.toJS()

ChapterSectionContent = ({
  acceptedOrRejectedChange
  content
  draftDiscussions
  hasChanges
  mdaTopicId
  onCancel
  onEditingStarted
  onSave
  readOnly
  scrollToSectionContent
  sectionId
  suggesting
  updatedDraftDiscussion
  updateDraftDiscussions
}) ->
  i18n = useTranslation('translation:actions')
  [ editing, setEditing ] = useState(false)
  editorViewRef = useRef(null)
  editorContainerRef = useRef(null)
  useScrollToDocSection(sectionId, scrollToSectionContent, editorViewRef)

  editorLoaded = useCallback(
    (editorView) -> editorViewRef.current = editorView
    []
  )

  clearHighlights = useCallback (view) ->
    contentSize = view.state.doc.content.size
    highlightMark = view.state.config.schema.marks.highlight.create({ bg: MDG_EIC_HIGHLIGHT_COLOR })

    tr = view.state.tr.removeStoredMark highlightMark
    newState = view.state.apply(tr.removeMark(0, contentSize, highlightMark))

    view.updateState(newState)
  , []

  handleEditorSave = useCallback(
    (view) ->
      resetSelection view
      onSave serializeEditorState view.state
      setEditing false
    [onSave, setEditing]
  )

  handleEditorCancel = useCallback(
    (view) ->
      resetEditorState(view, getJSEditorState(content) if isImmutable(content))
      setEditing false
      onCancel()
    [onCancel, content, setEditing]
  )

  handleEditorClick = useCallback(
    (_editorView, evt) ->
      return if editing
      if evt.target.classList.contains('section-link')
        return DocumentSectionsV2Actions.setScrollToSection {
          projectId: mediator.project.id
          sectionId: evt.target.dataset.sectionid
        }
      if evt.target.classList.contains('chapter-link')
        return DocumentSectionsV2Actions.setScrollToChapter evt.target.dataset.chapterid
      setEditing true unless readOnly
    [setEditing, editing, readOnly]
  )

  handleCommentsUpdated = useCallback(
    (comments, maybePrevComments) ->
      if editing
        updateDraftDiscussions 'comments', comments
      
      else if editorViewRef.current
        newContent = serializeEditorState(editorViewRef.current.state)
        onSave newContent

      if maybePrevComments
        _.defer ->
          newCommentId = R.find(
            (commentId) -> not R.has(commentId, maybePrevComments)
            R.keys(comments)
          )
          newCommentId and DocumentSectionsV2Actions.editDiscussion { id: newCommentId, isNew: true }

    [updateDraftDiscussions, editing, onSave]
  )

  handleChangesUpdated = useCallback(

    (changes, maybePrevChanges) ->
      if editing
        updateDraftDiscussions 'suggestions', changes

    [updateDraftDiscussions, editing]
  )

  handleActiveAnnotationUpdated = useCallback(
    (current, prev) ->
      _.defer ->
        currentIds = current?.map ({ id }) -> id
        prevIds = prev?.map ({ id }) -> id
        DocumentSectionsV2Actions.setActiveDiscussionItem(
          if current then { sectionId, id: currentIds } else null,
          if prev then { sectionId, id: prevIds } else null
        )
    [sectionId]
  )

  handleActiveChangeUpdated = useCallback(
    (current, prev) ->
      _.defer ->
        DocumentSectionsV2Actions.setActiveDiscussionItem(
          if current then { sectionId, id: current.id } else null,
          if prev then { sectionId, id: prev.id } else null
        )
    [sectionId]
  )

  handleEditorChange = useCallback(
    (changedValue) ->
      if hasChanges
        return
      if not editing
        return
      if not isImmutable(content) or not _.isEqual getJSEditorState(content), serializeEditorState(changedValue)
        DiscardChangesActions.setHasChanges true
    [editing, content, hasChanges]
  )

  handleEditorChangeDebounced = useCallback(
    _.debounce handleEditorChange, 500
    [handleEditorChange]
  )

  editorPluginProps = useMemo(
    ->
      author = { id: mediator.user.id, name: mediator.user.getFullName() }

      {
        annotations: {
          author
          onChange: handleCommentsUpdated
          onActiveAnnotationUpdated: handleActiveAnnotationUpdated
          annotationDecorator: (annotation, selection) ->
            if not mdaTopicId?
              annotation.getDecoration selection
            else
              return undefined unless annotation.data?.context?.topicId is mdaTopicId
              if isCurrentUserAdmin() or annotation.author?.id is mediator.user.id
                annotation.getDecoration selection
              else
                undefined
          onBeforeAnnotationAdded: (annotation) ->
            visibleFor = if mediator.user.hasRole ['mdg_panel_reviewer', 'evolent_panel_reviewer']
              ['mdg_editor_in_chief']
            else
              mediator.user.getAccessRights()

            DocumentSectionsV2Actions.setActiveSidebarTab 'COMMENTS'
            annotation.update
              data: _.extend {},
                annotation.data ? {},
                context: if mdaTopicId? then topicId: mdaTopicId else {}
              visibleFor: visibleFor

        }
        changeTracker: {
          author
          onChange: handleChangesUpdated
          onActiveChangeUpdated: handleActiveChangeUpdated
        }
        hyperlink: getHyperlinkPluginOptions()
        storedMarks: (schema) ->
          if mediator.user.hasRole(['mdg_editor_in_chief'])
            [schema.marks.highlight.create({ bg: MDG_EIC_HIGHLIGHT_COLOR })]
          else []
        cellMenu:
          onCellMenuClick: (action, cb) ->
            if action.toLowerCase() is 'delete table'
              confirmOptions =
                title: i18n '../delete_table_confirmation.title'
                message: i18n '../delete_table_confirmation.message'
                confirmText: i18n 'delete'
                declineText: i18n 'cancel'
              mediator.dialogs.confirm confirmOptions, (confirmed) ->
                if confirmed
                  cb()
            else
              cb()
      }
    [
      handleCommentsUpdated
      handleChangesUpdated
      handleActiveChangeUpdated
      handleActiveAnnotationUpdated
      mdaTopicId
    ]
  )

  editorExtensions = useMemo(
    ->
      shouldShowHighlights = appUtils.isActiveWorkspaceOrFeatureSwitchOn(
        MDG_WORKSPACE, 'mdgDocSecFeatures'
      ) and isCurrentUserAdmin()
      applyCancelControlsExt = createControlExtension 'applyCancelControlsExt', ({ view }) ->
        <div className="apply-cancel-editor-controls">
          {if shouldShowHighlights
            <button className="cancel-btn" onClick={() => clearHighlights(view)}>
              {i18n 'clear_highlights'}
            </button>
          }
          <button className="cancel-btn" onClick={() => handleEditorCancel(view)}>
            {i18n 'cancel'}
          </button>
          <button className="apply-btn" onClick={() => handleEditorSave(view)}>
            {i18n 'save'}
          </button>
        </div>

      [
        sectionLinksExtension(),
        applyCancelControlsExt,
        insertExtension(),
      ]
    [handleEditorCancel, handleEditorSave]
  )

  useEffect(
    ->
      onEditingStarted() if editing
      return
    [editing, onEditingStarted]
  )

  useEffect(
    ->
      view = editorViewRef.current
      if view and updatedDraftDiscussion
        tr = view.state.tr
        { id, discussionType, updatedData, removed } = updatedDraftDiscussion

        if discussionType is 'comments'
          if removed
            tr.setMeta annotationsPluginKey, { removedAnnotation: id }
          else
            tr.setMeta annotationsPluginKey, { annotationUpdated: { id, updatedData } }

        else if discussionType is 'suggestions'
          tr.setMeta changeTrackerPluginKey, { changedUpdated: { id, updatedData } }
        view.dispatch tr
        DocumentSectionsV2Actions.resetUpdatedDraftDiscussion()
    [updatedDraftDiscussion]
  )

  useEffect(
    ->
      view = editorViewRef.current
      if view and acceptedOrRejectedChange
        { changeId, accepted } = acceptedOrRejectedChange
        handler = if accepted then acceptChange else rejectChange
        handler(changeId)(view.state, view.dispatch)
        # to avoid cannot dispatch in the middle of dispatch error
        _.defer -> DocumentSectionsV2Actions.resetAcceptedOrRejectedChange()
      undefined
    [acceptedOrRejectedChange]
  )

  editMode = if editing
    if suggesting
      'review'
    else
      'edit'
  else
    'readonly'

  <div className="chapter-section-content" ref={editorContainerRef}>
    <Editor
      extensions={editorExtensions}
      initialState={if isImmutable(content) then getJSEditorState(content) else content }
      mode={editMode}
      onChange={handleEditorChangeDebounced}
      onClick={handleEditorClick}
      onLoad={editorLoaded}
      pluginProps={editorPluginProps}
      parentOverflowContainer={editorContainerRef.current}
    />
  </div>

ChapterSectionContent.propsTypes =
  content: PropTypes.object
  onSave: PropTypes.func.isRequired
  onCancel: PropTypes.func.isRequired
  onEditingStarted: PropTypes.func.isRequired
  updateDraftDiscussions: PropTypes.func.isRequired
  suggesting: PropTypes.bool.isRequired
  readOnly: PropTypes.bool
  draftDiscussions: PropTypes.instanceOf(Immutable.Map)
  updatedDraftDiscussion: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    discussionType: PropTypes.string.isRequired
    data: PropTypes.object
  })
  acceptedOrRejectedChange: PropTypes.shape({
    sectionId: PropTypes.string.isRequired,
    changeId: PropTypes.string.isRequired
    accepted: PropTypes.bool.isRequired
  })
  sectionId: PropTypes.string.isRequired
  scrollToSectionContent: PropTypes.shape({
    sectionId: PropTypes.string.isRequired,
    contentPos: PropTypes.number.isRequired
  })
  hasChanges: PropTypes.bool.isRequired
  mdaTopicId: PropTypes.string

module.exports = ChapterSectionContent
