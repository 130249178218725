MemberInputItem = createReactClass
  displayName: "MemberInputItem"

  propTypes:
    className: PropTypes.string
    disabled: PropTypes.bool
    member: PropTypes.instanceOf(Immutable.Map).isRequired
    name: PropTypes.string
    onChange: PropTypes.func.isRequired
    type: PropTypes.oneOf(['checkbox', 'radio']).isRequired

  getDefaultProps: ->
    className: ''
    disabled: false

  render: ->

    { checked, disabled, member, onChange, type } = @props
    className = classNames "member", @props.className

    <label className={className}>
      <div className="checkbox">
        <input
          type={type}
          value={member.get("_id")}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          name={@props.name}
        />
      </div>
      <div className="member-row">
        <div className="flex flex-row items-center space-between">
          <div>{member.get("lastName")} {member.get("givenNames")}</div>
          <div className="member-email">{member.get("email")}</div>
        </div>
      </div>
      <div className="clearfix" />
    </label>

module.exports = MemberInputItem
