var DOCUMENT_SECTIONS_V2_ROOT_ID, DocumentSectionsV2, DocumentSectionsV2Actions, DocumentSectionsViewV2, ReactComponent, View, alt, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

View = require('base/views/view');

ReactComponent = require('base/lib/traits/react_component');

mediator = require('mediator');

alt = require('alt');

DocumentSectionsV2 = require('components/document_sections/v2/document_sections_v2');

DocumentSectionsV2Actions = require('actions/document_sections_v2_actions');

DOCUMENT_SECTIONS_V2_ROOT_ID = require('lib/doc_ids').DOCUMENT_SECTIONS_V2_ROOT_ID;

module.exports = DocumentSectionsViewV2 = (function(_super) {
  __extends(DocumentSectionsViewV2, _super);

  function DocumentSectionsViewV2() {
    return DocumentSectionsViewV2.__super__.constructor.apply(this, arguments);
  }

  DocumentSectionsViewV2.prototype.container = '#page-container';

  DocumentSectionsViewV2.prototype.id = 'document-sections-v2-container';

  DocumentSectionsViewV2.prototype.autoRender = true;

  DocumentSectionsViewV2.prototype.initialize = function() {
    DocumentSectionsViewV2.__super__.initialize.apply(this, arguments);
    return this.enable(ReactComponent);
  };

  DocumentSectionsViewV2.prototype.afterRender = function() {
    var projectId, _ref;
    DocumentSectionsViewV2.__super__.afterRender.apply(this, arguments);
    projectId = mediator.project.id;
    this.renderComponent(DocumentSectionsV2, DocumentSectionsV2Actions, DOCUMENT_SECTIONS_V2_ROOT_ID, {
      fetchAndListen: {
        dbId: projectId
      }
    });
    return (_ref = document.getElementById('right-container')) != null ? _ref.classList.add('no-topbar') : void 0;
  };

  DocumentSectionsViewV2.prototype.dispose = function() {
    var _ref;
    this.unmountComponent();
    alt.recycle('DocumentSectionsV2Store', 'DiscardChangesStore', 'EDMSStore');
    if ((_ref = document.getElementById('right-container')) != null) {
      _ref.classList.remove('no-topbar');
    }
    return DocumentSectionsViewV2.__super__.dispose.apply(this, arguments);
  };

  return DocumentSectionsViewV2;

})(View);
