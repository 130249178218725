EtdTemplatesActions = require 'actions/etd_templates_actions'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
BlockSections = require 'components/administration/templates/block_sections'
PresentationsBlockSections = require 'components/administration/templates/presentations_block_sections'
TemplateName = require 'components/administration/templates/template_name'
TemplateButtons = require 'components/administration/templates/template_buttons'

TemplateSectionsSelection = createReactClass
  displayName: 'TemplateSectionsSelection'

  propTypes:
    templateType: PropTypes.string.isRequired
    template: PropTypes.instanceOf(Immutable.Map)
    nameEditable: PropTypes.bool
    templateChanged: PropTypes.bool

  mixins: [CustomRenderMixin]

  saveTemplateChanges: ->
    EtdTemplatesActions.saveTemplate @props.template, @props.templateType

  cancelEdit: ->
    @revertChanges()
    @closeEdit()

  closeEdit: ->
    EtdTemplatesActions.cancelEdit()

  revertChanges: ->
    EtdTemplatesActions.revertChanges()

  render: ->
    templateDef = @props.template?.get 'templateDef'
    templateData = @props.template?.get 'templateData'
    <div className='sections-selection'>
      <TemplateName
        nameEditable={@props.nameEditable}
        templateName={templateDef?.get('name')}
        templateChanged={@props.templateChanged}
        autoUpdateName={@props.autoUpdateName} />
      <BlockSections
        blockName='question'
        blockDef={templateDef?.get('question')}
        blockData={templateData?.get('question')}
        onSectionToggle={EtdTemplatesActions.toggleSection}
      />
      <BlockSections
        blockName='assessment'
        className='assessment-sections-block'
        blockDef={templateDef?.get('assessment')}
        blockData={templateData?.get('assessment')}
        descriptionKey='criteria'
        onSectionToggle={EtdTemplatesActions.toggleSection}
      />
      <BlockSections
        blockName='conclusions'
        blockDef={templateDef?.get('conclusions')}
        blockData={templateData?.get('conclusions')}
        onSectionToggle={EtdTemplatesActions.toggleSection}
      />
      <PresentationsBlockSections
        blockName='presentations'
        blockDef={templateDef?.get('presentations')}
        blockData={templateData?.get('presentations')}
      />
      <TemplateButtons
        templateInUse={@props.templateInUse}
        useCurrentTemplate={@props.useCurrentTemplate}
        templateInUse={@props.templateInUse}
        saveTemplateChanges={@saveTemplateChanges}
        templateChanged={@props.templateChanged}
        cancelEdit={@cancelEdit}
        closeEdit={@closeEdit}
        revertChanges={@revertChanges}
        />
    </div>

module.exports = TemplateSectionsSelection
