Input = require 'components/common/input'
EllipsizedText = require 'components/common/ellipsized_text'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Translation = require 'components/mixins/translation'
{ getKeyValObject, getCurrentLanguage } = require 'base/lib/utils'
{ arrayOf, bool, instanceOf, func, number, object } = PropTypes

TX_PICO_FIELDS = [
  'intervention'
  'comparison'
  'questionFormat'
  'healthProblemOrPopulation'
]

DX_PICO_FIELDS = [
  'indexTest'
  'comparatorTest'
  'comparatorTestPresent'
  'targetCondition'
  'healthProblemOrPopulation'
  'diagnosticVerb'
]

getPicoFieldNames = (questionType) ->
  switch questionType
    when 'diagnostic' then DX_PICO_FIELDS
    else TX_PICO_FIELDS

getPicoEditComponent = (questionType) ->
  switch questionType
    when 'diagnostic' then DxPicoEdit
    else TxPicoEdit

PicoField = (props) ->
  { value, name, onChange, onRequestSave, onRequestCancel, annotation, children, className } = props
  <div className={classNames 'with-annotation', className}>
    <Input
      name={name}
      onChange={onChange}
      onRequestSave={onRequestSave}
      onRequestCancel={onRequestCancel}
      type='text'
      value={value}
    />
    {children}
    {if annotation?
      <div className='annotation'>{annotation}</div>
    }
  </div>

TxPicoEdit = createReactClass
  displayName: 'TxPicoEdit'

  propTypes:
    picoData: object.isRequired
    onPicoFieldEdit: func.isRequired
    onRequestSave: func.isRequired
    onRequestCancel: func.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  _ref: (el) ->
    @containerEl = el

  componentDidMount: ->
    @containerEl.querySelector('input').focus()

  renderQuestionFormatSelect: ->
    selectedFormat = @props.picoData.questionFormat

    <select
      id='questionFormat'
      name='questionFormat'
      value={selectedFormat}
      onChange={@props.onPicoFieldEdit}
    >
      <option value='FOR_HP'>{@i18n 'for_hp_label'}</option>
      <option value='IN_POP'>{@i18n 'in_pop_label'}</option>
    </select>

  render: ->
    { picoData, onPicoFieldEdit, onRequestCancel, onRequestSave } = @props

    fieldEditProps =
      onChange: onPicoFieldEdit
      onRequestSave: onRequestSave
      onRequestCancel: onRequestCancel

    <div className='question-pico-fields__edit' ref={@_ref}>
      {@i18n 'templates.general', {},
        intervention:
          <PicoField {...fieldEditProps} name='intervention' value={picoData.intervention} />
        comparison:
          <PicoField {...fieldEditProps} name='comparison' value={picoData.comparison} />
        forOrIn:
          @renderQuestionFormatSelect()
        healthProblemOrPopulation:
          <PicoField {...fieldEditProps}
            name='healthProblemOrPopulation'
            value={picoData.healthProblemOrPopulation}
          />
      }
    </div>

DxPicoEdit = createReactClass
  displayName: 'DxPicoEdit'

  propTypes:
    picoData: object.isRequired
    onPicoFieldEdit: func.isRequired
    onRequestSave: func.isRequired
    onRequestCancel: func.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  _ref: (el) ->
    @containerEl = el

  _prepareFieldProps: (fieldName) ->
    { picoData, onPicoFieldEdit, onRequestSave, onRequestCancel } = @props

    name: fieldName
    onChange: onPicoFieldEdit
    onRequestSave: onRequestSave
    onRequestCancel: onRequestCancel
    value: picoData[fieldName]

  renderComparatorTestCheckbox: ->
    { picoData, onPicoFieldEdit } = @props
    { comparatorTestPresent } = picoData

    inputCls = classNames 'input-button',
      'add-comparator-test add': not comparatorTestPresent
      'remove-comparator-test remove': comparatorTestPresent

    <Input
      checked={comparatorTestPresent}
      className={inputCls}
      name='comparatorTestPresent'
      onChange={onPicoFieldEdit}
      type='checkbox'
    />

  renderIndexTest: ->
    fieldProps = @_prepareFieldProps 'indexTest'

    if @props.picoData.comparatorTestPresent
      <PicoField {...fieldProps} />
    else
      <PicoField {...fieldProps} className='with-comparator-test-toggle'>
        {@renderComparatorTestCheckbox()}
      </PicoField>

  renderComparatorTest: ->
    return null unless @props.picoData.comparatorTestPresent

    fieldProps = @_prepareFieldProps 'comparatorTest'

    <PicoField {...fieldProps} className='with-comparator-test-toggle'>
      {@renderComparatorTestCheckbox()}
    </PicoField>

  renderDiagnosticVerbSelect: ->
    diagnosticVerb = @props.picoData.diagnosticVerb

    options = ['diagnose', 'screen']
    if getCurrentLanguage() is 'es'
      options = options.concat ['stage']

    <select
      id='diagnosticVerb'
      name='diagnosticVerb'
      value={diagnosticVerb}
      onChange={@props.onPicoFieldEdit}
    >
      {options.map (option) =>
        <option key={option} value={option}>
          {@i18n "#{option}_label"}
        </option>
      }
    </select>

  componentDidMount: ->
    @containerEl.querySelector('input').focus()

  render: ->
    { picoData } = @props
    { comparatorTestPresent } = picoData
    hpopProps = @_prepareFieldProps 'healthProblemOrPopulation'
    targetConditionProps = @_prepareFieldProps 'targetCondition'
    templateKey =
      "templates.#{if comparatorTestPresent then 'diagnostic_vs' else 'diagnostic'}_edit"

    <div className='question-pico-fields__edit' ref={@_ref}>
      {@i18n templateKey, {},
        indexTest:
          @renderIndexTest()
        comparatorTest:
          @renderComparatorTest()
        diagnosticVerb:
          @renderDiagnosticVerbSelect()
        targetCondition:
          <PicoField {...targetConditionProps} />
        healthProblemOrPopulation:
          <PicoField {...hpopProps} />
      }
    </div>

QuestionPicoEdit = createReactClass
  displayName: 'QuestionPicoEdit'

  mixins: [
    CustomRenderMixin
  ]

  propTypes:
    questionData: instanceOf(Immutable.Map).isRequired
    onRequestSave: func.isRequired
    onRequestCancel: func.isRequired

  getInitialState: ->
    @picoFieldNames ?= getPicoFieldNames @props.questionData.get 'type'

    @picoFieldNames.reduce (initialState, fieldName) =>
      _.extend initialState,
        getKeyValObject fieldName, @props.questionData.get fieldName, ''
    , {}

  handlePicoFieldChange: (evt) ->
    { target } = evt
    fieldName = target.getAttribute 'name'
    newVal = if fieldName is 'comparatorTestPresent' then target.checked else target.value
    if fieldName is 'comparatorTestPresent'
      @props.toggleComparatorTestPresent(newVal)

    @setState getKeyValObject fieldName, newVal

  getEditedValues: ->
    # return only changed values
    @picoFieldNames.reduce (editedValues, fieldName) =>
      currentValue = @state[fieldName]
      initialValue = @props.questionData.get fieldName, ''
      return editedValues if currentValue is initialValue

      _.extend editedValues, getKeyValObject fieldName, currentValue
    , {}

  render: ->
    PicoEditComponent = getPicoEditComponent @props.questionData.get 'type'

    <PicoEditComponent
      picoData={@state}
      onPicoFieldEdit={@handlePicoFieldChange}
      onRequestSave={@props.onRequestSave}
      onRequestCancel={@props.onRequestCancel}
    />

module.exports = QuestionPicoEdit
