var AddPatientPresentationToGPSTemplate, GPS_PRESENTATION_DEF, Migration, W, mediator, migrationName, templateSections, updateTemplateDataAndDefinition,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

mediator = require('mediator');

Migration = require('./migration');

W = require('when');

templateSections = require('lib/etd_templates/templates_sections');

migrationName = 'add_patient_presentation_to_gps_templates';

GPS_PRESENTATION_DEF = [
  {
    "id": "patients",
    "checked": true,
    "sections": [
      {
        "id": "whyThisGoodPracticeStatement",
        "checked": true
      }, {
        "id": "whatItMeansForYou",
        "checked": true
      }, {
        "id": "whoIsThisFor",
        "checked": true
      }
    ]
  }
];

updateTemplateDataAndDefinition = function(templateData, templateDef) {
  var presentations;
  presentations = templateSections().presentations;
  templateData = templateData.hasIn(['presentations', 'sections', 'patients', 'sections']) ? templateData.updateIn(['presentations', 'sections', 'patients', 'sections'], function(sections) {
    var whyThisGoodPracticeStatement;
    whyThisGoodPracticeStatement = presentations.sections.patients.sections.whyThisGoodPracticeStatement;
    return sections.set('whyThisGoodPracticeStatement', Immutable.fromJS(whyThisGoodPracticeStatement));
  }) : templateData.withMutations(function(td) {
    td.setIn(['presentations', 'sectionsOrder'], Immutable.fromJS(['patients']));
    return td.updateIn(['presentations', 'sections', 'patients'], Immutable.Map(), function(patients) {
      return patients.withMutations(function(p) {
        p.set('sections', Immutable.fromJS({
          whyThisGoodPracticeStatement: presentations.sections.patients.sections.whyThisGoodPracticeStatement,
          whatItMeansForYou: presentations.sections.patients.sections.whatItMeansForYou,
          whoIsThisFor: presentations.sections.patients.sections.whoIsThisFor
        }));
        return p.set('sectionsOrder', Immutable.fromJS(['whyThisGoodPracticeStatement', 'whatItMeansForYou', 'whoIsThisFor']));
      });
    });
  });
  templateDef = templateDef.set('presentations', Immutable.fromJS(GPS_PRESENTATION_DEF));
  return {
    templateData: templateData,
    templateDef: templateDef
  };
};

module.exports = AddPatientPresentationToGPSTemplate = (function(_super) {
  __extends(AddPatientPresentationToGPSTemplate, _super);

  function AddPatientPresentationToGPSTemplate() {
    AddPatientPresentationToGPSTemplate.__super__.constructor.call(this, false);
  }

  AddPatientPresentationToGPSTemplate.prototype.up = function(project, colls) {
    var adapter, docIds, projectId;
    AddPatientPresentationToGPSTemplate.__super__.up.apply(this, arguments);
    projectId = project.id;
    adapter = mediator.services.storePersistenceAdapter;
    docIds = colls.questions.reduce(function(docIds, q) {
      return docIds.concat(q.get('recommendationIds'));
    }, []);
    docIds = docIds.concat(['etd-goodPracticeStatement-template']);
    return adapter.fetch(projectId, _.compact(_.uniq(docIds))).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).filter(function(rows) {
        return rows.doc != null;
      }).pluck('doc').filter(function(doc) {
        var _ref;
        return ((_ref = doc.templateDef) != null ? _ref.id : void 0) === 'good-practice-statement';
      }).value();
    }).then(function(templateDocs) {
      var updatedDocs;
      if (_.isEmpty(templateDocs)) {
        return W.resolve();
      }
      updatedDocs = _.map(templateDocs, function(doc) {
        var templateData, templateDef, _ref;
        _ref = updateTemplateDataAndDefinition(Immutable.fromJS(doc.templateData), Immutable.fromJS(doc.templateDef)), templateData = _ref.templateData, templateDef = _ref.templateDef;
        return _.extend(doc, {
          templateData: templateData.toJS(),
          templateDef: templateDef.toJS(),
          rev_author: "project_migration/" + migrationName
        });
      });
      return adapter.bulkDocs(projectId, updatedDocs);
    });
  };

  return AddPatientPresentationToGPSTemplate;

})(Migration);
